import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2c94c4d5"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["href", "filter"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    width: $props.size,
    height: $props.size
  }, [_createElementVNode("image", {
    href: $props.path,
    width: "100%",
    height: "100%",
    filter: $options.strokePath
  }, null, 8, _hoisted_2)], 8, _hoisted_1);
}