import {
    Usdt,
    Farmt,
    NftItemExchange,
    Nft,
    BoxKeySol,
    MarketSol,
    ConsignmentSol,
    GoldenNft,
    PaymentSol,
    MainPol,
    UniSwap2
} from '@/chainField/contractApi';
import _ from 'lodash';
import vueStore from "@/store";

function StoreCool() {
    vueStore.dispatch('submitCooldown');
}
const EventRetry= function (itemInStoreQueue) {
    const cloneData = _.cloneDeep(itemInStoreQueue);
    console.log('独立开的重试的cloneData',cloneData);
    if(cloneData.beReplaced){
        return {status:false, message:'已经重试过一次了不让点'};
    }
    if(cloneData.status=='receipt'){
        console.log('交易已结束.不用覆盖');
        // return {status:false,message:'当前交易已结束无需覆盖'}
    }
    if(cloneData.status=='txResponse'){
        console.log('交易已上链.依然尝试');
    }
    // cloneData.callArgs;
    const contractSolPointer= cloneData.queueNo.split('_')[0];
    console.log('大概是哪个合约方法里的?',contractSolPointer);
    const abiName = cloneData.abiName;  // 用pointer和.abiName.来选择方法
    const oldTxOptions =cloneData.txOptions;

    if(!oldTxOptions){
        console.log('缺失txOptions');
        return {status:false, message:'没有旧的txOptions'}
    }
    if(oldTxOptions.gasPrice){
        const multiplier = 110n;
        oldTxOptions.gasPrice = BigInt(oldTxOptions.gasPrice);
        console.log('没有1.1倍时的值',oldTxOptions.gasPrice);
        oldTxOptions.biggerGasPrice= (oldTxOptions.gasPrice * multiplier) / 100n;
        console.log('得到计算后的值gasPriceVal',oldTxOptions.biggerGasPrice);
    }
    oldTxOptions.replacedQueueNo = cloneData.queueNo;
    // vueStore.dispatch('submitCooldown');
    let matchTarget=false;
    if(contractSolPointer=='queueNftItemExchange'){
        if(abiName=='FarmToNFT'){
            StoreCool();
            console.log('再次执行');
            matchTarget=true;
            NftItemExchange.runFarmToNFT({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
        if(abiName=='NFTToFarm'){
            StoreCool();
            console.log('再次执行道具回收');
            matchTarget=true;
            NftItemExchange.runNFtToFarm({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
        // NftItemExchange
    }else if(contractSolPointer=='queueGoldenNft'){
        console.log('是goldNft相关');
        if(abiName=='approve_toconsi'){
            StoreCool();
            matchTarget=true;
            GoldenNft.toApproveToConsignment({...cloneData.callArgs,retryTxOptions:oldTxOptions})
        }
        if(abiName=='UserBatchSafeMint'){
            StoreCool();
            matchTarget=true;
            GoldenNft.runUserBatchSafeMint({...cloneData.callArgs,retryTxOptions:oldTxOptions})
        }

        if(abiName=='burn'){
            StoreCool();
            matchTarget=true;
            GoldenNft.runBurnNftByTokenId({...cloneData.callArgs,retryTxOptions:oldTxOptions})
        }


    }
    else if(contractSolPointer=='queueFarmt'){
        if(abiName=='approve'){
            StoreCool();
            console.warn('是这个授权哦')
            matchTarget=true;
            Farmt.toApprove({...cloneData.callArgs,retryTxOptions:oldTxOptions})
        }
        if(abiName=='transfer'){
            StoreCool();
            console.log('确实是发了farm');
            matchTarget=true;
            Farmt.transfer({...cloneData.callArgs,retryTxOptions:oldTxOptions})
        }

    }
    else if(contractSolPointer=='queueUsdt'){
        console.log('usdt相关');
        if(abiName=='approve'){
            StoreCool();
            matchTarget=true;
            Usdt.toApprove({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
        if(abiName=='transfer'){
            console.log('再来次转账');
            StoreCool();
            matchTarget=true;
            Usdt.transfer({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
        // NftItemExchange
    }
    else if(contractSolPointer=='queueNft'){
        if(abiName=='PaymentKey'){
            StoreCool();
            console.log('再执行');
            matchTarget=true;
            Nft.RunPaymentByKey({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
        if(abiName=='Payment'){
            StoreCool();
            console.log('盲盒解锁吧');
            matchTarget=true;
            Nft.RunPayment({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }

        if(abiName=='OpeBlindBox'){
            StoreCool();
            console.log('再执行');
            matchTarget=true;
            Nft.RunOpenBindBox({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }

        if(abiName=='approve'){
            StoreCool();
            console.log('再执行装备授权');
            matchTarget=true;
            Nft.toApproveTokenId({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }


    }
    else if(contractSolPointer=='queueUniswapv2'){

        if(abiName=="router.swapExactTokensForTokens"){
            StoreCool();
            matchTarget=true;
            console.log('再兑换一次');
            UniSwap2.swap({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
    }
    else if(contractSolPointer=='queuePol'){
        if(abiName=='transfer'){
            StoreCool();
            matchTarget=true;
            console.log('又发一次pol');
            MainPol.transferPol({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
    }
    else if(contractSolPointer=='queueMarket'){

        if(abiName=='ListingNFT'){
            StoreCool();
            matchTarget=true;
            console.log('二次装备上架');
            MarketSol.EquipmentRunListingNFT({...cloneData.callArgs,retryTxOptions:oldTxOptions})
        }

        if(abiName=='OffShelfNFT'){
            StoreCool();
            matchTarget=true;
            console.log('装备取消上架');
            MarketSol.cancelMyEquipment({...cloneData.callArgs,retryTxOptions:oldTxOptions})
        }

        if(abiName=='Place'){
            StoreCool();
            matchTarget=true;
            console.log('又买一次装备');
            MarketSol.BuyEquipmentAndGolden({...cloneData.callArgs,retryTxOptions:oldTxOptions})
        }
        //
    }
    else if(contractSolPointer=='queueBoxKey'){
        if(abiName=='SigMint'){
            StoreCool();
            matchTarget=true;
            console.log('再执行钥匙的铸造');
            BoxKeySol.KeySigMint({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
    }
    else if(contractSolPointer=='queueGoldConsignment'){
        // NftItemExchange
        if(abiName=='createUserPurchase'){
            StoreCool();
            matchTarget=true;
            console.log('再执行发布');
            ConsignmentSol.AddGoldPurchase({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
        if(abiName=='OffShelf'){
            StoreCool();
            matchTarget=true;
            ConsignmentSol.cancelByGoldType({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
        if(abiName=='shellGold'){
            StoreCool();
            matchTarget=true;
            ConsignmentSol.shellGold({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }

    }
    else if(contractSolPointer=='queuePayment'){
        if(abiName=='Buy'){
            console.log('再执行购买服务');
            StoreCool();
            matchTarget=true;
            PaymentSol.runBuyByHash({...cloneData.callArgs,retryTxOptions:oldTxOptions});
        }
        // runBuyByHash
    }


    console.log('有匹配的执行方法么',matchTarget);


    return {status:true,matchTarget:matchTarget};
    // console.log('大概是哪个方法里的',cloneData.abi);
}

export {EventRetry}
