import {setKey,getKey,clearKey} from './localStore';
import {Center} from '@/chainField/chainCenter';
import {Gameins} from './gameRequest';
const keyNames={
    gameHeader_token:'gameHeader_token',
    gameHeader_address:'gameHeader_address',
    player_my_id:'player_my_id',
};


function delayMs(ms,fn,context) {
    return new Promise((resolve)=>{
        setTimeout(()=>{
            console.log('delay:'+ms+'ms');
            if(fn){
                if(context){
                    fn.call(context);
                }else{
                    fn.call();
                }
            }
            resolve('end')
        },ms)
    })
}

function ClearGameApiHeader() {
    localStorage.removeItem(keyNames.gameHeader_token);
    localStorage.removeItem(keyNames.gameHeader_address);
}
function GameApiHeader(){
    const token = getKey(keyNames.gameHeader_token);
    const address = getKey(keyNames.gameHeader_address);
    return {Token:token,Address:address};
}
async function applyGameApiTokenDev() {
    const info = Center.devWalletSignInfo();
    const refreshRes = await Gameins.request({
        url:'/api/ping2',
        method:'POST',
        data:info,
        headers:{
            _isGetToken:true
        }});
    console.log('刷新refreshRes结果',refreshRes);
    return true;
}
let tokenPromise=null;
const refreshCodes={
    '1000':'json参数解析失败',
    '1001':'SigMessage 数据错误',
    '1002':'有效的时间戳必须大于当前时间且不大于5分钟',
    '1003':'签名地址不匹配',
    '1004':'解析参数到JSON错误',
    '5006':'user banned',
    "1028":"server maintenance"
}
async function ApplyGameApiToken({minutes=2,selfSave=true,registerData=null}) {
    const {status, message,data} = Center.WalletSignInfo(minutes);
    // console.log('更新用的data',data);
    if(status){
        if(!tokenPromise){
            if(registerData&&registerData.inviteCode){
                data.ref=registerData.inviteCode;
            }
            console.log('run_api_register');
            tokenPromise= Gameins.request({
                url:'/api/register',
                method:'POST',
                data:data,
                headers:{
                    _isGetToken:true
                }});
        }else{
            console.log('using exist token promise');
        }

        // await delayMs(5000);
        const game_tk_res = await tokenPromise;
        tokenPromise=null;
        if(game_tk_res.data&&game_tk_res.data.code==200){
            if(selfSave){
                setKey(keyNames.gameHeader_address,data.message.address);
                setKey(keyNames.gameHeader_token,game_tk_res.data.data.token);
                setKey(keyNames.player_my_id,game_tk_res.data.data.myId);
            }
            return {status:true, code: game_tk_res.data.code,address:data.message.address, token:game_tk_res.data.data.token};
        }else{
            let error = refreshCodes.hasOwnProperty(game_tk_res.data.code)?refreshCodes[game_tk_res.data.code]:'未知错误码';
            console.log('game refreh token Error',error);
            return {status:false, code: game_tk_res.data.code};
        }
    }else{
        return {status: false, message };
    }

}

function requestIsGetToken(config){
    return !!config._isGetToken;
}

export {GameApiHeader,ClearGameApiHeader,requestIsGetToken,ApplyGameApiToken};
