import {ethers} from "ethers";
import {EventManager,EventEnum} from "@/util/EventManager";
import {Center} from "@/chainField/chainCenter";
import {addressConfig} from "@/config";
import {ErrorTypes,AssembleErr,BaseContractSetting} from './NamingSummary';

import {
    ClearWaitForReceiptByHash,
    EventTag,
    PolyEventManager,
    syncWaitForReceiptByHash
} from "@/util/polyEventProgress";


const abis=[
    'function SigMint(address _to,uint256 _value,uint256 _time,bytes memory _signature)  external' //签名铸造方法 参数: 接收地址，数量，时间戳，签名信息
];
let _address={Low:addressConfig.KeyLow,Medium:addressConfig.KeyMedium,High:addressConfig.KeyHigh};
const Main={
    contract:{Low:null, Medium:null, High:null},
    signerContract:{Low:null,Medium:null,High:null},
    _Iface:null,
};
Main.abis=abis;


const init=function(key){
    if(!Center.provider){
        return {status: false, message: 'provider need init!'}
    }
    Main.contract[key]=new ethers.Contract(_address[key],abis,Center.provider);
    return {status: true, contract: Main.contract[key]}
};

const contractAddSigner = function(key){
    if(!Main.contract[key]){
        const step0 = init(key);
        if(!step0.status){
            return step0;
        }
    }
    if(!Center.signer){
        return {status: false, message: 'center signer missed', showUserAuth:true}
    }
    Main.signerContract[key]=Main.contract[key].connect(Center.signer);
    return {status: true, signerContract: Main.signerContract[key]}
}

function ready(){
    for(let item in Main.contract){
        if(!Main.contract[item]){
            init(item);
        }
    }
}

function parseExpectError(e){
    let expectError=null;
    if(e.toString().indexOf('Used signatures')>-1){
        expectError='Used signatures';
    }
    return expectError;
}
// 铸造钥匙
Main.KeySigMint= async function({keyQuality,to,keyQty,time,signature,retryTxOptions=null}){
    console.log('Main.KeySigMintrun',arguments);
    // 外层传source的时候.有可能传递错误.而用到当前方法的话.event必然是内定的.所以..还是手动传source
    if(['Low','Medium','High'].indexOf(keyQuality)==-1){
        return {status:false, message: 'key Quality is need'};
    }
    // Low Medium High
    ready();
    if(!Main.signerContract[keyQuality]){
        const toSign = contractAddSigner(keyQuality);
        if(!toSign.status){
            return toSign;
        }
    }
    // 比如'1.05'
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract[keyQuality].SigMint.populateTransaction(to,keyQty,time,signature);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_钥匙铸造,eventOpt:{keyQuality:keyQuality,time:time},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx = await Main.signerContract[keyQuality].SigMint(to,keyQty,time,signature,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_钥匙铸造,queueNo:queueNo,error:parseExpectError(e)||e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_钥匙铸造,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            PolyEventManager.End({event:EventTag.e_钥匙铸造,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.boxKeyFinishCreate,{keyQuality:keyQuality,keyQty:keyQty,time:time});
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_钥匙铸造,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        return {status:true, waitTx:firstTx, message:'内部等待wait'}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_钥匙铸造,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }
}




export {Main as BoxKeySol}