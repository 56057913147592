import {Howl} from 'howler';
const audioSources=[
    {name: 'c1', path: require('@/assets/sounds/sound_click.wav'),loop:false},
    {name: 'c2', path: require('@/assets/sounds/snap_click.wav'),loop:false},
    {name: 'drawerOpen', path: require('@/assets/sounds/drawer_open_quick.wav'),loop:false},
];

const Attention="在电脑浏览器中.一些过短的音乐文件会碰到无法播放的问题.代码本身无误";
const howInstances={};
const audioplayer={
    loadSource:false,

};
function createHowl({path,loop=false}){
    return new Howl({src:[path],html5:true,loop:loop})
}
function init(){
    for(let i =0;i<audioSources.length;i++){
        howInstances[audioSources[i].name]=createHowl({path:audioSources[i].path,loop: audioSources[i].loop});
    }
    audioplayer.loadSource=true;
};
audioplayer.play=function (key) {
    if(!audioplayer.loadSource){
        init();
    }
    howInstances[key].play();
};
audioplayer.pause=function (key) {
    if(howInstances[key]){
        howInstances[key].pause();
    }
};
export {audioplayer};