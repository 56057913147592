import {ethers} from "ethers";
import {Center} from "@/chainField/chainCenter";
import {addressConfig} from "@/config";
import {ErrorTypes,AssembleErr,BaseContractSetting} from './NamingSummary';
import {logInPhone} from'@/util/phoneLog';
import {EventTag, PolyEventManager, syncWaitForReceiptByHash,ClearWaitForReceiptByHash} from "@/util/polyEventProgress";
import {EventEnum, EventManager} from "@/util/EventManager";


const abis=[
    "function FarmToNFT(address nftAddr,uint256 amount) external", // 0xae0049a8
    "function NFTToFarm(address nftAddr,uint256 tokenId) external", // 0x72a6174b
];


let _address=addressConfig.nftItemExchange;
const Main={
    contract:null,
    signerContract:null,
    _Iface:null,
};
Main.abis=abis;

const init=function(){
    if(!Center.provider){
        return {status: false, message: 'provider need init!'}
    }
    Main.contract=new ethers.Contract(_address,abis,Center.provider);
    return {status: true, contract: Main.contract}
};

Main.test=function () {

}
const contractAddSigner = function(level){
    if(!Main.contract){
        const step0 = init();
        if(!step0.status){
            return step0;
        }
    }
    if(!Center.signer){
        return {status: false, message: 'center signer missed', showUserAuth:true}
    }
    Main.signerContract=Main.contract.connect(Center.signer);
    return {status: true, signerContract: Main.signerContract}
}

function ready(){
    if(!Main.contract){
        init();
    }
}


function levelAddress(levelNum){
    if(levelNum==1){
        return addressConfig.level1;
    }else if(levelNum==2){
        return addressConfig.level2;
    }else if(levelNum==3){
        return addressConfig.level3;
    }
}


function parseExpectError(e){
    let expectError=null;
    if(e.toString().indexOf('hash Insufficient')>-1){
        expectError='hash Insufficient';
    }else if(e.toString().indexOf('Panic due to OVERFLOW')>-1){
        expectError='Panic due to OVERFLOW';
    }
    return expectError;
}

// 购买盲盒
Main.runFarmToNFT=async function({whichLevel,buyQty=1,retryTxOptions=null}){
    console.log('arguments',arguments);
    console.log("runFarmToNFT");
    ready();
    if(!(buyQty>0)){
        return AssembleErr({status: false, message:'buyNum must more than zero'});
    }
    if(!Main.signerContract){
        const toSign = contractAddSigner();
        if(!toSign.status){
            return toSign;
        }
    }
    let queueNo = '';
    try{
        const address=levelAddress(whichLevel);
        const _inputV=ethers.parseUnits(buyQty+'', 18);
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        console.log('本来的这时候的nonce',gasOpt);
        // 判断如果共通冷却在冷却中.直接返回.不然的话就可以走到下一步.
        const popuInfo = await Main.signerContract.FarmToNFT.populateTransaction(address,_inputV);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_购买盲盒,eventOpt:{level:whichLevel},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx= await Main.signerContract.FarmToNFT(address,_inputV,gasOpt);
            /*正常刷新..无论重试与否.如果nonce当前就是这个值.那确实可以去变动.*/
            console.warn('盲盒第一步结束后的时间:'+new Date().getTime());
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            console.log('购买盲盒方法报错',e);
            const couldExpectErr=parseExpectError(e);
            return await PolyEventManager.txCatchError({event:EventTag.e_购买盲盒,queueNo:queueNo,error:couldExpectErr||e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_购买盲盒,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        // firstTx.hash 这个执行了购买盲盒操作.在前端应该加个事务管理了.
        console.log('nonce刷新下面买盲盒tx',firstTx);
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
                console.log("结果里有状态status么",res);
                PolyEventManager.End({event:EventTag.e_购买盲盒,queueNo:queueNo,txReceipt:res});
                EventManager.emit(EventEnum.buyNewBlindBox,{levelNum:whichLevel});
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_购买盲盒,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        return {status: true, message:'wait finished'}
    }catch (e) {
        console.log('在这里有捕捉么',e);
        PolyEventManager.Error({event:EventTag.e_购买盲盒,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }
}


// 这东西.是把nft[比如金币nft.装备.盲盒.来回收成为farm]
Main.runNFtToFarm=async function({whichLevel,tokenId,source,retryTxOptions=null}){
    ready();
    if(!Main.signerContract){
        const toSign = contractAddSigner();
        if(!toSign.status){
            return toSign;
        }
    }
    let queueNo='';
    try{
        const address=levelAddress(whichLevel);
        tokenId= parseInt(tokenId);
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.NFTToFarm.populateTransaction(address,tokenId);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_NFT道具回收,source:source,eventOpt:{levelNum:parseInt(whichLevel),tokenId:parseInt(tokenId)},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx= await Main.signerContract.NFTToFarm(address,tokenId,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            const couldExpectErr=parseExpectError(e);
            return await PolyEventManager.txCatchError({event:EventTag.e_NFT道具回收,queueNo,error:couldExpectErr||e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_NFT道具回收,queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        console.log('提交tx',firstTx);
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            EventManager.emit(EventEnum.nftItemHuishou,{source:source,levelNum:whichLevel,tokenId:tokenId});
            PolyEventManager.End({event:EventTag.e_NFT道具回收,queueNo,txReceipt:res});
        }).catch(e=>{
            console.error('runNFtToFarm',e);
            PolyEventManager.receiptCatchError({event:EventTag.e_NFT道具回收,queueNo,error:e,txResponse:firstTx});
        })
        return {status: true, message:'wait finished'}
    }catch (e) {

        PolyEventManager.Error({event:EventTag.e_NFT道具回收,queueNo,error:e});
        /*这个感觉要回扣1啊.*/
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }

}

export {Main as NftItemExchange}