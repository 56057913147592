const platform ={
    web:'web',
    mobile:'mobile',
}
const crossDomain={};
const domainMobileMap ={
    japan:'http://64.176.57.127:8081', // 日服
    xinjiapo:'http://47.236.159.122:80',
    china:'http://124.221.89.109:80', // 国服
};
crossDomain[platform.mobile]={
    baseGame:'waitinit',
}
crossDomain[platform.web]={
    baseGame:'/gameapi',
}
// const buildplatForm= platform.web; //  web,或者android.
// const currentApiPath = crossDomain[buildplatForm];

const AccountSaveLevels={
    session:'session',
    local:'localstorage',
    currenSelect:'session',
}

// buildplatForm,currentApiPath
export  {platform,AccountSaveLevels,crossDomain,domainMobileMap};
