import {ref,toRaw} from 'vue';
import useClipboard from "vue-clipboard3";
import {showToast} from "vant";
export function vueToRaw(obj) {
   return toRaw(obj);
}
// 延迟时间.并且可以延迟执行.执行可以不需要.
export function delayMs(ms,fn,context) {
    return new Promise((resolve)=>{
        setTimeout(()=>{
            console.log('delay:'+ms+'ms');
            if(fn){
                if(context){
                    fn.call(context);
                }else{
                    fn.call();
                }
            }
            resolve('end')
        },ms)
    })
}

// 独立ref空间.
export function useCounter(initialValue = 0) {
    const count = ref(initialValue);

    function increment() {
        count.value++;
    }

    return { count, increment };
}




export function copyContent(content){
    const {toClipboard}=useClipboard();
    return new Promise((resolve)=>{
        if(content&&content.length>0){
            toClipboard(content).then(res=>{
                resolve({status:true});
                console.log("hook复制结束",res);
                showToast("copy success");
            })
        }else{
            resolve({status:false, message:'no content'});
        }
    })
}

export function debounce(fn, delay) {
    console.log('debounce被执行次数');
    let timeoutId = null;
    return function(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            fn.apply(this, args);
        }, delay);
    };
}


export async function loadImgUrl(fileName){
    const extraPath = fileName;
    let image=null;
    try{
        image = await import(`@/assets/images/`+extraPath)
    }catch (e) {
        return null;
    }
    return image.default;
};

export function qualityToLevelLight(quality){
    if(quality){
        if(typeof quality =='number'){
            return quality;
        }else{
            const value =quality.toLowerCase();
            if(value=='nomal'||value=='normal'){
                return 0;
            }else if(value=='excellent'){
                return 1;
            }else if(value=='rare'){
                return 2;
            }else if(value=='legendary'){
                return 3;
            }
        }

    }else{
        return 0;
    }

}

export function ParseRawdataAttributes(attributes) {
    if(attributes&&ParseRawdataAttributes.length>0){
        const item={};
        for(let i=0;i<attributes.length;i++){

            if(attributes[i].trait_type=='Equipment ID'){
                item.equipmentId=attributes[i].value;
            }
            else if(attributes[i].trait_type=='Type'){
                item.type=attributes[i].value;
            }
            else if(attributes[i].trait_type=='Level'){
                item.level=attributes[i].value;
            }
            else if(attributes[i].trait_type=='Quality'){
                item.quality=attributes[i].value;
            }
        }
        return item;
    }else{
        return {};
    }

}




export function getCompleteInfoByEquipId(equipmentId) {
//     数码铲子（数码对应普通）
// 电子铲子（电子对应精良）
// 纳米铲子（纳米对应稀有）
// 量子铲子（量子对应传说）
    const guideId = equipmentId+''; //改成字符串形式
    // 1 01 001
    if(guideId.length!==6){
        // return {qualityNum:0,qualityName:'',
        //     equipTypeNum:0,baseName:{zh:'',en:''},fullName:{zh:'',en:''},effectDes:{zh:'',en:''},levelNum:0};
        return {dictionaryId:equipmentId,qualityNum:0,qualityName:{zh:'',en:''},equipTypeNum:0,baseName:{zh:'',en:''},fullName:{zh:'',en:''},effectDes:{zh:'',en:''},levelNum:0};
    }
    const quality = guideId[0];
    const equipType = guideId[1]+''+guideId[2]; // 类别
    const equipLevel = guideId[3]+''+guideId[4]+''+guideId[5];
    let qualityName = {zh:'',en:''};
    if(quality=='1'){
        qualityName.zh='普通';
        qualityName.en='normal';
    }
    if(quality=='2'){
        qualityName.zh='精良';
        qualityName.en='excellent';
    }
    if(quality=='3'){
        qualityName.zh='稀有';
        qualityName.en='rare';
    }
    if(quality=='4'){
        qualityName.zh='傳奇';
        qualityName.en='legendary';
    }
    const baseName ={zh:'',en:''};

    const effectDes={zh:'',en:''};
    if(equipType=='01'){
        baseName.zh='殺蟲劑';
        baseName.en='pesticide';
        effectDes.zh='作物產量提升';
        effectDes.en='Crop Yield Increase';
    }
    if(equipType=='02'){
        baseName.zh='寵物';
        baseName.en='pet';
        effectDes.zh='防守概率';
        effectDes.en='Defense probability increase';
    }
    if(equipType=='03'){
        baseName.zh='水壺';
        baseName.en='bottle';
        effectDes.zh='減少成熟時間';
        effectDes.en='Mature Time Reduce';
    }
    if(equipType=='04'){
        baseName.zh='鏟子';
        baseName.en='shovel';
        effectDes.zh='角色經驗加成';
        effectDes.en='Exp Gain Increase';
    }
    if(equipType=='05'){
        baseName.zh='運輸車';
        baseName.en='truck';
        effectDes.zh='偷取次數增加';
        effectDes.en='Steal times increase';
    }
    const levelNum=parseInt(equipLevel);

    const fullName ={zh:qualityName.zh+''+baseName.zh,en:qualityName.en+' '+baseName.en}
    return {dictionaryId:equipmentId,qualityNum:parseInt(quality),qualityName:qualityName,equipTypeNum:parseInt(equipType),baseName:baseName,fullName,effectDes:effectDes,levelNum};
}

export function guideIdToImgPath(guideId){
    if(!guideId){
        return ''
    }
    const quality = guideId[0];
    const equipType = guideId[1]+''+guideId[2]; // 类别
    const equipLevel = guideId[3]+''+guideId[4]+''+guideId[5];
    let pathStart='equip/';
    let pathAddType='';
    if(equipType=='01'){
        pathAddType='pesticide';
    }
    if(equipType=='02'){
        pathAddType='pet';
    }
    if(equipType=='03'){
        pathAddType='bottle';
    }
    if(equipType=='04'){
        pathAddType='shovel';
    }
    if(equipType=='05'){
        // 卡车
        pathAddType='truck';
    }
    const levelStr=parseInt(equipLevel);
    const finalPath = pathStart+pathAddType+'/'+levelStr+'.png';
    return  finalPath;
}

// attrs转换成适合显示详情的数据
export function AttrsToEquipInfo(attributes){
    const attrsParseItem = ParseRawdataAttributes(attributes);
    const data = getCompleteInfoByEquipId(attrsParseItem.equipmentId);
    data.attrsParseItem=attrsParseItem;
    data.imgOriPath = guideIdToImgPath(attrsParseItem.equipmentId);
    return data;
}

import {
    getPlayerInfo,GetServeTime
} from '@/gameApi';
import vueStore from '@/store/index'
export async function updateStorePlayerInfo() {
    getPlayerInfo({id: ''}).then(res => {
        // 如果不能正常取值的話
        if (res.code === 200) {
            vueStore.commit('setPlayerInfo', res.data);
        }else{

        }
    })
}
// 比对本地和服务器的时间差
export async function compareSystemTime() {
   const reqStartTime = new Date().getTime();
    const res = await GetServeTime();
    console.log('系统时间',res);
    if(res.data){
        const serverTimeSeconds = res.data.Date;
        const MilliSeconds =serverTimeSeconds*1000;
        const reqBackTime = new Date().getTime();
        const FasterThanServerMilliSec = (reqBackTime+reqStartTime)/2 - MilliSeconds;
        console.log('compare faster value',FasterThanServerMilliSec);
        vueStore.commit('fasterThanServer',FasterThanServerMilliSec);
    }
}


function parseVersion(version) {
    const match = version.match(/^(\d+)\.(\d+)\.(\d+)(?:-(\w+))?$/);
    if (!match) {
        // throw new Error(`无效的版本号格式: ${version}`);
        return {
            result: 0,
            type: "equal", // 版本相等
        };
    }

    const [, major, minor, patch, label] = match;
    return {
        major: parseInt(major, 10),
        minor: parseInt(minor, 10),
        patch: parseInt(patch, 10),
        label: label || null, // 后缀（如 beta, alpha）如果没有则为 null
    };
}

export function compareVersionsDetailed(version1, version2) {
    const v1 = parseVersion(version1);
    const v2 = parseVersion(version2);
    // 比较主版本号
    if (v1.major !== v2.major) {
        return {
            result: v1.major > v2.major ? 1 : -1,
            type: "major", // 主版本差异
        };
    }

    // 比较次版本号
    if (v1.minor !== v2.minor) {
        return {
            result: v1.minor > v2.minor ? 1 : -1,
            type: "minor", // 次版本差异
        };
    }

    // 比较修订号
    if (v1.patch !== v2.patch) {
        return {
            result: v1.patch > v2.patch ? 1 : -1,
            type: "patch", // 修订版本差异
        };
    }

    // 比较后缀（例如 alpha < beta），如果后缀不同
    if (v1.label !== v2.label) {
        const labelComparison = v1.label ? (v2.label ? v1.label.localeCompare(v2.label) : 1) : -1;
        return {
            result: labelComparison,
            type: "label", // 后缀差异
        };
    }

    // 完全相同的版本
    return {
        result: 0,
        type: "equal", // 版本相等
    };
}



export function getMobileOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
}


// 如果是app内就另外调用浏览器，如果是网页版就开新标签
export function OpenBrowserByUrl(url){
    if(url&&url.length>0){
        if(window.plus){
            plus.runtime.openURL(url);
        }else{
            window.open(url, '_blank');
        }
    }else{
        return;
    }

}


function formatDateTime(date) {
    function padZero(num) {
        return num<10?'0'+num:num;
    }
    const year = date.getFullYear();
    const month = padZero(date.getMonth()+1);
    const day = padZero(date.getDate());
    const our = padZero(date.getHours());
    const minute = padZero(date.getMinutes());
    const second = padZero(date.getSeconds());
    return year+''+month+''+day+''+our+''+minute+''+second;
}

export function TimestampToDate(timestr){
    let tempTimestr= timestr+'';
    if((tempTimestr+'').length==10){
        tempTimestr=tempTimestr+'000';
    }

    return new Date(parseInt(tempTimestr));
}
export function ParseTimestampToFormat(timestr,type=0){
    const date = TimestampToDate(timestr);
    function padZero(num) {
        return num<10?'0'+num:num;
    }
    const year = date.getFullYear();
    const month = padZero(date.getMonth()+1);
    const day = padZero(date.getDate());
    const our = padZero(date.getHours());
    const minute = padZero(date.getMinutes());
    const second = padZero(date.getSeconds());
    if(type==0){
        return year+''+month+''+day+''+our+''+minute+''+second;
    }
    else if(type==1){
        return year+'/'+month+'/'+day+'_'+our+':'+minute+':'+second;
    }
    else if(type==2){
        return year+'年'+month+'月'+day+'日'+our+'时'+minute+'分'+second;
    }else if(type==4){
        return month+'/'+day+'d_'+our+':'+minute+':'+second;
    }else{
        return year+''+month+''+day+''+our+''+minute+''+second;
    }
}


export function formatTimestamp(timestamp) {
    let tempTimestr= timestamp+'';
    if(tempTimestr.length==10){
        tempTimestr=tempTimestr+'000';
    }
    const date = new Date(parseInt(tempTimestr));
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${month}-${day} ${hours}:${minutes}`;
}

export const blindBoxFarmHraPriceMap={
    'level1':1000,
    'level2':2000,
    'level3':3000,
    '1':1000,
    '2':2000,
    '3':3000,
}

export const blindBoxLockUsdtHraPriceMap={
    'level1':5.99,
    'level2':9.99,
    'level3':13.99,
    '1':5.99,
    '2':9.99,
    '3':13.99,
}

export const localBuyKeyPrice={
    Low:{integral_price: 200, gold_price: 200000},
    Medium:{integral_price: 400, gold_price: 300000},
    High:{integral_price: 600, gold_price: 400000},
}

export const purchaseMinFarmDemand={
    goldType0:100,
    goldType1:1000,

}

// const examplePlayerInfo={
//     address:'',
//     anti_theft_probability:0,
//     countdown: 0, //count
//     em: 0,
//     exp: 0,
//     gold_amount: 0,
//     growth_rate: 0,
//     harvest_integral: 0,
//     key1:0,
//     key2:0,
//     key3:0,
//     level:60,
//     my_id:"qm8760",
//     name:"",
//     next_level_exp:0,
//     steal_count:10,
//     steal_countdown:37224,
//     use_steal_count:0,
//     user_id:16,
//     vip:0,
//     ym:0
// }
