const ErrorTypes={
    localErr:'logicError', //逻辑错误，比如缺参数.
    ethErr:'EthereumError'
};
import vueStore from '@/store/index'
function getLng(){
    // 这个后面可以丢到vue里.而不用都从local里取
    const lngP = vueStore.state.appBaseOption.language=='en'?'en':'zh';
    return lngP;
}
function AssembleErr(originJson,errType) {
    const lng = getLng();
    if(errType==ErrorTypes.ethErr){
        originJson.errType=errType;
    }else{
        originJson.errType=ErrorTypes.localErr;
    }
    if(originJson.message instanceof Error){
        originJson.message=originJson.message.toString();
        if(originJson.message.indexOf('nonce_is_wrong')>-1){
            originJson.message='【nonce is not ready,please wait】';
        }
        else if(originJson.message.indexOf('contract_run_in_cd')>-1){
            originJson.message='【please wait contract CD】';
        } else if(originJson.message.indexOf('funds:pol_must_be_greater_than')>-1){
            originJson.message='【pol must be greater than0.5】';
        }
    }
    return originJson;
}

const BaseContractSetting={
   txWaitTimeout:300000,   // 5分钟版本
}
// 改变txReponse等待的超时时间设定
function ChangeBaseTimeOut(type){
    const Minutel=2*1000; // 2秒
    const Minuteh=30*1000;
    const Minute1=1*60*1000;
    const Minute2=2*60*1000;
    const Minute5=5*60*1000;
    if(type==-1){
        BaseContractSetting.txWaitTimeout=Minutel;
    }
    if(type==0){
        BaseContractSetting.txWaitTimeout=Minuteh;
    }
    else if(type==1){
        BaseContractSetting.txWaitTimeout=Minute1;
    }
    else if(type==2){
        BaseContractSetting.txWaitTimeout=Minute2;
    }
    else if(type==5){
        BaseContractSetting.txWaitTimeout=Minute5;
    }
    return {curSettingTimeout:BaseContractSetting.txWaitTimeout};
}
function SetTxWaitTimeout(seconds){
    if(seconds>=0&&seconds<5*60){
        BaseContractSetting.txWaitTimeout=seconds;
        return true;
    }else{
        return true;
    }
}
export {ErrorTypes,AssembleErr,BaseContractSetting,SetTxWaitTimeout,ChangeBaseTimeOut};