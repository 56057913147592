import {ethers} from "ethers";
import {Center} from "@/chainField/chainCenter";
import {addressConfig} from "@/config";
import {EventEnum, EventManager} from "@/util/EventManager";
import {ErrorTypes,AssembleErr,BaseContractSetting} from './NamingSummary';
import {PolyEventManager,EventTag,syncWaitForReceiptByHash,ClearWaitForReceiptByHash} from "@/util/polyEventProgress";
// const abis=[
//     'function Payment(uint256 tokenId) external',
//     'function OpeBlindBox(uint256 tokenId,string memory rawData) external',
//     'function tokenURI(uint256 id) public view virtual returns (string memory)'
// ];
const abis=[
    'function Payment(uint256 tokenId) external',
    'function OpeBlindBox(uint256 tokenId, string memory rawData) external',
    'function tokenURI(uint256 id) public view returns (string memory)',
    "function allowance(address owner, address spender) external view returns (uint256)",
    "function approve(address spender, uint256 tokenId) external returns (bool)",
    'function getApproved(uint256 amountOrId) public view returns (address)',
    'function PaymentKey(uint256 tokenId) external'
];

// const abiSelector={
//     Payment:'0xd73298c5',
//     OpeBlindBox:'0x3bb11300',
//     tokenURI:'0xc87b56dd',
//     allowance:'0xdd62ed3e',
//     approve:'0x095ea7b3',
//     getApproved:'0x081812fc',
//     PaymentKey:'0x8c1bd931'
// };


let _address={level1:addressConfig.level1,level2:addressConfig.level2,level3:addressConfig.level3};
const levels=['level1','level2','level3'];
const Main={
    contract:{level1:null, level2:null, level3:null},
    signerContract:{level1:null,level2:null,level3:null},
    _Iface:null,
};
Main.abis=abis;

const unitNum=18;
const init=function(level){
    if(!Center.provider){
        return {status: false, message: 'provider need init!'}
    }
    Main.contract[level]=new ethers.Contract(_address[level],abis,Center.provider);
    return {status: true, contract: Main.contract[level]}
};

// let Iface=null;
// Main.getIface=function () {
//     if(Iface==null){
//         Iface = new ethers.Interface(abis);
//     }
//     return Iface;
// };
Main.test=function () {

}
const contractAddSigner = function(level){
    if(!Main.contract[level]){
        const step0 = init(level);
        if(!step0.status){
            return step0;
        }
    }
    if(!Center.signer){
        return {status: false, message: 'center signer missed', showUserAuth:true}
    }
    Main.signerContract[level]=Main.contract[level].connect(Center.signer);
    return {status: true, signerContract: Main.signerContract[level]}
}

function ready(){
    for(let item in Main.contract){
        if(!Main.contract[item]){
            init(item);
        }
    }
}

function numToLevel(level){
    let match = level+'';
    if(match.indexOf('1')>-1){
        return 'level1';
    }
    else if(match.indexOf('2')>-1){
        return 'level2';
    }
    else if(match.indexOf('3')>-1){
        return 'level3';
    }
}


Main.ViewTokenURI=async function({level,tokenId}){
    level = numToLevel(level);
    ready();
    tokenId=tokenId+'';
    if(!Main.contract[level]){
        return AssembleErr({status: false, message:'nft contract'+level+' is null'});
    }
    const infoStr=await Main.contract[level].tokenURI(tokenId);
    return {status:true, data:JSON.parse(infoStr)}
    // return await Main.contract[level].tokenURI(tokenId);
}


// 开盒子
Main.RunOpenBindBox=async function({level,tokenId,rawData,retryTxOptions=null}){
    if(!level){
        return AssembleErr({status:false, message:'level is null'});
    }
    if(!tokenId){
        return AssembleErr({status:false, message:'tokenId is null'});
    }
    if(!rawData){
        return AssembleErr({status:false, message:'rawData is null'});
    }
    const levelStr = numToLevel(level);
    tokenId=tokenId+'';
    ready();
    if(!Main.signerContract[levelStr]){
        const toSign = contractAddSigner(levelStr);
        if(!toSign.status){
            return toSign;
        }
    }
    let queueNo = '';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract[levelStr].OpeBlindBox.populateTransaction(tokenId,rawData);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_开启盲盒,eventOpt:{levelStr:levelStr,tokenId:tokenId},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx=await Main.signerContract[levelStr].OpeBlindBox(tokenId,rawData,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            const couldExpectErr=parseExpectError(e);
            return await PolyEventManager.txCatchError({event:EventTag.e_开启盲盒,queueNo:queueNo,error:couldExpectErr||e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_开启盲盒,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log('开启盲盒的结果',res);
            PolyEventManager.End({event:EventTag.e_开启盲盒,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.finishOpenBox,{levelStr:levelStr,tokenId:tokenId,receiptInfo:{status:res.status}});
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_开启盲盒,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        return {status: true, message:'wait finished'}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_开启盲盒,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
        // if(e.toString().includes('execution reverted: "NftToRawData.length > 0"')){
        //     return AssembleErr({status:false, message:'box open fail.'},ErrorTypes);
        //     return {status: false, message:'盲盒无法正常开启CODE:1'}
        // }else{
        //     return {status: false, message:'open error', e}
        // }
    }

}


function parseExpectError(e){
    let expectError=null;
    if(e.toString().indexOf('PaidInFull')>-1){
        expectError='PaidInFull';
    }
    if(e.toString().indexOf('NftToRawData.length > 0')>-1){
        expectError='NftToRawData.length > 0';
    }
    return expectError;
}
// 应该是盲盒解锁，也就是nft支付
Main.RunPayment=async function({level,tokenId,retryTxOptions=null}){
    console.log('start Runpayment');
    console.log("传入参数",level,tokenId);
    if(!level){
        return AssembleErr({status:false, message:'level is null'});
    }
    if(!tokenId){
        return AssembleErr({status:false, message:'tokenId is null'});
    }
    level = numToLevel(level);
    tokenId=tokenId+'';
    ready();

    if(!Main.signerContract[level]){
        const toSign = contractAddSigner(level);
        if(!toSign.status){
            return toSign;
        }
    }
    console.log('使用哪个盒子合约',Main.signerContract[level]);
    console.log('支付payment的tokenId是什么',tokenId);
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract[level].Payment.populateTransaction(tokenId);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_NFT支付,eventOpt:{levelStr:level,tokenId:tokenId},data:popuInfo.data});
        let firstTx={};
        try {
            firstTx = await Main.signerContract[level].Payment(tokenId,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            const couldExpectErr=parseExpectError(e);
            return await PolyEventManager.txCatchError({event:EventTag.e_NFT支付,queueNo:queueNo,error:couldExpectErr||e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_NFT支付,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        console.log("开盲盒调用payment方法:"+tokenId,firstTx);


        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("RunPayment.approve_tx_wait_res",res);
            PolyEventManager.End({event:EventTag.e_NFT支付,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.usdtPayToBox,{level:level,tokenId:tokenId,receiptInfo:{status:res.status}})
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_NFT支付,queueNo:queueNo,error:e,txResponse:firstTx});
        });


        return {status: true, message:'wait finished:盒等级'+level}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_NFT支付,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }

};
Main.RunPaymentByKey=async function({level,tokenId,retryTxOptions=null}){
    console.log('用钥匙解锁的');
    console.log("传入参数",level,tokenId);
    if(!level){
        return AssembleErr({status:false, message:'level is null'});
    }
    if(!tokenId){
        return AssembleErr({status:false, message:'tokenId is null'});
    }
    level = numToLevel(level);
    tokenId=tokenId+'';
    ready();

    if(!Main.signerContract[level]){
        const toSign = contractAddSigner(level);
        if(!toSign.status){
            return toSign;
        }
    }
    console.log('RunPaymentByKey使用哪个盒子合约',Main.signerContract[level]);
    console.log('支付paymentKey的tokenId是什么',tokenId);
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions)
        const popuInfo = await Main.signerContract[level].PaymentKey.populateTransaction(tokenId);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_NFT钥匙支付,eventOpt:{levelStr:level,tokenId:tokenId},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx = await Main.signerContract[level].PaymentKey(tokenId,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
            console.log('输出firstTx',firstTx);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            console.log('调用PaymentKey方法就出错了',e);
            return await PolyEventManager.txCatchError({event:EventTag.e_NFT钥匙支付,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_NFT钥匙支付,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        console.log("用钥匙解锁盲盒:"+tokenId,firstTx);
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("钥匙解锁盲盒",res);
            PolyEventManager.End({event:EventTag.e_NFT钥匙支付,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.keyPayToBox,{level:level,tokenId:tokenId,receiptInfo:{status:res.status}})
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_NFT钥匙支付,queueNo:queueNo,error:e,txResponse:firstTx});
        });

        return {status: true, message:'wait finished:盒等级'+level}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_NFT钥匙支付,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }

}



Main.whereTokenApproved= async function({level,tokenId}){
    ready();
    if(!level){
        return {status:false, message: 'level is need'};
    }
    level = numToLevel(level);
    const whereAddress = await Main.contract[level].getApproved(tokenId);
    return {status:true, address:whereAddress};
}
// 游戏交换合约的比对
Main.checkTokenIdIsApproveToNftItemExchange= async function({level,tokenId}){
    const res =await Main.whereTokenApproved({level,tokenId});
    if(res.status){
        if(res.address==addressConfig['nftItemExchange']){
            return {status:true, isApprovedSuccess:true};
        }else{
            return {status:true, isApprovedSuccess:false};
        }

    }else{
        return AssembleErr({status:false, message:res.message},ErrorTypes.ethErr);
    }
}
// 大卖场匹配
Main.checkTokenIdIsApproveToMarket= async function({level,tokenId}){
    const res =await Main.whereTokenApproved({level,tokenId});
    if(res.status){
        if(res.address==addressConfig['market']){
            return {status:true, isApprovedSuccess:true};
        }else{
            return {status:true, isApprovedSuccess:false};
        }

    }else{
        return AssembleErr({status:false, message:res.message},ErrorTypes.ethErr);
    }
}

Main.getProviderPrice=async function (){

};
// 去授权tokeNId。这个好像是盲盒在回收前授权给特定对象哈. 这个.装备的回收授权.和..盲盒的是同spender,不好分.
Main.toApproveTokenId= async function({level,spender,tokenId,source,retryTxOptions=null}){
    // 外层传source的时候.有可能传递错误.而用到当前方法的话.event必然是内定的.所以..还是手动传source
    if(!level){
        return {status:false, message: 'level is need'};
    }
    const levelStr = numToLevel(level);
    ready();
    if(!Main.signerContract[levelStr]){
        const toSign = contractAddSigner(levelStr);
        if(!toSign.status){
            return toSign;
        }
    }
    // 比如'1.05'
    const tokenIdToApprove =tokenId;
    let spenderAddress='';
    let queueNo='';
    try{
        if(['nftItemExchange','market'].indexOf(spender)>-1){
            spenderAddress=addressConfig[spender];
            const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
            const popuInfo = await Main.signerContract[levelStr].approve.populateTransaction(spenderAddress,tokenIdToApprove);
            queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_NFT道具授权,source:source,eventOpt:{levelStr:levelStr,spender:spender,tokenId:tokenId},data:popuInfo.data});
            let firstTx={};
            try{
                firstTx = await Main.signerContract[levelStr].approve(spenderAddress,tokenIdToApprove,gasOpt);
                await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
            }catch (e) {
                Center.submitCoolByNonce(gasOpt.nonce);
                return await PolyEventManager.txCatchError({event:EventTag.e_NFT道具授权,queueNo:queueNo,error:e});
            }
            Center.submitCoolByNonce(gasOpt.nonce);
            PolyEventManager.Update({event:EventTag.e_NFT道具授权,queueNo:queueNo,txResponse:firstTx});
            Center.NonceRefreshAnyway();
            console.log(levelStr+"授权特定token:"+tokenId+'给nftExchange', firstTx);
            syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
                ClearWaitForReceiptByHash(firstTx.hash);
                console.log("approve_tx_wait_res",res);
                PolyEventManager.End({event:EventTag.e_NFT道具授权,queueNo:queueNo,txReceipt:res});
                EventManager.emit(EventEnum.nftItemApproved,{source:source,levelStr:levelStr,spender:spender,tokenId:tokenId,receiptInfo:{status:res.status}})
            }).catch(e=>{
                PolyEventManager.receiptCatchError({event:EventTag.e_NFT道具授权,queueNo:queueNo,error:e,txResponse:firstTx});
            });
            return {status:true, waitTx:firstTx, message:'内部等待wait'}
        }else{
            return AssembleErr({status:false, message:'spender不匹配'});
        }
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_NFT道具授权,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }


}

export {Main as Nft}