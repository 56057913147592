import "core-js/modules/es.array.push.js";
import _ from 'lodash';
import { showToast } from "vant";
import { PolyEventManager, SourceTag } from '@/util/polyEventProgress';
import { EventManager, EventEnum } from "@/util/EventManager";
import LowPopFrame from "./frameStyle/lowPopFrame";
import { GetBlindBoxData } from '@/gameApi';
import { AttrsToEquipInfo, loadImgUrl, vueToRaw, updateStorePlayerInfo } from '@/hooks';
import TallPopFrame from "./frameStyle/tallPopFrame";
import { copyContent } from '@/hooks/index';
import { Center } from '@/chainField/chainCenter';
import EthEventDetail from "./ethEventDetail";
import vueStore from "../store";
import { ParseTimestampToFormat } from "../hooks";
import { BaseContractSetting } from '@/chainField/contractApi/NamingSummary';
export default {
  name: 'EventQueueSample',
  components: {
    EthEventDetail,
    TallPopFrame,
    LowPopFrame
  },
  data() {
    return {
      ethEventViewOp: {
        queueNo: '',
        whichQueueContract: '',
        abiName: '',
        show: false
      },
      errorPop: {
        show: false,
        content: '',
        highLightHash: ''
      },
      errorCollectorPop: {
        show: false
      },
      show: true,
      position: 'left',
      eventProgress: {
        show: true
      },
      listenNewEquipment: {
        show: false,
        fullInfo: {},
        boxBase: {
          levelStr: 'level',
          levelNum: 0,
          tokenId: 0
        }
      }
    };
  },
  watch: {},
  computed: {
    ifSetGasPriceInTx() {
      return this.$store.state.setGasPriceInTx;
    },
    bottomBarExist() {
      if (this.$route.fullPath.indexOf('/main/') > -1) {
        return true;
      } else {
        return false;
      }
    },
    debugMode() {
      return this.$store.state.appRunStatus.debugMode;
    },
    storeErrList() {
      return this.$store.state.errorCollector.list;
    },
    currentTimeStamp() {
      return this.$store.state.currentTimeStamp;
    },
    storeSubmitCD() {
      return this.$store.state.txSubmit.isBusy;
    },
    storeSubmitBusyNonce() {
      return this.$store.state.txSubmit.busyNonce;
    },
    storeLocalNonce() {
      return this.$store.state.provider_nonce;
    },
    curPath() {
      return this.$route.path;
    },
    showByPath() {
      if (this.curPath == '/login') {
        return false;
      } else {
        return true;
      }
    },
    winHeight() {
      return this.$store.state.winHeight;
    },
    winCurHeight() {
      return this.$store.state.winCurHeight;
    },
    nodePingInfo() {
      return this.$store.state.pingInfo;
    },
    allQueues() {
      const list = [];
      //     queuePol:[],
      list.push(...this.$store.state.queuesAll.queuePol);
      list.push(...this.$store.state.queuesAll.queueBoxKey);
      list.push(...this.$store.state.queuesAll.queueUsdt);
      list.push(...this.$store.state.queuesAll.queueUniswapv2);
      list.push(...this.$store.state.queuesAll.queueNft);
      list.push(...this.$store.state.queuesAll.queueFarmt);
      list.push(...this.$store.state.queuesAll.queueNftItemExchange);
      list.push(...this.$store.state.queuesAll.queuePayment);
      list.push(...this.$store.state.queuesAll.queueGoldConsignment);
      list.push(...this.$store.state.queuesAll.queueMarket);
      list.push(...this.$store.state.queuesAll.queueGoldenNft);
      const sortedList = _.orderBy(list, ['addTime'], ['asc']);
      return sortedList;
      // return list;
    },
    queueFarmt() {
      return this.$store.state.queuesAll.queueFarmt;
    },
    queueNftItemExchange() {
      return this.$store.state.queuesAll.queueNftItemExchange;
    },
    queueAll() {
      return this.$store.state.queuesAll;
    },
    receiptListLenInStore() {
      return this.$store.state.curEventWaitReceipt.list.length;
    },
    checkPingTooLong() {
      let status = '';
      if (this.nodePingInfo.lastRefreshPingTimeMills > 0) {
        const pingDiffTime = Math.floor((this.currentTimeStamp - this.nodePingInfo.lastRefreshPingTimeMills) / 1000);
        if (pingDiffTime > 120) {
          status = 'runtime';
        } else {
          status = '(' + pingDiffTime + 's)';
        }
      }
      return status;
    }
  },
  methods: {
    refreshFarmBalance() {
      this.$store.dispatch('getBalance', {
        tokenKey: 'token_farmt'
      });
    },
    refreshUsdtBalance() {
      this.$store.dispatch('getBalance', {
        tokenKey: 'token_usdt'
      });
    },
    gasPriceShortShow(value) {
      let tempValue = value;
      if (typeof tempValue == 'string') {
        tempValue = parseInt(tempValue);
      }
      return (tempValue / 1e9).toFixed(3);
    },
    viewReceiptNumInStore() {
      console.log('查看列表..如果某条记录不在store里说明基本是中途关闭app了');
      console.log(JSON.stringify(this.$store.state.curEventWaitReceipt));
    },
    singleUrge(info) {
      PolyEventManager.urgeCheck(info.queueNo);
    },
    async checkTxRpRunTimeError() {
      if (!Center.provider) {
        // console.log('provider not init');
        return;
      }
      for (let i = 0; i < this.allQueues.length; i++) {
        const queueItem = this.allQueues[i];
        if (queueItem.status == 'txResponse') {
          // 有可能中途关闭了,结果还在txResponse状态的,并且没报错的
          if (queueItem.iswrong && queueItem.err.code == 'TIMEOUT') {
            console.log('匹配到了TIMEOUT', queueItem.err);
            if (queueItem.receiptRetryArr.length == 0) {
              console.log(queueItem.queueNo + '第一次尝试' + new Date().getTime());
              showToast(this.UiText.autoCheckHash + queueItem.queueNo);
              PolyEventManager.urgeCheck(queueItem.queueNo);
            } else if (queueItem.receiptRetryArr.length <= 7) {
              let lastTryStartStamp = queueItem.receiptRetryArr[queueItem.receiptRetryArr.length - 1].startStamp;
              // 如果是大于0的情况.取上一条看看.看尝试过去了多久了.
              if (new Date().getTime() - lastTryStartStamp > 60 * 1000) {
                // 如果距离上次尝试又过了半分钟了.那就还可以再尝试
                console.log(queueItem.queueNo + '继续尝试' + new Date().getTime());
                showToast(this.UiText.autoCheckHash + queueItem.queueNo);
                PolyEventManager.urgeCheck(queueItem.queueNo);
              } else {
                console.log('距离上次检测hash操作时间还短' + queueItem.queueNo);
              }
            } else {
              console.log('重试次数上限');
            }
          }
          if (!queueItem.iswrong) {
            // 卡在txReponse.并且不报错.那就应该是中途重启了.
            // 用于应对在交易中途关掉app再开启的情况.
            const checkExist = await this.$store.dispatch('ExistInCurEventWaitForReceipt', {
              queueNo: queueItem.queueNo,
              hash: queueItem.txResponseInfo.hash
            });
            if (!checkExist) {
              const lastUrgeCheckItem = await this.$store.dispatch('ItemInUrgeWaitForReceipt', {
                queueNo: queueItem.queueNo,
                hash: queueItem.txResponseInfo.hash
              });
              if (!lastUrgeCheckItem) {
                // 当前内存里还没有催促过.则催促一次且只一次
                // 如果本次开机后这个事件不在当前事件队列里.没催促过.则去催促.
                PolyEventManager.urgeCheck(queueItem.queueNo);
              } else {
                // 这边一直催.当然是5分钟才一次的.然后超过5分钟.就设定为超时错误.就走上面的路线.所以这里不做次数判断限制.
                if (new Date().getTime() - lastUrgeCheckItem.start_stamp > 60 * 1000) {
                  PolyEventManager.urgeCheck(queueItem.queueNo);
                }
                // 这里就不再催了.或者说.
              }
              if (this.timePassed(queueItem.txTime) * 1000 > BaseContractSetting.txWaitTimeout) {
                //重开app后的这种催促每1分钟来一次.然后5分钟后如果没报错.这里给它设置个自定义的超时错误.然后它也能和常规流程一样走上面的路线了.
                console.log('中途退出app重进检测到未错误的超时');
                PolyEventManager.manualSetWaitTxTimeoutError(queueItem.queueNo);
              }
            }
          }

          /*到这里一直是卡在txReponse状态的*/
        }
      }
    },
    copyHashInError(txInfo) {
      if (txInfo && txInfo.hash && txInfo.hash.length > 0) {
        copyContent(txInfo.hash);
      }
    },
    timestampFormat(timestamp) {
      return ParseTimestampToFormat(timestamp, 4);
    },
    jumpToFriendList() {
      this.$router.push('/friends');
    },
    slientRemoveThoseFinalSuccess() {
      // console.log('静默删除检测');
      // 就当做不会很多吧
      for (let i = 0; i < this.allQueues.length; i++) {
        if (this.allQueues[i].status == 'receipt' && !this.allQueues[i].iswrong) {
          // 到了交易结束不报错
          if (this.allQueues[i].isFinalStep) {
            // 是finalStep行为，如果成功的话就可以静默删了
            if (this.allQueues[i].success) {
              // console.log('这条真可以自动删'+JSON.stringify(this.allQueues[i]));
              const itsReceiptTime = new Date(this.allQueues[i].receiptTime).getTime();
              if ((new Date().getTime() - itsReceiptTime) / 1000 > 10) {
                // console.log('成功了超过10秒了,再删');
                this.removeLine(this.allQueues[i]);
              }
            }
          }
        }
      }
    },
    clearErrCollector() {
      this.$store.commit('clearErrorCollector');
    },
    viewErrCollector() {
      this.errorCollectorPop.show = true;
    },
    timePassed(time) {
      return Math.round((this.currentTimeStamp - new Date(time).getTime()) / 1000);
    },
    notifyBusy(info) {
      // showToast(this.UiText.CD+':'+info.message)
      showToast(this.UiText.CDBusy);
    },
    runAgain(item) {
      const res = Center.runAgain(item);
      console.log('重跑返回', res);
      if (res.status) {} else {
        showToast(res.message);
      }
    },
    showRealDetail(item) {
      const qNo = item.queueNo;
      const abiName = item.abiName;
      const whichQueueContract = qNo.split('_')[0];
      this.ethEventViewOp.queueNo = qNo;
      this.ethEventViewOp.whichQueueContract = whichQueueContract;
      this.ethEventViewOp.abiName = abiName;
      this.ethEventViewOp.show = true;
    },
    showAll(item) {
      this.errorPop.content = JSON.stringify(item);
      this.errorPop.show = true;
      console.log('所有的内容', item);
      if (item.txResponseInfo) {
        this.errorPop.highLightHash = item.txResponseInfo.hash;
        copyContent(item.txResponseInfo.hash);
      }
    },
    parseErr(item) {
      if (item.err && item.err !== {} && item.err !== '') {
        if (JSON.stringify(item.err).indexOf('nonce has already been use') > -1) {
          return '@nonceTooLow';
        } else if (JSON.stringify(item.err).indexOf('nonceTooLow') > -1) {
          // 可能TryCatch里捕捉了.
          return '@nonceTooLow';
        }
      }
      return '@otherError';
    },
    showErr(item) {
      if (item.err && item.err !== {}) {
        this.errorPop.show = true;
        this.errorPop.content = JSON.stringify(item.err);
      }
    },
    // checkLine(info){
    //   PolyEventManager.urgeCheck(info.queueNo).then(res=>{
    //       console.log('check的返回结果',res);
    //       let basMsg='check返回:';
    //       if(res.txExist){
    //           basMsg+='[hash上链]';
    //           // 交易存在
    //           // res.hash;
    //           if(res.receiptStatus=='txNotExist'){}
    //           if(res.receiptStatus=='waiting'){basMsg+='等待中'}
    //           if(res.receiptStatus=='success'){basMsg+='交易成功'}
    //           if(res.receiptStatus=='fail'){basMsg+='交易失败'}
    //       }else{
    //           basMsg+='[未找到]:'+res.hash;
    //       }
    //       showToast(basMsg)
    //   }).catch(e=>{
    //       console.log('有抛出错误么',e);
    //   });
    // },
    removeLine(info) {
      PolyEventManager.removeEvent(info);
    },
    clearQueue() {
      // 只清理掉完全成功的部分
      this.$store.commit('clearSuccessQueue');
    },
    // commonCoolDown(){
    //   console.log("改冷卻");
    //     this.$store.dispatch('submitCooldown');
    // },
    clearAll() {
      // 只清理掉完全成功的部分
      this.$store.commit('clearAll');
    },
    changePos() {
      if (this.position == 'left') {
        this.position = 'right';
      } else {
        this.position = 'left';
      }
    },
    showData() {},
    bgmStart() {},
    box_belock_bykey(res) {
      // 盒子被钥匙解锁了
      console.log('监听盒子被钥匙解锁了', res);
      if (res.receiptInfo.status == 1) {
        const data = {
          levelStr: res.level,
          tokenId: res.tokenId,
          unlockType: 'key'
        };
        this.$store.commit('pushBoxUnlock', data);
      }
    },
    box_belock_byusdt(res) {
      console.log('监听盒子被u解锁了', res);
      // 盒子被usdt解锁了
      if (res.receiptInfo.status == 1) {
        const data = {
          levelStr: res.level,
          tokenId: res.tokenId,
          unlockType: 'usdt'
        };
        this.$store.commit('pushBoxUnlock', data);
      }
      this.refreshUsdtBalance();
    },
    finish_boxkey_create(info) {
      showToast(this.UiText.newGameKeyIsCreated);
      console.log('完成了钥匙铸造,延迟2秒去更新状态', info);
      setTimeout(() => {
        updateStorePlayerInfo();
      }, 1500);
      // 完成了
    },
    view_finish_openBox(info) {
      console.log('某盲盒被打开了', info);
      const level = parseInt(info.levelStr.substr(5, 1));
      this.listenNewEquipment.boxBase.tokenId = info.tokenId;
      this.listenNewEquipment.boxBase.level = level;
      GetBlindBoxData({
        level: level,
        token_id: this.listenNewEquipment.boxBase.tokenId
      }).then(res => {
        console.log('监听外层盒子打开信息', res);
        if (res.code == 200) {
          this.listenNewEquipment.show = true;
          this.listenNewEquipment.fullInfo = AttrsToEquipInfo(res.data.attributes);
          loadImgUrl(this.listenNewEquipment.fullInfo.imgOriPath).then(p => {
            this.listenNewEquipment.fullInfo.localImgPath = p;
          });
        } else {
          showToast(this.UiText.boxOpenButDataNotSync);
        }
      });
    },
    tryJumpPage(info) {
      if (info.iswrong) {
        if (info.err.toString().indexOf('nonce has already been use') > -1) {
          Center.DelayNonceRefreshAfterToolowError();
        }
      }
      if (!info.isFinalStep) {
        // 只有前置类操作才有跳转的必要
        console.log('尝试', JSON.stringify(info));
        const curPath = this.$route.fullPath;
        let actualJumpQueueNo = '';
        if (info.queueNo.indexOf('queueNft_') > -1) {
          // const boxRecycleReady = '&&open_page=recycle&&open_time='+new Date().getTime();
          // 可能是钥匙解锁.和 u解锁的?
          if (info.abiName == 'PaymentKey') {
            const {
              level,
              tokenId
            } = info.callArgs;
            const openBoxReady = '&&open_page=openready&&open_time=' + new Date().getTime();
            const targetPath = '/warehouse_item_detail/blindbox?box_level=' + level + '&&token_id=' + tokenId + openBoxReady;
            if (curPath.indexOf('/warehouse_item_detail/blindbox') > -1) {
              this.$router.replace(targetPath);
            } else {
              this.$router.push(targetPath);
            }
            // this.$router.push('/warehouse_item_detail/blindbox?box_level='+level+'&&token_id='+tokenId+openBoxReady);
            actualJumpQueueNo = info.queueNo;
            console.log('钥匙解锁的可以考虑');
          }
          if (info.abiName == 'Payment') {
            const {
              level,
              tokenId
            } = info.callArgs;
            const openBoxReady = '&&open_page=openready&&open_time=' + new Date().getTime();
            const targetPath = '/warehouse_item_detail/blindbox?box_level=' + level + '&&token_id=' + tokenId + openBoxReady;
            if (curPath.indexOf('/warehouse_item_detail/blindbox') > -1) {
              this.$router.replace(targetPath);
            } else {
              this.$router.push(targetPath);
            }
            // this.$router.push('/warehouse_item_detail/blindbox?box_level='+level+'&&token_id='+tokenId+openBoxReady);
            actualJumpQueueNo = info.queueNo;
            console.log('u解锁的跳转');
          }
          if (info.abiName == 'approve') {
            console.log('abi是approve');
            const {
              level,
              spender,
              tokenId
            } = info.callArgs;
            if (spender == 'nftItemExchange') {
              const source = info.source;
              // 盲盒和装备的回收都是这个入口需要分开
              if (source.ename == 'approveForEquipmentRoll') {
                // 跳装备的

                const equipRecycleReady = '&&open_page=recycle&&open_time=' + new Date().getTime();
                const targetPath = '/warehouse_item_detail/equipment?nftlevel=' + level + '&&tokenid=' + tokenId + equipRecycleReady;
                if (curPath.indexOf('/warehouse_item_detail/equipment') > -1) {
                  this.$router.replace(targetPath);
                } else {
                  this.$router.push(targetPath);
                }
                // this.$router.push('/warehouse_item_detail/equipment?nftlevel='+level+'&&tokenid='+tokenId+equipRecycleReady);
                actualJumpQueueNo = info.queueNo;
              }
              if (source.ename == 'approveForBoxRoll') {
                const boxRecycleReady = '&&open_page=recycle&&open_time=' + new Date().getTime();
                // 这是盲盒回收前的授权
                const targetPath = '/warehouse_item_detail/blindbox?box_level=' + level + '&&token_id=' + tokenId + boxRecycleReady;
                if (curPath.indexOf('/warehouse_item_detail/blindbox') > -1) {
                  this.$router.replace(targetPath);
                } else {
                  this.$router.push(targetPath);
                }
                // this.$router.push('/warehouse_item_detail/blindbox?box_level='+level+'&&token_id='+tokenId+boxRecycleReady);
                actualJumpQueueNo = info.queueNo;
              }
            }
            if (spender == 'market') {
              console.log('spender>market');
              // 这个是上架的准备
              const source = info.source;
              if (source.ename == 'approveForEquipSell') {
                console.log('应该是装备上架前的准备吧');
                const equipSellReady = '&&open_page=tosell&&open_time=' + new Date().getTime();
                const targetPath = '/warehouse_item_detail/equipment?nftlevel=' + level + '&&tokenid=' + tokenId + equipSellReady;
                if (curPath.indexOf('/warehouse_item_detail/equipment') > -1) {
                  this.$router.replace(targetPath);
                } else {
                  this.$router.push(targetPath);
                }
                // this.$router.push('/warehouse_item_detail/equipment?nftlevel='+level+'&&tokenid='+tokenId+equipSellReady);
                actualJumpQueueNo = info.queueNo;
              }
            }
            console.log('授权的呢');
          }
        } else if (info.queueNo.indexOf('queueGoldenNft_') > -1) {
          if (info.abiName == 'approve_toconsi') {
            // 这个只能跳转到列表
            const eventOptPlus = info.callArgs.eventOptPlus;
            if (eventOptPlus) {
              const buyer = eventOptPlus.buyer;
              const goldType = eventOptPlus.goldType;
              this.$router.push('/goldAskBuy?is_gold=true&&buyer=' + buyer + '&&gold_type=' + goldType);
              actualJumpQueueNo = info.queueNo;
            } else {
              this.$router.replace('/main/playermarket/goldpurchase');
              actualJumpQueueNo = info.queueNo;
            }
          }
        }
        if (actualJumpQueueNo && actualJumpQueueNo.length > 0) {
          if (info.status == 'receipt' || info.iswrong) {
            // 如果完结了.或者如果出错了.都是点了后延迟删除
            setTimeout(() => {
              PolyEventManager.removeEvent({
                queueNo: actualJumpQueueNo
              }).then(res => {});
            }, 2000);
          }
        } else {
          console.log('不能删除的情况');
        }
      } else {
        if (info.status == 'receipt') {
          if (info.success) {
            // 成功或失败暂时都是同样的行为.
            PolyEventManager.removeEvent({
              queueNo: info.queueNo
            }).then(res => {});
          } else {
            PolyEventManager.removeEvent({
              queueNo: info.queueNo
            }).then(res => {});
          }
          // 点图标就想关闭的话至少要是正常成功的状态.
        } else if (info.status == 'new') {
          if (info.iswrong) {
            // 在new阶段报错的话当然能清除.
            PolyEventManager.removeEvent({
              queueNo: info.queueNo
            }).then(res => {});
          } else {
            // 或者如果没报错但是卡在new实在太久的话.也清理
            const stayTooLong = this.EventStuckAtNew(info);
            if (stayTooLong) {
              PolyEventManager.removeEvent({
                queueNo: info.queueNo
              }).then(res => {});
            } else {
              showToast(this.UiText.waitplease);
            }
          }
        } else if (info.status == 'txResponse') {
          if (info.iswrong) {
            // 在new阶段报错的话当然能清除.
            PolyEventManager.removeEvent({
              queueNo: info.queueNo
            }).then(res => {});
          } else {
            // 这个如果超时的话是会报错的.所以不用做卡new时间判断.
          }
        }
      }
    },
    EventStuckAtNew(info) {
      // 如果卡在new3分钟就算是卡了.
      if (info.status == 'new' && this.timePassed(info.newTime) >= 3 * 60) {
        return true;
      } else {
        return false;
      }
    },
    box_new_created() {
      // 买盒子用的是farm

      this.refreshFarmBalance();
    },
    buy_equipment() {
      // 买了装备了
      this.refreshFarmBalance();
    },
    buy_service(info) {
      // 买了服务..比如vip，土地扩展一类的.

      console.log('queue内完成服务的监听', info);
      const {
        source,
        receiptInfo,
        eventOpt
      } = info;
      if (source == SourceTag.in_U购买服务.s_购买VIP服务) {
        // 买vip也会用u并且要刷新用户本身的状态
        showToast(this.UiText.vipServiceStatusUpdated);
        updateStorePlayerInfo();
        this.refreshUsdtBalance();
      }
      if (source == SourceTag.in_U购买服务.s_土地升级) {
        this.refreshUsdtBalance();
      }
      if (source == SourceTag.in_U购买服务.s_土地扩展) {
        this.refreshUsdtBalance();
      }
    },
    addGoldOrder() {
      // 求购单取消新建是用的farm
      this.refreshFarmBalance();
    }
  },
  created() {},
  mounted() {
    // 0 购买盲盒会放farm变动
    // 1.购买装备.会让farm变动 2. 取消求购会让farm变动 3.发起求购会让farm变动 .买服务会让u变动.
    // 解锁盲盒会让u变动.

    EventManager.on(EventEnum.buyNewBlindBox, this.box_new_created);
    EventManager.on(EventEnum.equipmentBuy, this.buy_equipment);
    EventManager.on(EventEnum.paymentForService, this.buy_service);
    EventManager.on(EventEnum.AddGoldNftOrder, this.addGoldOrder);
    EventManager.on(EventEnum.CancelGoldNftOrder, this.addGoldOrder);
    EventManager.on(EventEnum.usdtPayToBox, this.box_belock_byusdt);
    EventManager.on(EventEnum.keyPayToBox, this.box_belock_bykey);
    EventManager.on(EventEnum.submitCommonCD, this.notifyBusy);
    EventManager.on(EventEnum.finishOpenBox, this.view_finish_openBox);
    EventManager.on(EventEnum.boxKeyFinishCreate, this.finish_boxkey_create);
    setInterval(() => {
      this.slientRemoveThoseFinalSuccess();
    }, 5000);
    setInterval(() => {
      this.checkTxRpRunTimeError();
    }, 2000);
  },
  beforeUnmount() {
    EventManager.off(EventEnum.buyNewBlindBox, this.box_new_created);
    EventManager.off(EventEnum.equipmentBuy, this.buy_equipment);
    EventManager.off(EventEnum.paymentForService, this.buy_service);
    EventManager.off(EventEnum.AddGoldNftOrder, this.addGoldOrder);
    EventManager.off(EventEnum.CancelGoldNftOrder, this.addGoldOrder);
    EventManager.off(EventEnum.usdtPayToBox, this.box_belock_byusdt);
    EventManager.off(EventEnum.keyPayToBox, this.box_belock_bykey);
    EventManager.off(EventEnum.submitCommonCD, this.notifyBusy);
    EventManager.off(EventEnum.finishOpenBox, this.view_finish_openBox);
    EventManager.off(EventEnum.boxKeyFinishCreate, this.finish_boxkey_create);
  },
  destroyed() {}
};