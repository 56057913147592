import { createStore } from 'vuex'
import {Center} from '@/chainField/chainCenter';
import {Farmt,Usdt,MainPol,UniSwap2} from '@/chainField/contractApi'
import createPersistedState  from 'vuex-persistedstate';
import {showToast}  from 'vant';
import {PolyEventManager} from "@/util/polyEventProgress";
import { Mutex } from 'async-mutex';
const mutex = new Mutex();
import _ from 'lodash';
export default createStore({
  state: {
    appBaseOption:{
      language:'en', //默认英文.
    },
    setGasPriceInTx:false, // 每次发起交易的时候是否设置gas.默认为true
    currentTimeStamp:0,
    provider_nonce:-1,
    txSubmit:{isBusy:false,busyNonce:-1,intoBusyTime:0},
    blockNumber:0,
    pingInfo:{updateTime:0,NodeHealth:false,lastRefreshPingTimeMills:0,pingRunError:false},
    scanResult:{needRead:false, result:''},
    appRunStatus:{
      debugMode:false,
      mySoldGoldNfts:[],
      landLvUpRecords:[],
      landExpandRecords:[],
      unlockBlindBoxs:[], // 有时候解锁后比较慢啊
    },
    actionInLoginStatus:{
      // session保存级。每次进来重算的.
      gameNotify:{
        readAfterLogin:false,   // 进入游戏后.是否看过公告了.
      }
    },
    actionPersistenceStatus:{
      lastEncryWAddress:'',
      gameNotify: {
        readNotifyIds:[],
      },
      debugBubble:{
        show:false
      }
    },
    token_pol:{
      balance:{
        suaAmount:0,
        hraAmount:0,
      }
    },
    token_farmt:{
      balance:{
        suaAmount:0,
        hraAmount:0,
        doller:{
          hraAmount:0
        }
      },
      allowance:{
        nftItemExchange: {},
        market:{},
        goldenConsignment:{},
        uniswapV2Router:{},
      }
    },
    token_usdt:{
      balance:{
        suaAmount:0,
        hraAmount:0,
      },
      allowance:{
        payment:{},
        level1:{},
        level2:{},
        level3:{},
        uniswapV2Router:{},
      }
    },
    token_nft:{
      allowanceTokenId:{
        nftItemExchange:-1,
      }
    },
    seedDetailInfo:'',
    storage:{},
    errorCollector:{
      list:[],
    },
    queuesAll:{
      queueFarmt:[],
      queueGoldenNft:[],
      queueMarket:[],
      queuePol:[],
      queueNft:[],
      queuePayment:[],
      queueUniswapv2:[],
      queueUsdt:[],
      queueNftItemExchange:[],
      queueBoxKey:[], //这是盒子钥匙的.
      queueGoldConsignment:[], // 这个是金币求购的
    },// 事件队列
    curEventWaitReceipt:{
      list:[]
    }, // 有若干事件是等待着receipt的.但是store里不可以存promsie的哦.所以注意一下
    UrgeInnerWaitReceipt:{
      list:[], //  这种是checkTransaction里才会加上的.和常规悬浮窗里的那种形式分开一下.
    },
    count:0,
    winHeight:0,
    winCurHeight:0,
    winWidth:0,
    winCurWidth:0,
    playerInfo:{
      my_id:'',
      user_id:-1,
      address:'',
      anti_theft_probability:'',
      em: 0,
      exp:0,
      gold_amount: 0,
      growth_rate: 0,
      guild_name: "",
      kettle: "",
      level:1,
      name:'',
      next_levle_exp:0,
      pesticide: "",
      pet:"",
      shovel:"",
      steal_count:0,
      truck: ""
    },
    landInfo: {},
    seedId: null,
    marketItemDetail:{
      CreatedAt:null,
      ID:-1,
      contract_addr:'',
      data:{},
      gold_type:'',
      level:'0',
      owned_by_user:false,
      price:0,
      token_id:'',
      user_addr:"0x"
    },
    pureEffectValueJson:{},// 是个对象
    fasterThanServerMilliSec:0, // 本地比服务器快多少毫秒?
    newRunTime:30,
  },
  getters: {
    appActionStatus(state){
      return {...state.actionInLoginStatus.gameNotify,...state.actionPersistenceStatus.gameNotify}
    },
  },
  mutations: {
    changeDebugBubbleShow(state){
      console.log('改变泡泡显示');
      state.actionPersistenceStatus.debugBubble.show=!state.actionPersistenceStatus.debugBubble.show;
    },
    appActionSet(state,info){
      const {action} = info;
      if(action=='readAfterLogin'){
        console.log('store appaction set red.');
        state.actionInLoginStatus.gameNotify.readAfterLogin=true;
      }
      if(action=='lastNotifyVersion'){
        console.log('store appaction readids');
        state.actionPersistenceStatus.gameNotify.readNotifyIds=info.readNotifyIds;
      }
    },
    pushMySoldGoldNft(state,info){
      state.appRunStatus.mySoldGoldNfts.push({goldType:info.goldType,tokenId:info.tokenId});
      // 放入一个我卖出的金币
    },
    pushLandLvupRecord(state,info){
      state.appRunStatus.landLvUpRecords.push({landIndex:info.landIndex,plotIndex:info.plotIndex,nextLevel:info.nextLevel});
    },
    pushLandExpandRecord(state,info){
      state.appRunStatus.landExpandRecords.push({landIndex:info.landIndex,plotIndex:info.plotIndex});
    },

    pushBoxUnlock(state,info){
      state.appRunStatus.unlockBlindBoxs.push({levelStr:info.levelStr,tokenId:info.tokenId,unlockType:info.unlockType});
    },
    setLanguage(state,lng){
      state.appBaseOption.language=lng;
    },
    gasPriceSetOnOff(state){
      state.setGasPriceInTx=!state.setGasPriceInTx;
    },
    uidebug(state){
      state.appRunStatus.debugMode=!state.appRunStatus.debugMode;
    },
    setNewRuntime(state,value){
      state.newRunTime=value;
    },
    fasterThanServer(state,value){
      console.log('local faster than server millssecs',value);
      state.fasterThanServerMilliSec=value;
    },
    updateLocalTime(state){
      state.currentTimeStamp = new Date().getTime();
    },
    setPingInfo(state,info){
      // console.warn('setPinginfo',info);
      const {blockNumber,NodeHealth,nodeDelay} =info;
      state.pingInfo.NodeHealth=NodeHealth;
      state.pingInfo.updateTime=new Date().getTime();
      if(NodeHealth){
        state.blockNumber=blockNumber;
        state.pingInfo.nodeDelay= nodeDelay;
        state.pingInfo.lastRefreshPingTimeMills = new Date().getTime();
        state.pingInfo.pingRunError=false;
      }else{
        state.pingInfo.nodeDelay= -1;
        state.pingInfo.pingRunError=true;
      }
    },
    setBlockNumber(state,blockNumber){
      // 就是当前的事件啊.大概是到了哪个blockNumber了
      console.log('设置当前blockNumber到了',blockNumber);
      state.blockNumber = blockNumber;
      state.pingInfo.NodeHealth=true;
    },
    setNonce(state,nonce){
      if(state.provider_nonce>nonce){
        const oldV=state.provider_nonce;
        // showToast('禁止nonce从:'+oldV+'退回'+nonce);
      }else{
        const oldV=state.provider_nonce;
        // showToast('nonce refresh'+oldV+'>'+nonce);
        state.provider_nonce=nonce;
      }
    },
    incrementNonce(state){
      state.provider_nonce+=1;
      console.warn(`Nonce incremented to: ${state.provider_nonce}`);
    },
    setNonceFromTo(state,info){
      const {oldNonce,newNonce}=info;
      console.warn('setNonceFromTo'+new Date().getTime());
      if(state.provider_nonce==oldNonce){
        console.log('------把nonce:'+oldNonce+'改成'+newNonce+'----------');
        state.provider_nonce=newNonce;
      }else{
        console.warn('oldNonce不匹配不增加');
      }
    },
    reduceNonce(state){
      state.provider_nonce-=1;
      console.warn(`Nonce reduce to: ${state.provider_nonce}`);
    },
    managerAdjustNonce(state,info){
      const {action,value} = info;
      if(action=='add'){
        state.provider_nonce+=1;
      }
      if(action=='reduce'){
        state.provider_nonce-=1;
      }
      if(action=='set'){
        state.provider_nonce-=value;
      }
    },
    addCurEventWaitForReceipt(state,info){
      state.curEventWaitReceipt.list.push({queueNo:info.queueNo,hash:info.hash,time:info.time});
    },
    removeCurEventWaitForReceipt(state,info){
      const {hash}=info;
      for(let i=0;i<state.curEventWaitReceipt.list.length;i++){
        if(state.curEventWaitReceipt.list[i].hash==hash){
          state.curEventWaitReceipt.list.splice(i,1);
          i--;
        }
      }
    },
    addTxUrgeWaitForReceipt(state,info){
      console.warn('增加一条催促信息',info);
      state.UrgeInnerWaitReceipt.list.push({uniqueTag:info.uniqueTag,queueNo:info.queueNo,hash:info.hash,start_stamp: new Date().getTime()});
    },
    finishTxUrgeWaitForReceipt(state,info){
      const {hash,receipt_status,uniqueTag}=info;
      for(let i=0;i<state.UrgeInnerWaitReceipt.list.length;i++){
        if(state.UrgeInnerWaitReceipt.list[i].hash==hash&&state.UrgeInnerWaitReceipt.list[i].uniqueTag==uniqueTag){
          state.UrgeInnerWaitReceipt.list[i].end_stamp= new Date().getTime();
          state.UrgeInnerWaitReceipt.list[i].receipt_status= receipt_status;
          console.log('内部催促receipt完结后列出',JSON.stringify(state.UrgeInnerWaitReceipt.list));
        }
      }
    },
    ClearInUrgeWaitForReceipt({state}){
      console.log('清理一下Urgelist');
      return state.UrgeInnerWaitReceipt.list=[];
    },
    setScanResult(state,info){
      const {result} = info;
      state.scanResult.needRead=true;
      state.scanResult.result=result;
    },
    clearScanResult(state){
      state.scanResult.needRead=false;
    },
    clearSuccessQueue(state,info){
      console.log('只清理成功的部分');
      for(let key in state.queuesAll){
        for(let i=0;i<state.queuesAll[key].length;i++){
          if(state.queuesAll[key][i].success){
            state.queuesAll[key].splice(i,1);
            i--;
          }
        }
        // state.queuesAll[key]=[];
      }
    },
    clearAll(state,info){
      console.log('只清理成功的部分');
      for(let key in state.queuesAll){
        state.queuesAll[key]=[];
      }
    },
    addAloneQueue(state,{queueGroup,addedEvent}){
      console.log('自检新建个完整事件',addedEvent);
      state.queuesAll[queueGroup].push(addedEvent);
    },
    addToQueue(state,info){
      const {queueGroup,data} =info;
      // data.updateTime=new Date();
      data.newTime = new Date();
      state.queuesAll[queueGroup].push(data);
    },
    removeInQueue(state,info){
      const {queueGroup,index}=info;
      state.queuesAll[queueGroup].splice(index,1);
    },
    updateToQueue(state,info){
      console.log('修改的参数>>>>>',info);
      const {queueGroup,index,status,success}=info;

      if(status=='new'){
        // 补充信息.这个大概不会碰到了.
        state.queuesAll[queueGroup][index].data=info.data;
      }
      if(status=='txResponse'||status=='receipt')
      {
        // state.queuesAll[queueGroup][index].updateTime= new Date();
        state.queuesAll[queueGroup][index].status=status;
      }
      if(status=='txResponse'){
        state.queuesAll[queueGroup][index].txTime= new Date();
        state.queuesAll[queueGroup][index].txResponseInfo=info.txResponseInfo;
      }
      if(status=='receipt'){
        state.queuesAll[queueGroup][index].receiptTime= new Date();
        if(info.receipt){
          state.queuesAll[queueGroup][index].receiptInfo= info.receipt;
        }
        // 这个成功特指receipt的成功.
        state.queuesAll[queueGroup][index].success=success;
        // 如果成功了.则可以把这个错误标记去掉
      }
    },
    endNormalToQueue(state,info){
      console.log('修改的参数>>>>>',info);
      const {queueGroup,index,status,success}=info;
      if(status=='receipt'){
        state.queuesAll[queueGroup][index].status=status;
        state.queuesAll[queueGroup][index].receiptTime= new Date();
        if(info.receipt){
          state.queuesAll[queueGroup][index].receiptInfo= info.receipt;
        }
        // 这个成功特指receipt的成功.
        state.queuesAll[queueGroup][index].success=success;
        // 如果成功了.则可以把这个错误标记去掉
        state.queuesAll[queueGroup][index].iswrong=false;
        state.queuesAll[queueGroup][index].err={};
      }
    },
    endInCheckToQueue(state,info){
      // end里的专门弄一个.
      console.log('修改的参数>>>>>',info);
      const {queueGroup,index,status,success,retryTag,hashFound}=info;
      if(hashFound){
        if(status=='receipt'){
          state.queuesAll[queueGroup][index].status=status;
          state.queuesAll[queueGroup][index].receiptTime= new Date();
          if(info.receipt){
            state.queuesAll[queueGroup][index].receiptInfo= info.receipt;
          }
          // 这个成功特指receipt的成功.
          state.queuesAll[queueGroup][index].success=success;
          state.queuesAll[queueGroup][index].iswrong=false;
          state.queuesAll[queueGroup][index].err={};
          if(retryTag){
            // 如果有tag的话.

            // 如果是正在尝试的话..额..
            let innerIndex= _.findIndex(state.queuesAll[queueGroup][index].receiptRetryArr,o=>o.indexTag==retryTag);
            if(innerIndex>-1){
              state.queuesAll[queueGroup][index].receiptRetryArr[innerIndex].returnStamp = new Date().getTime();
              if(info.receipt){
                state.queuesAll[queueGroup][index].receiptRetryArr[innerIndex].returnStatus = info.receipt.status;
              }

            }
          }
        }
      }else{
          const innerIndex= _.findIndex(state.queuesAll[queueGroup][index].receiptRetryArr,o=>o.indexTag==retryTag);
          if(innerIndex>-1){
            state.queuesAll[queueGroup][index].receiptRetryArr[innerIndex].returnStamp = new Date().getTime();
            state.queuesAll[queueGroup][index].receiptRetryArr[innerIndex].returnStatus = -2;
          }
      }


    },
    AddTxOptions(state,info){
      console.log('修改的参数>>>>>',info);
      const {queueGroup,index,status}=info;

      if(status=='new'&&info.txOptions){
        state.queuesAll[queueGroup][index].txOptions=info.txOptions;
      }
    },
    errorToQueue(state,info){
      const {queueGroup,index,err}=info;
      state.queuesAll[queueGroup][index].errTime = new Date();
        // 这个成功特指receipt的成功.
      state.queuesAll[queueGroup][index].success=false;
      if(err){
        state.queuesAll[queueGroup][index].iswrong=true;
        state.queuesAll[queueGroup][index].err=err;
      }
    },
    receiptErrQueueStartTry(state,{queueGroup,index,retryTag,hash}){
      state.queuesAll[queueGroup][index].receiptRetryArr.push({
        indexTag:retryTag, //
        hash:hash,
        startStamp:new Date().getTime(),
        returnStamp:0,
        returnStatus:-1
      });

      // 标注一下代表这个记录开始了一次尝试
    },
    storeSetWaitTxTimeout(state,{queueGroup,index,hash}){
      // 这个是页面端.中途退出app重进会导致之前的tx超时检测不在内存里了.所以这里模仿设置个错误.
      if(state.queuesAll[queueGroup][index].status=='txResponse'){
        if(!state.queuesAll[queueGroup][index].iswrong){
          // 当然是没有错误的时候才能设置错误了
          state.queuesAll[queueGroup][index].iswrong=true;
          const theError = new Error('custom wait for transaction timeout');
          theError.code='TIMEOUT';
          state.queuesAll[queueGroup][index].err = theError;
          state.queuesAll[queueGroup][index].errTime = new Date();
        }
      }
    },
    ClearErrAtTxResponse(state,{queueGroup,index,hash}){
      if(state.queuesAll[queueGroup][index].status=='txResponse'){
        if(state.queuesAll[queueGroup][index].iswrong){
          state.queuesAll[queueGroup][index].iswrong=false;
          state.queuesAll[queueGroup][index].err = {};
        }

      }
    },
    errorOnlySave(state,info){
      const {queueGroup,index,err,txResponse}=info;
      let savemsg='';
      if(err instanceof Error){
        savemsg =err.toString();
      }else{
        savemsg =err;
      }
      state.errorCollector.list.unshift({expectGroup:queueGroup,txResponse:txResponse,matchIndex:index,content:savemsg,errTime:new Date().getTime()});
      if(state.errorCollector.list.length>200){
        // 错误收集器改200个上限.
        state.errorCollector.list.splice(200);
      }
    },
    clearErrorCollector(state){
      showToast('清理错误收集器');
      state.errorCollector.list=[];
    },
    QueueMarkBeReplace(state,info){
      const {queueGroup,index,replacedQueueNo,replaceByQueueNo}=info;
      state.queuesAll[queueGroup][index].retryQueueNoArr.push(replaceByQueueNo);
      state.queuesAll[queueGroup][index].beReplaced=true;
      state.queuesAll[queueGroup][index].replaceByQueueNo=replaceByQueueNo;
    },
    setPureEffectValue(state,info){
      // 所有装备信息的效果，应该没了
      state.pureEffectValueJson=info;
    },
    setMarketItem(state,info){
      console.log('store想要看拍卖行物品',info);
      state.marketItemDetail=info;
    },
    setSeedInfo(state,info){
      state.seedDetailInfo=info;
    },
    setPlayerInfo(state,info){
      this.state.playerInfo=info;
    },
    setLandInfo(state,info){
      this.state.landInfo = info;
    },
    increment(state){
      state.count++;
    },
    decrement(state){
      state.count--;
    },
    setWinSize(state,{height,width}){
      state.winHeight=height;
      state.winWidth=width;
    },
    setWinCurSize(state,{height,width}){
      state.winCurHeight=height;
      state.winCurWidth=width;
    },
    setSeedId(state, data) {
      state.seedId = data
      console.log('赋值', state.seedId)
    },
    removeSeedId(state, data) {
      state.seedId = null
      console.log('清空', state.seedId)
    },
    setBalance(state,{tokenKey,hraAmount,suaAmount}){
      if(hraAmount!==undefined){
        state[tokenKey].balance.hraAmount=hraAmount;
      }
      if(suaAmount!==undefined){
        state[tokenKey].balance.suaAmount=suaAmount.toString();
      }
    },
    setBalanceDoller(state,{tokenKey,hraAmount}){
      console.log('store_setBalanceDoller',tokenKey,hraAmount);
      if(tokenKey=='token_farmt'){
        state.token_farmt.balance.doller.hraAmount=hraAmount.toString();
      }
    },
    setAllowance(state,{tokenKey,spender,hraAmount,suaAmount}){
      console.log('store.setAllowance'+spender+'token->'+tokenKey);
      state[tokenKey].allowance[spender].hraAmount=hraAmount;
      state[tokenKey].allowance[spender].suaAmount=suaAmount
    },
    txSubmitSetNonce(state,nonce){
      if(state.txSubmit.isBusy){
        state.txSubmit.busyNonce=nonce;
      }
    },
    txSubmitNonceReset(state){
      state.txSubmit.busyNonce=-1;
    },
    txSubmitSetBusy(state){
      state.txSubmit.isBusy=true;
      state.txSubmit.intoBusyTime=new Date().getTime();
    },
    txSubmitSetFree(state){
      state.txSubmit.isBusy=false;
    },
    resetWhenSetAddress(state,info){
      const _address = info.address;
      console.log('resetWhenSetAddress');
      let needReset=false;
      if(state.actionPersistenceStatus.lastEncryWAddress==''){
        // 如果上次为空的。说明是旧版本，要清理数据的.
        needReset=true;
      }else{
        if(state.actionPersistenceStatus.lastEncryWAddress!==_address){
          // 换了新地址进来，也要清理数据
          needReset=true;
        }else{
          // 还是这个地址，不用清理
          needReset=false;
        }
      }
      console.log('是否清理',needReset);
      state.playerInfo.name='';
      state.playerInfo.address='';
      state.playerInfo.my_id='';
      state.playerInfo.user_id=-1;
      state.txSubmit.isBusy=false;
      state.txSubmit.busyNonce=-1;
      state.provider_nonce=-1;
      // nonce反正不管怎样都清理一下.主要是悬浮窗.和余额
      if(needReset){
        state.appRunStatus.debugMode=false;
        state.appRunStatus.mySoldGoldNfts=[];
        state.appRunStatus.landLvUpRecords=[];
        state.appRunStatus.landExpandRecords=[];
        state.appRunStatus.unlockBlindBoxs=[];
        state.curEventWaitReceipt.list=[];
        state.UrgeInnerWaitReceipt.list=[];
        // 悬浮窗是都要清理的.
        for(let key in state.queuesAll){
          state.queuesAll[key]=[];
        }
        // 错误收集器也清理.
        state.errorCollector.list=[];
        state.token_pol.balance={
          suaAmount:0,
          hraAmount:0,
        };
        state.token_farmt.balance={
          suaAmount:0,
          hraAmount:0,
          doller:{
            hraAmount:0
          }
        };
        state.token_farmt.allowance={
          nftItemExchange: {},
          market:{},
          goldenConsignment:{},
          uniswapV2Router:{},
        };
        state.token_usdt.balance={
          suaAmount:0,
          hraAmount:0,
        };

        state.token_usdt.allowance={
          payment:{},
          level1:{},
          level2:{},
          level3:{},
          uniswapV2Router:{},
        };

      }
      // 如果当前的address和上一个值不同..

      state.actionPersistenceStatus.lastEncryWAddress=_address;
    },
  },
  actions: {

    StartClock({state,commit}){
      console.log('开启定时器');
      setInterval(()=>{
        commit('updateLocalTime');
      },1000)
    },
    ExistInCurEventWaitForReceipt({state},info){
      return _.findIndex(state.curEventWaitReceipt.list,o=>o.queueNo=info.queueNo&&o.hash==info.hash)>-1;
    },
    ItemInUrgeWaitForReceipt({state},info){
      return _.findLast(state.UrgeInnerWaitReceipt.list,o=>o.queueNo=info.queueNo&&o.hash==info.hash);
    },
    // async howLongFromLastIntoCD({state,commit}){
    //   return await mutex.runExclusive(async () => {
    //     console.log();state.txSubmit.intoBusyTime
    //     // 距离上一次进入cd多久了?
    //   });
    // },
    async submitCouldStart({state,commit}){
      return await mutex.runExclusive(async () => {
        // 如果cd是负值.则可以改.
        const {isBusy} = state.txSubmit;
        if(isBusy==true){
          // 如果正在提交.说明不能跑下一步
          return false;
        }else{
          // 如果不在提交.则设为true并且能走下一步
          commit('txSubmitSetBusy');
          return true;
        }
      });
    },
    async submitSetNonceIfBusy({state,commit},info){
      return await mutex.runExclusive(async () => {
        commit('txSubmitSetNonce',info.nonce);
        return true;
      });
    },
    async submitCooldown({state,commit}){
      return await mutex.runExclusive(async () => {
        commit('txSubmitSetFree');
      });
    },
    async submitCooldownByNonce({state,commit},info){
      return await mutex.runExclusive(async () => {
        console.log('调用byNonce冷却的参数',info);
        if(info.nonce>=0){
          if(state.txSubmit.busyNonce==info.nonce){
            commit('txSubmitSetFree');
            commit('txSubmitNonceReset');
          }
        }else{
          console.warn(info)
        }


      });
    },

    async getNonce({state,commit}){
      return await mutex.runExclusive(async () => {
        const nonce = state.provider_nonce;
        console.log('Current nonce:'+nonce,new Date().getTime());
        return nonce;
      });
    },
    async getNonceAndIncre({state,commit}){
      return await mutex.runExclusive(async () => {
        const nonce = state.provider_nonce;
        console.log('Current nonce:'+nonce,new Date().getTime());
        commit('incrementNonce');
        return nonce;
      });
    },
    async storeNonceFromTo({state,commit},{oldNonce,newNonce}){
      console.warn('必须是从oldNonce'+oldNonce+'转到'+newNonce+'>at.'+new Date().getTime());
      return await mutex.runExclusive(async () => {
        commit('setNonceFromTo',{oldNonce,newNonce});
      });
    },
    async NonceReduce({state,commit}){
      return await mutex.runExclusive(async () => {
        commit('reduceNonce');
      });
    },
    async action_setNonce({commit},nonce) {
      await mutex.runExclusive(async () => {
        commit('setNonce',nonce);
      });
    },
    async getAllowance(state,{tokenKey,spender}){
      if(tokenKey=='token_usdt'){
        const alloRes = await Usdt.viewAllowance(spender);
        if(!alloRes.status){
          return ;
        }
        state.commit('setAllowance',{tokenKey,spender,hraAmount:alloRes.hraAmount,suaAmount:alloRes.suaAmount.toString()});
      }
      if(tokenKey=='token_farmt'){
        const alloRes = await Farmt.viewAllowance(spender);
        if(!alloRes.status){
          return ;
        }
        state.commit('setAllowance',{tokenKey,spender,hraAmount:alloRes.hraAmount,suaAmount:alloRes.suaAmount.toString()});
      }
    },
    async getBalance(state,{tokenKey,needDoller=false}){
      if(tokenKey=='token_farmt'){
        const bRes = await Farmt.balance();
        state.commit('setBalance',{
          tokenKey,
          suaAmount:bRes.suaBalance,
          hraAmount:bRes.hraBalance
        });
        if(needDoller==true){
          const dollerRes = await UniSwap2.swapExpect(bRes.hraBalance,'farm','usdt');
          if(dollerRes.status){
            state.commit('setBalanceDoller',{tokenKey,hraAmount:dollerRes.hraOutAmount})
          }else{
            // 内部报错
          }

        }
      }
      if(tokenKey=='token_usdt'){
        const bRes = await Usdt.balance();
        state.commit('setBalance',{
          tokenKey,
          suaAmount:bRes.suaBalance,
          hraAmount:bRes.hraBalance
        });
      }
      if(tokenKey=='token_pol'){
        const mRes =await MainPol.balance();
        state.commit('setBalance',{
          tokenKey,
          suaAmount:mRes.suaBalance,
          hraAmount:mRes.hraBalance
        });
      }
    },
    incrementAsync(commit,info){
      Center.simulatePageLogin();
      // 异步执行.会阻塞dom么?

    }
  },
  modules: {
  },
  plugins:[
    createPersistedState({
      storage:window.sessionStorage,
      reducer(val){
        return {
          appRunStatus:val.appRunStatus,
          seedDetailInfo:val.seedDetailInfo,
          token_nft:val.token_nft,
          marketItemDetail:val.marketItemDetail,
          pureEffectValueJson:val.pureEffectValueJson
        }
      }
    }),
    createPersistedState({
      storage:window.localStorage,
      reducer(val){
        return {
          actionPersistenceStatus:val.actionPersistenceStatus,
          // appRunStatus:val.appRunStatus,
          appBaseOption:val.appBaseOption,
          playerInfo: val.playerInfo,
          token_farmt:val.token_farmt,
          token_usdt:val.token_usdt,
          token_pol: val.token_pol,
          storage: val.storage,
          queuesAll:val.queuesAll,
          errorCollector:val.errorCollector
        }
      },
    }),
  ]
})
