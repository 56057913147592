// 不同的合约分别需要干什么事情.都列举一下.
import vueStore from '@/store/index'
import {Center} from '@/chainField/chainCenter';
import _ from 'lodash';
import {AssembleErr, ErrorTypes,BaseContractSetting} from "@/chainField/contractApi/NamingSummary";
import {showToast} from "vant";
function formatDateTime(date) {
    function padZero(num) {
        return num<10?'0'+num:num;
    }
    const year = date.getFullYear();
    const month = padZero(date.getMonth()+1);
    const day = padZero(date.getDate());
    const our = padZero(date.getHours());
    const minute = padZero(date.getMinutes());
    const second = padZero(date.getSeconds());
    return year+''+month+''+day+''+our+''+minute+''+second;
}
function formatDateTimeNm(date) {
    function padZero(num) {
        return num<10?'0'+num:num;
    }
    const year = date.getFullYear();
    const month = padZero(date.getMonth()+1);
    const day = padZero(date.getDate());
    const our = padZero(date.getHours());
    const minute = padZero(date.getMinutes());
    const second = padZero(date.getSeconds());
    const getMilliseconds = padZero(date.getMilliseconds());
    return year+''+month+''+day+''+our+''+minute+''+second+getMilliseconds;
}
const farm={
    t1:'farm要授权给nftItemExchange.这东西拿到你的授权后.才可以拿你的farm去换不同level的盒子',
    t2:'farm要授权给拍卖行.这样你才可以用farm拿下那些道具',
    t3:'farm还要授权给uniswapRouter.这样才能兑换啊',
    t4:'要能够转账'
}

const farm2={
    e_toApprove:{zh:'农场币授权给NFT兑换合约',en:'Farm Approve to contract[NftExchange]'}
    // 需要hash.保留的参数.
}

const goldnft={
    t1:'runUserBatchSafeMint 这个也就是铸造成金币nft.用游戏币来的.还可以指定大小数量',
    t2:'runBurnNftByTokenId这个呢..就是手头有金币nft的情况下变成游戏币',
    t3:'toApproveToMarket.金币nft被授权给market'
}

const market={
    t1:'EquipmentRunListingNFT装备丢拍卖行',
    t2:'GoldenRunListingNFT 金币nft丢拍卖行',
    t3:'cancelMyEquipment 这个下架.金币和装备的一个方法',
    t4:'BuyEquipmentAndGolden 购买装备.当然要先授权的'
}

const Pol={
    t1:'transferPol 就这一个.转主币的'
}

const nft={
    t1:'RunOpenBindBox实际打开盲盒,三种级别的',
    t2:'RunPayment 这个是开盲盒之前要用usdt激活',
    t3:'toApproveTokenId 盲盒授权.准备回收的准备.,也可以授权给market.拍卖行的准备.授权给nftItemExchange,market都行'
}

// const nftItemExchange={
//     t1:'runFarmToNFT 这个是farm换nft.也就是farm买盲盒的意思',
//     t2:'.runNFtToFarm 盲盒和装备.金币nft的回收'
// }



const payment={
    t1:'runBuyByHash 这个呢.是扩展土地一类的.也就是游戏服务类.'
}
const uniswapv2={
    t1:'swap swapExactTokensForTokens .好像就farm和usdt的交换'
}
const usdt={
    t1:'toApprove授权给.level1,level2,level3,uniswapV2Router,payment 这几个',
    t2:'transfer usdt本身的转账'
}


const commonEvent={
    data:'', //popu产出的data
    isTransferEth:'',//是不是转标准币的
}



const EventTag={
    e_购买盲盒:{contract:'queueNftItemExchange',abiName:'FarmToNFT',zname:'购买盲盒',ename:'buyBlindBox',isFinalStep:true},
    e_Farm币授权:{contract:'queueFarmt',abiName:'approve',zname:'FARM币授权',ename:'farmApprove',isFinalStep:true},
    e_Usdt币授权:{contract:'queueUsdt',abiName:'approve',zname:'USDT币授权',ename:'usdtApprove',isFinalStep:true},
    e_NFT支付:{contract:'queueNft',abiName:'Payment',zname:'NFT支付服务',ename:'payfornft',isFinalStep:false},
    e_NFT钥匙支付:{contract:'queueNft',abiName:'PaymentKey',zname:'NFT钥匙支付服务',ename:'keyUnlock',isFinalStep:false},
    e_开启盲盒:{contract:'queueNft',abiName:'OpeBlindBox',zname:'开启盲盒',ename:'openBox',isFinalStep:true},
    e_NFT道具授权:{contract:'queueNft',abiName:'approve',zname:'NFT道具授权',ename:'nftApprove',isFinalStep:false}, // 盲盒回收之前.或者上架之前应该有这个.甚至金币.
    e_Farm和Usdt交换:{contract:'queueUniswapv2',abiName:'router.swapExactTokensForTokens',zname:'兑换',ename:'swap',isFinalStep:true},
    e_Farm币发送:{contract:'queueFarmt',abiName:'transfer',zname:'FARM币发送',ename:'farm transfer',isFinalStep:true},
    e_Usdt币发送:{contract:'queueUsdt',abiName:'transfer',zname:'USDT发送',ename:'usdt transfer',isFinalStep:true},
    e_Pol币发送:{contract:'queuePol',abiName:'transfer',zname:'Pol发送',ename:'pol transfer',isFinalStep:true},
    e_NFT道具回收:{contract:'queueNftItemExchange',abiName:'NFTToFarm',zname:'NFT道具回收',ename:'nftToFarm',isFinalStep:true},
    e_NFT装备上架市场:{contract:'queueMarket',abiName:'ListingNFT',zname:'NFT装备上架市场',ename:'nftToMarket',isFinalStep:true},
    e_NFT装备取消上架:{contract:'queueMarket',abiName:'OffShelfNFT',zname:'NFT装备取消上架',ename:'nftCancelSell',isFinalStep:true},
    e_拍卖行购买装备:{contract:'queueMarket',abiName:'Place',zname:'拍卖行购买装备',ename:'buyInMarket',isFinalStep:true},
    e_钥匙铸造:{contract:'queueBoxKey',abiName:'SigMint',zname:'购买钥匙',ename:'buyKey',isFinalStep:true},
    e_创建金币求购:{contract:'queueGoldConsignment',abiName:'createUserPurchase',zname:'发起求购金币nft',ename:'sendGoldPurchase',isFinalStep:true},
    e_取消金币求购:{contract:'queueGoldConsignment',abiName:'OffShelf',zname:'取消金币求购',ename:'cancelGoldPurchase',isFinalStep:true},
    e_金币铸造:{contract:'queueGoldenNft',abiName:'UserBatchSafeMint',zname:'金币生成',ename:'createGoldNft',isFinalStep:true},
    e_金币使用:{contract:'queueGoldenNft',abiName:'burn',zname:'金币使用',ename:'burnGoldNft',isFinalStep:true},
    e_金币授权给求购单:{contract:'queueGoldenNft',abiName:'approve_toconsi',zname:'金币授权给求购',ename:'goldNftApprove',isFinalStep:false},
    e_金币nft卖给单子:{contract:'queueGoldConsignment',abiName:'shellGold',zname:'金币nft卖给buyer',ename:'goldNftSell',isFinalStep:true},
    e_U购买服务:{contract:'queuePayment',abiName:'Buy',zname:'购买服务',ename:'buyService',isFinalStep:true},
}
//
const SourceTag={
    in_NFT道具授权:{
        s_装备回收授权:{ename:'approveForEquipmentRoll',zname:'装备回收授权'},
        s_盲盒回收授权:{ename:'approveForBoxRoll',zname:'盲盒回收授权'},
        s_装备上架授权:{ename:'approveForEquipSell',zname:'装备上架前授权'},
    },
    in_NFT道具回收:{
        s_装备回收:{ename:'EquipmentRoll',zname:'装备回收'},
        s_盲盒回收:{ename:'BoxRoll',zname:'盲盒回收'},
    },
    in_U购买服务:{
        s_购买VIP服务:{ename:'BuyVip',zname:'购买VIP'},
        s_土地升级:{ename:'LandLvUp',zname:'土地升级'},
        s_土地扩展:{ename:'LandExpand',zname:'土地扩展'},
    }
}; //更细分的..因为上面的操作中.比如盲盒回收授权和装备回收授权是同一个入口.不太好判断.


function MarkQueueIsReplace({queueGroup,replacedQueueNo,replaceByQueueNo}){
    const index = _.findIndex(vueStore.state.queuesAll[queueGroup],{queueNo:replacedQueueNo});
    if(index>-1){
        vueStore.commit('QueueMarkBeReplace',{queueGroup:queueGroup,index,replacedQueueNo,replaceByQueueNo});
    }else{
        // 未匹配
    }

}
const PolyEventManager={};
function randomEnd(length=3) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomString = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
    }
    return '_ed_'+randomString;
}
PolyEventManager.Add=function ({event,eventOpt,source,data,callArgs=null,txOptions=null}) {
    console.log('方法调用时的参数',callArgs);
    console.log('传入的交易设置',txOptions);
    if(callArgs){
        callArgs=callArgs[0];
        delete callArgs['txOptions'];

        if(callArgs.retryTxOptions){
            if(callArgs.retryTxOptions.gasPrice){
                callArgs.retryTxOptions.gasPrice =callArgs.retryTxOptions.gasPrice+'';
            }
            if(callArgs.retryTxOptions.biggerGasPrice){
                callArgs.retryTxOptions.biggerGasPrice =callArgs.retryTxOptions.biggerGasPrice+'';
            }

        }
    }
    if(txOptions){
        if(txOptions.gasPrice){
            txOptions.gasPrice =txOptions.gasPrice+'';
        }
        if(txOptions.gasLimit){
            txOptions.gasLimit=txOptions.gasLimit+'';
        }
    }
    let timeString=formatDateTimeNm(new Date());
    const newEvent = {
        callArgs:callArgs, // 调用方法时准备的参数
        txOptions:txOptions,  // 比如gas啦.gasLimit. nce啦.
        txResponseInfo:{},
        receiptInfo:{},
        abiName:event.abiName,
        eventOpt:{},
        source:{},
        queueNo:'',
        status:'new',
        data:data,
        addTime:new Date(),
        hash:'',
        success:false,
        iswrong:false,
        zname:event.zname,
        ename:event.ename,
        currentBlockNumber:vueStore.state.blockNumber,
        isReplaceTx:false, // 默认当然不是替换的交易了
        beReplaced:false,
        replacedQueueNo:'',// 曾经的Queueno,当前是覆盖它的
        replaceByQueueNo:'',
        retryQueueNoArr:[], // 在此基础上的重试可能会有一堆.
        isFinalStep:event.isFinalStep,
        receiptRetryArr:[], // 重试数据
    };
    if(callArgs&&callArgs.retryTxOptions){
        // 如果有传入重试参数说明本次操作是替换掉前面的.所以要写明,是哪个被替换了.
        newEvent.isReplaceTx=true;
        newEvent.replacedQueueNo = callArgs.retryTxOptions.replacedQueueNo;
    }


    newEvent.data=SimpleEllipsis(newEvent.data,10);
    newEvent.queueNo=event.contract+'_'+timeString;
    newEvent.eventOpt=eventOpt;
    newEvent.source=source;
    if(newEvent.isReplaceTx){
        MarkQueueIsReplace({queueGroup:event.contract,replacedQueueNo:newEvent.replacedQueueNo,replaceByQueueNo:newEvent.queueNo});
    }
    console.log('这次加入的',newEvent);
    vueStore.commit('addToQueue',{queueGroup:event.contract,data:newEvent});
    return newEvent.queueNo;
};

PolyEventManager.AddFilltxOpt=function({event,queueNo,data,txOptions=null}){
    let matchIndex=-1;
    const index = _.findIndex(vueStore.state.queuesAll[event.contract],{queueNo:queueNo});
    if(txOptions){
        txOptions.gasPrice =txOptions.gasPrice+'';
        if(txOptions.gasLimit){
            txOptions.gasLimit=txOptions.gasLimit+'';
        }
    }
    if(index>-1){
        vueStore.commit('AddTxOptions',{queueGroup:event.contract,index,status:'new',data:data,txOptions});
    }
    return {matchIndex};
}
PolyEventManager.Update=function ({event,queueNo,txResponse}) {
    let matchIndex=-1;
    const index = _.findIndex(vueStore.state.queuesAll[event.contract],{queueNo:queueNo});
    if(index>-1){
        const changeBody ={queueGroup:event.contract,index,status:'txResponse'};
        if(txResponse){
            changeBody.txResponseInfo={
                hash:txResponse.hash,
                nonce:txResponse.nonce
            }
        }
        vueStore.commit('updateToQueue',changeBody);
        // queueNftItemExchange[index].status = 'txResponse';
        // queueNftItemExchange[index].txResponseTime = new Date();
        // queueNftItemExchange[index].from = txResponse.from;
        // queueNftItemExchange[index].to = txResponse.to;
        // queueNftItemExchange[index].hash = txResponse.hash;
    }else{
        console.log('没有匹配上line131',queueNo);
    }
    return {matchIndex};
}

PolyEventManager.End=function({event,queueNo,success=true,txReceipt}) {
    const index = _.findIndex(vueStore.state.queuesAll[event.contract],{queueNo:queueNo});
    if(index>-1){
        const changeInfo={
            queueGroup:event.contract,index,status:'receipt',success:success
        };
        if(txReceipt){
            changeInfo.receipt={
                blockNumber:txReceipt.blockNumber,
                blockHash:txReceipt.blockHash,
                status:txReceipt.status,
                hash:txReceipt.hash
            };
            vueStore.commit('setBlockNumber',txReceipt.blockNumber);
        }
        vueStore.commit('endNormalToQueue',changeInfo);
    }else{
        console.log('没有匹配上line147',queueNo);
    }
    return queueNo;
}

PolyEventManager.HashNotFound=function({event_name,queueNo,retryTag}){
    const index = _.findIndex(vueStore.state.queuesAll[event_name],{queueNo:queueNo});
    vueStore.commit('endInCheckToQueue',{
        queueGroup:event_name,index,status:'receipt',retryTag,hashFound:false
    });
};
PolyEventManager.EndInCheckTx=function({event_name,queueNo,success=true,txReceipt,retryTag}) {
    const index = _.findIndex(vueStore.state.queuesAll[event_name],{queueNo:queueNo});
    if(index>-1){
        const changeInfo={
            queueGroup:event_name,index,status:'receipt',success:success,retryTag,hashFound:true
        };
        if(txReceipt){
            changeInfo.receipt={
                blockNumber:txReceipt.blockNumber,
                blockHash:txReceipt.blockHash,
                status:txReceipt.status,
                hash:txReceipt.hash
            };
            vueStore.commit('setBlockNumber',txReceipt.blockNumber);
        }
        vueStore.commit('endInCheckToQueue',changeInfo);
    }else{
        console.log('没有匹配上line147',queueNo);
    }
    return queueNo;
}

PolyEventManager.innerCheckTxCatchError=function({contractName,queueNo,error,hash}) {
    const index = _.findIndex(vueStore.state.queuesAll[contractName],{queueNo:queueNo});
    if(index>-1){
        vueStore.commit('errorToQueue',{queueGroup:contractName,index,err:error});
    }else{
    }
    // 只有个hash能进来.
    vueStore.commit('errorOnlySave',{queueGroup:contractName,index:index,err:error,txResponse:{hash:hash}});
};
PolyEventManager.Error=function({event,queueNo,error}) {
    const index = _.findIndex(vueStore.state.queuesAll[event.contract],{queueNo:queueNo});
    if(index>-1){
        vueStore.commit('errorToQueue',{queueGroup:event.contract,index,err:error});
    }
    vueStore.commit('errorOnlySave',{queueGroup:event.contract,index:index,err:error,txResponse:{}});
};

// 用这个错误都是在第二步回调那里的报错.分开写.干净.
PolyEventManager.receiptCatchError=function({event,queueNo,error,txResponse}) {
    const index = _.findIndex(vueStore.state.queuesAll[event.contract],{queueNo:queueNo});
    if(index>-1){
        vueStore.commit('errorToQueue',{queueGroup:event.contract,index,err:error});
    }
    vueStore.commit('errorOnlySave',{queueGroup:event.contract,index:index,err:error,txResponse:{hash:txResponse.hash}});
};
const matchNonceToolowErr='nonce has already been used';
PolyEventManager.txCatchError=async function({event,queueNo,error}) {
    console.log('【txCatchError】',error);
    const index = _.findIndex(vueStore.state.queuesAll[event.contract],{queueNo:queueNo});
    if(index>-1){
        console.log('txCatchError捕捉错误传入store');
        vueStore.commit('errorToQueue',{queueGroup:event.contract,index,err:error});
    }
    if(error.toString().indexOf(matchNonceToolowErr)>-1){
            // 应该要通知外层.去延迟更新nonce
        Center.DelayNonceRefreshAfterToolowError();
    }
    vueStore.commit('errorOnlySave',{queueGroup:event.contract,index:index,err:error});
    return AssembleErr({status:false, message:error},ErrorTypes.ethErr);
}

function SimpleEllipsis(text,length,suffix='...'){
    if(text){
        if(text.length<=length) return text;
        const begin = text.substr(0,length/2);
        const end = text.substr(-1*Math.ceil(length/2),length/2);
        return `${begin}${suffix}${end}`;
    }else{
        return '';
    }

}
// 额外增加独立事件,这个事件既然是扫块拿到的.起码是第二阶段了
PolyEventManager.AddTxInBlock=function ({event,data,blockNumber,hash,nonce,setEventOpt}) {
    console.log('AddTxInBlock',event,data,blockNumber,hash,nonce,setEventOpt);
    let timeString=formatDateTimeNm(new Date());
    const newEvent = {abiName:event.abiName,eventOpt:{},source:{},queueNo:'',status:'txResponse',data:data,addTime:new Date(),hash:hash,
        success:false,iswrong:false,zname:event.zname,currentBlockNumber:blockNumber,isExtraCheckEvent:true};
    newEvent.data=SimpleEllipsis(newEvent.data,10);
    if(setEventOpt){
        newEvent.spEventOpt=setEventOpt;
    }
   newEvent.queueNo=event.contract+'_'+timeString;
    newEvent.nonce =nonce;
    console.log('这个事件它能自检么',newEvent);
    vueStore.commit('addAloneQueue',{queueGroup:event.contract,addedEvent:newEvent});
    // checkTransaction(hash,{event_name:event.contract,queueNo:newEvent.queueNo});
};


// checktx里独立
async function checkTransaction(hash,{event_name,queueNo,retryTag}){
    if(!Center.provider){
        return {}
    }
    console.warn('run checkTransaction>>>',queueNo);
    // 这里因为没在用到同步方法的那个方式存储到store里去.我打算专门列个列表来存一下.
    // 获取交易信息
    const transaction = await Center.provider.getTransaction(hash);
    const uniqueTag = randomEnd(10);
    if (!transaction) {
        console.log("未在链上查询到此hash.");
        vueStore.commit('addTxUrgeWaitForReceipt',{hash:hash,queueNo:queueNo,uniqueTag:uniqueTag});
        PolyEventManager.HashNotFound({event_name,queueNo,retryTag:retryTag});
        vueStore.commit('finishTxUrgeWaitForReceipt',{hash:hash,receipt_status:-2,uniqueTag:uniqueTag});
        return {receiptStatus:'txNotExist',hash:hash,txExist:false};
    }
    // 如果交易还没有被打包进区块，等待确认
    if (!transaction.blockNumber) {
        console.log("交易还在等待确认...");
        // const showData={hash:hash,promise:Center.provider.waitForTransaction(hash),queueNo:queueNo};
        vueStore.commit('addTxUrgeWaitForReceipt',{hash:hash,queueNo:queueNo,uniqueTag:uniqueTag});
        Center.provider.waitForTransaction(hash,1,BaseContractSetting.txWaitTimeout).then(receipt=>{
            vueStore.commit('finishTxUrgeWaitForReceipt',{hash:hash,receipt_status:receipt.status,uniqueTag:uniqueTag});
            if (receipt.status === 1) {
                console.log("交易成功！");
                PolyEventManager.EndInCheckTx({event_name,queueNo,txReceipt:receipt,retryTag:retryTag});
            } else {
                console.log("交易失败（回滚1）");
                PolyEventManager.EndInCheckTx({event_name,queueNo,success:false,txReceipt:receipt,retryTag:retryTag});
            }
        }).catch(e=>{
            PolyEventManager.innerCheckTxCatchError({contractName:event_name,queueNo,error:e,hash:hash,retryTag:retryTag});
        });
        return {receiptStatus:'waiting',hash:hash,txExist:true};
    } else {
        vueStore.commit('addTxUrgeWaitForReceipt',{hash:hash,queueNo:queueNo,uniqueTag:uniqueTag});
        console.log('有blockNumber获取receipt');
        // 如果交易已确认，获取交易收据判断成功与否
        const receipt = await Center.provider.getTransactionReceipt(hash);
        // 这一步应该是耗时的.
        console.log('现有receipt',receipt);
        if (receipt && receipt.status === 1) {
            console.log("交易成功！");
            PolyEventManager.EndInCheckTx({event_name,queueNo,txReceipt:receipt,retryTag:retryTag});
            vueStore.commit('finishTxUrgeWaitForReceipt',{hash:hash,receipt_status:receipt.status,uniqueTag:uniqueTag});
            return {receiptStatus:'success',hash:hash,txExist:true};
        } else if (receipt && receipt.status === 0) {
            console.log("交易失败（回滚2）");
            PolyEventManager.EndInCheckTx({event_name,queueNo,success:false,txReceipt:receipt,retryTag:retryTag});
            vueStore.commit('finishTxUrgeWaitForReceipt',{hash:hash,receipt_status:receipt.status,uniqueTag:uniqueTag});
            return {receiptStatus:'fail',hash:hash,txExist:true};
        }else{
            return {receiptStatus:'unkown',hash:hash,txExist:true};
        }

    }

}


PolyEventManager.urgeCheck=async function(queueNo) {
    if(!Center.provider){
        return {status:false, message:'provider not init',txExist:false}
    }
    console.log('manager run urgeCheck:'+queueNo);
    if(!queueNo){
        return {status:false, message:'queueNo缺失',txExist:false}
    }
    // 只需要queueNo.
    const queueContractGroup= queueNo.split('_')[0];
    const targetIndex = _.findIndex(vueStore.state.queuesAll[queueContractGroup],{queueNo:queueNo});
    if(targetIndex==-1){
        console.log('未匹配到数据');
        return {status:false, message:'未匹配到数据',txExist:false}
    }
    if(vueStore.state.queuesAll[queueContractGroup][targetIndex].txResponseInfo){
        // 当然只有跑完第一步的才能去尝试了.那这里要改一下尝试标记了.
        const hash = vueStore.state.queuesAll[queueContractGroup][targetIndex].txResponseInfo.hash;
        if(hash&&hash!==''&&hash.length>0){
            const retryTag=randomEnd(10);
            // 应该这里不需要hash的..根据queue里去找就可以了
            vueStore.commit('receiptErrQueueStartTry',{queueGroup:queueContractGroup,hash:hash,index:targetIndex,retryTag:retryTag});
            console.log('执行checkTx去');
            return checkTransaction(hash,{event_name:queueContractGroup,queueNo,retryTag:retryTag});
            // return {status:true, message:'please wait'}
        }else{
            console.log('不存在hash');
            return {status:false, message:'hash缺失',txExist:false}
        }
    }else{
        return {status:false, message:'第一步信息缺失',txExist:false}
    }
//有group有no的情况下自检行为.如果其有hash的话.再去询问一遍.
};


// 手动设置其超时错误.给了个差不多的错误提示.
PolyEventManager.manualSetWaitTxTimeoutError=async function(queueNo) {
    if(!queueNo){
        return {status:false}
    }
    const queueContractGroup= queueNo.split('_')[0];
    const targetIndex = _.findIndex(vueStore.state.queuesAll[queueContractGroup],{queueNo:queueNo});
    if(targetIndex==-1){
        return {status:false}
    }
    if(vueStore.state.queuesAll[queueContractGroup][targetIndex]){
        // 当然只有跑完第一步的才能去尝试了.那这里要改一下尝试标记了.
        const hash = vueStore.state.queuesAll[queueContractGroup][targetIndex].txResponseInfo.hash;
        vueStore.commit('storeSetWaitTxTimeout',{queueGroup:queueContractGroup,hash:hash,index:targetIndex});
        return {status:true}
    }else{
        return {status:false}
    }
};

PolyEventManager.clearTxReponseError=async function(queueNo) {
    if(!queueNo){
        return {status:false}
    }
    const queueContractGroup= queueNo.split('_')[0];
    const targetIndex = _.findIndex(vueStore.state.queuesAll[queueContractGroup],{queueNo:queueNo});
    if(targetIndex==-1){
        return {status:false}
    }
    if(vueStore.state.queuesAll[queueContractGroup][targetIndex]){
        // 当然只有跑完第一步的才能去尝试了.那这里要改一下尝试标记了.
        const hash = vueStore.state.queuesAll[queueContractGroup][targetIndex].txResponseInfo.hash;
        vueStore.commit('ClearErrAtTxResponse',{queueGroup:queueContractGroup,hash:hash,index:targetIndex});
        return {status:true}
    }else{
        return {status:false}
    }
//有group有no的情况下自检行为.如果其有hash的话.再去询问一遍.
};
// ClearErrAtTxResponse
PolyEventManager.removeEvent=async function(info){
    const {queueNo} = info;
    const queueContractGroup= queueNo.split('_')[0];
    console.log('remove_group:',queueContractGroup);
    const removeindex = _.findIndex(vueStore.state.queuesAll[queueContractGroup],{queueNo:queueNo});
    if(removeindex>-1){
        vueStore.commit('removeInQueue',{queueGroup:queueContractGroup,index:removeindex});
        return {status:true};
    }else{
        return {status:false,message:'找不到这条记录了'};
    }

};

PolyEventManager.existCheck=function({event,eventOpt,source,ignoreEventOpt=false,type='status'}){
    // 这里的传入条件是没有queue的.
    let list=[];
    if(ignoreEventOpt){
        // 忽视掉eventOpt的筛选
        list = _.filter(vueStore.state.queuesAll[event.contract],{abiName:event.abiName});
    }else{
        // console.log('条件',eventOpt);
        // console.log('从哪里匹配',vueStore.state.queuesAll[event.contract]);
        list = _.filter(vueStore.state.queuesAll[event.contract],o=>(o.abiName==event.abiName)&&_.isMatch(o.eventOpt,eventOpt));

    }
    if(source){
        list =_.filter(list,o=>_.isEqual(o.source,source));
    }
    if(list.length==0){
        return type=='list'?{status:'free',list:[]}:'free';
    }
    const wait_tx_arr=[];
    const wait_receipt_arr=[];
    for(let i=0;i<list.length;i++){
        if(list[i].status=='new'&&list[i].iswrong==false){
            // 时间应该也加上..
            // 通过queueNo.这个东西当前是否在执行中.
            // wait_tx_arr.push({updateTime:list[i].updateTime,queueNo:list[i].queueNo});
            wait_tx_arr.push(list[i].queueNo);
        }
        if(list[i].status=='txResponse'&&list[i].iswrong==false){
            // wait_receipt_arr.push({updateTime:list[i].updateTime,queueNo:list[i].queueNo});
            wait_receipt_arr.push(list[i].queueNo);
        }
    }
    if(wait_tx_arr.length>0){
        return type=='list'?{status:'exist_wait_tx',list:wait_tx_arr}:'exist_wait_tx';
    }
    if(wait_receipt_arr.length>0){
        return type=='list'?{status:'exist_wait_receipt',list:wait_receipt_arr}:'exist_wait_receipt';
    }
    return type=='list'?{status:'free',list:[]}:'free';
}


// 一种新的检测
PolyEventManager.latestEvent=function({event,eventOpt,source,ignoreEventOpt=false,type='status'}){
    // 这里的传入条件是没有queue的.
    let list=[];
    if(ignoreEventOpt){
        // 忽视掉eventOpt的筛选
        list = _.filter(vueStore.state.queuesAll[event.contract],{abiName:event.abiName});
    }else{
        // console.log('条件',eventOpt);
        // console.log('从哪里匹配',vueStore.state.queuesAll[event.contract]);
        list = _.filter(vueStore.state.queuesAll[event.contract],o=>(o.abiName==event.abiName)&&_.isMatch(o.eventOpt,eventOpt));

    }
    if(source){
        list =_.filter(list,o=>_.isEqual(o.source,source));
    }
    if(list.length==0){
        return 'free';
    }
    const endItem = list[list.length-1];
    // const endObj={
    //     iswrong:endItem.iswrong,
    //     queueNo:endItem.queueNo,
    //     newTime:endItem.newTime,
    //     txTime:endItem.txTime,
    //     status:endItem.status,
    //     success:endItem.success
    // };
    // 如果完结了肯定给过呀.
    if(endItem.status=='receipt'){
        return 'free';
    }
    else if(endItem.status=='txResponse'){
        const new_mills = endItem.txTime?new Date(endItem.txTime).getTime():0;
        const passSecondsFromTx = (vueStore.state.currentTimeStamp-new_mills)/1000;
        const runTimePass=vueStore.state.newRunTime; // 这个是超时时间啊.
        if(passSecondsFromTx>runTimePass){
            return 'free';
        }else{
            return 'timeFromTx:'+passSecondsFromTx
        }
    }else{
        const new_mills = endItem.newTime?new Date(endItem.newTime).getTime():0;
        const passSecondsFromNew = (vueStore.state.currentTimeStamp-new_mills)/1000;
        const runTimePass=vueStore.state.newRunTime; // 这个是超时时间啊.
        if(passSecondsFromNew>runTimePass){
            return 'free';
        }else{
            return 'timeFromNew:'+passSecondsFromNew
        }
    }



};
// 在成功提交之前是没有hash的.但是那个操作..也需要await..
// 准备一个数组.去存储.等待中的.hash.
const receiptWaitArray={}; // 这个是以hash为组的.
function syncWaitForReceiptByHash({hash,promise,queueNo}){
    const now = new Date().getTime();
    if(receiptWaitArray[hash]){
        // 弄个超时时间进去吧..
        // 别添加了
    }else{
        vueStore.commit('addCurEventWaitForReceipt',{queueNo:queueNo,hash:hash,time:now})
        receiptWaitArray[hash]={promise:promise,queueNo:queueNo,time:now};
    }
    // receiptWaitArray[hash].promise.then(res=>{
    //    console.log('syncWait里的promsie也可以then',res);
    // });
    return receiptWaitArray[hash].promise;
}


// 清理掉处理中的事件
function ClearWaitForReceiptByHash(hash){
    if(receiptWaitArray[hash]){
        vueStore.commit('removeCurEventWaitForReceipt',{hash:hash});
        delete receiptWaitArray[hash];
    }
}

// 这个是在内存里的.不在store里.不能随时变化数据.
const findIfQueueInWaitingReceipt=function(queueNo) {
    if(!queueNo){
        return false;
    }
    // 只需要queueNo.
    for(let key in receiptWaitArray){
        if(receiptWaitArray[key].queueNo==queueNo){
            return true;
        }
    }
    return false;

};

PolyEventManager.viewEventByQueueNo=async function(queueNo) {
    if(!queueNo){
        return 'queueNo is empty';
    }
    // 只需要queueNo.
    const queueContractGroup= queueNo.split('_')[0];
    console.log('view_group:',queueContractGroup);
    const targetIndex = _.findIndex(vueStore.state.queuesAll[queueContractGroup],{queueNo:queueNo});
    const hash = vueStore.state.queuesAll[queueContractGroup][targetIndex].hash;

    const data = {
        queueNo:queueNo,
        eventDetail:vueStore.state.queuesAll[queueContractGroup][targetIndex],
        isWaitingReceipt:findIfQueueInWaitingReceipt(queueNo)
    }
//有group有no的情况下自检行为.如果其有hash的话.再去询问一遍.
};
// 应该以hash.也就是


export{EventTag,PolyEventManager,syncWaitForReceiptByHash,ClearWaitForReceiptByHash,SourceTag}

// 这个文件里有对Center的引用.这样不太好.不过center里没有引用当前文件,ok!