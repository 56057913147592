export const saveMap={
    auth_header_address:'auth_header_address',
    auth_header_token:'auth_header_token',
    player_my_id:'player_my_id',
    activeWalletAddress:'activeWalletAddress', // 这个好像没用啊.实际没用到哦.
    encryptedWallet:'encryptedWallet', //加密过的信息


    randomencodewt:'randomencodewt', //
    randompd:'randompd', //
    user_key_load_type:'user_key_load_type',
    remind_save_key:'remind_save_key' , //是否要提醒用户保存
    refCode:'refCode', //邀请码
};