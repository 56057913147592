const EventEnum={
    toGetNotify:'toGetNotify',   // 不要一开始就去拿游戏公告.
    ProviderInit:'ProviderInit',
    AskForPwdAuth:'AskForPwdAuth',
    walletConnect:'walletConenct',
    walletInit:'walletInit',
    signerInit:'signerInit',
    usdtFinishApprove:'usdtFinishApprove', //成功授予usdt后
    farmtFinishApprove:'farmtFinishApprove',
    farmtFinishTransfer:'farmtFinishTransfer',
    usdtFinishTransfer:'usdtFinishTransfer',// 美元发送完毕
    polFinishTransfer:'polFinishTransfer',// 主代币发送
    coinFinishApprove:'coinFinishApprove',
    usdtPayToBox:'usdtPayToBox',
    keyPayToBox:'keyPayToBox', //这是钥匙拿来解锁了
    finishOpenBox:'finishOpenBox',
    nftItemApproved:'nftItmApproved',
    nftItemHuishou:'nftItemHuishou',//道具.比如盲盒.回收了.
    goldNftApproved:'goldNftApproved', // 金币nft授权出去了
    farmtAndUsdtv2Swap:'farmtAndUsdtv2Swap',//道具.比如盲盒.回收了.
    boxKeyFinishCreate:'boxKeyFinishCreate', //钥匙铸造完成咯
    AddGoldNftOrder:'AddGoldNftOrder', //创建金币求购单
    CancelGoldNftOrder:'CancelGoldNftOrder', //取消金币求购单
    equipmentPushToMarket:'equipmentPushToMarket', //装备丢到拍卖行了
    equipmentSellCancel:'equipmentSellCancel', //装备从拍卖行撤回去了
    equipmentBuy:'equipmentBuy', //装备从拍卖行撤回去了
    goldNftToGameCoin:'goldNftToGameCoin', //金币nft变成游戏币了
    goldNftPutToConsinment:'goldNftPutToConsinment', //金币nft丢到单子里了
    paymentForService:'paymentForService', //用u购买了服务
    newGoldNftCreated:'newGoldNftCreated',  //有新金币被铸造出来了
    submitCommonCD:'submitCommonCD',
    buyNewBlindBox:'buyNewBlindBox'
};
const EventWindowEnum={
    loginStart:'loginStart',
    loginEnd:'loginEnd',
    Message:'Message',
}
export{
    EventEnum,EventWindowEnum
}