 const TokenNameEnum={
    farm:'farm',
    usdt:'usdt'
}


 function getUnit(tokenType) {
    let unit = 0;
    switch (tokenType) {
        case TokenNameEnum.farm:
            unit= 18;
            break;
        case TokenNameEnum.usdt:
            unit= 6;
            break;
        default:
            unit=0;
            break;
    }
    return unit;
}

export {TokenNameEnum,getUnit};