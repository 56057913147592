// @ is an alias to /src

export default {
  components: {},
  props: {
    styleArr1: {
      type: Array,
      default: []
    }
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('popclose', {});
    }
  }
};