export const seedDictList = [
  {id: 1,  chinese_name: "生物幻影藤", english_name: "Phantom Vine"},
  {id: 2,  chinese_name: "生物冰晶草", english_name: "Ice Grass"},
  {id: 3,  chinese_name: "生物宇宙藤", english_name: "Cosmic Vine"},
  {id: 4,  chinese_name: "生物蒼穹藤", english_name: "Firmament Vin"},
  {id: 5,  chinese_name: "生物星座藤", english_name: "Constellation Vine"},
  {id: 6,  chinese_name: "生物星際藤", english_name: "Interstellar Vine"},
  {id: 7,  chinese_name: "數字草莓花", english_name: "Strawberry Flower"},
  {id: 8,  chinese_name: "數字藍玫瑰", english_name: "Blue Rose"},
  {id: 9,  chinese_name: "數字鑽石花", english_name: "Diamond Flower"},
  {id: 10,  chinese_name: "數位晶體草", english_name: "Crystal Grass"},
  {id: 11,  chinese_name: "數字曙光花", english_name: "Dawn Flower"},
  {id: 12,  chinese_name: "數字碧海蓮", english_name: "Sea Lotus"},
  {id: 13,  chinese_name: "數字幽蘭花", english_name: "Dark Orchid"},
  {id: 14,  chinese_name: "數字夜色花", english_name: "Nightshade Flower"},
  {id: 15,  chinese_name: "數據之心草", english_name: "Heart Grass"},
  {id: 16,  chinese_name: "數據金銀花", english_name: "Honeysuckle Flower"},
  {id: 17,  chinese_name: "數據碧空花", english_name: "Azure Flower"},
  {id: 18,  chinese_name: "數據流星樹", english_name: "Meteor Tree"},
  {id: 19,  chinese_name: "數據藍天草", english_name: "Sky Grass"},
  {id: 20,  chinese_name: "科技磁力草", english_name: "Magnetic Grass"},
  {id: 21,  chinese_name: "科技火星藤", english_name: "Mars Vine"},
  {id: 22,  chinese_name: "科技銀河樹", english_name: "Milky Way Tree"},
  {id: 23,  chinese_name: "科技星芒樹", english_name: "Starbeam Tree"},
  {id: 24,  chinese_name: "科技星河花", english_name: "Galaxy Flower"},
  {id: 25,  chinese_name: "科技恒星花", english_name: "Stellar Flower"},
  {id: 26,  chinese_name: "虛擬彩虹草", english_name: "Rainbow Grass"},
  {id: 27,  chinese_name: "虛擬仙人掌", english_name: "Cactus"},
  {id: 28,  chinese_name: "虛擬幻彩花", english_name: "Fantasy Flower"},
  {id: 29,  chinese_name: "虛擬曼陀羅", english_name: "Mandrake"},
  {id: 30,  chinese_name: "虛擬幻影草", english_name: "Phantom Grass"},
  {id: 31,  chinese_name: "虛擬夢幻樹", english_name: "Dream Tree"},
  {id: 32,  chinese_name: "虛擬神秘草", english_name: "Mystery Grass"},
  {id: 33,  chinese_name: "全息夜蘭花", english_name: "Night Orchid"},
  {id: 34,  chinese_name: "全息星塵樹", english_name: "Stardust Tree"},
  {id: 35,  chinese_name: "全息極光樹", english_name: "Aurora Tree"},
  {id: 36,  chinese_name: "全息星火蕨", english_name: "Starfire Fern"},
  {id: 37,  chinese_name: "全息雲霞樹", english_name: "Cloud Veil Tree"},
  {id: 38,  chinese_name: "全息霓虹樹", english_name: "Neon Tree"},
  {id: 39,  chinese_name: "量子霓虹苔", english_name: "Neon Moss"},
  {id: 40,  chinese_name: "量子震盪樹", english_name: "Oscillating Tree"},
  {id: 41,  chinese_name: "量子漣漪花", english_name: "Ripples Flower"},
  {id: 42,  chinese_name: "量子時空草", english_name: "Spacetime Grass"},
  {id: 43,  chinese_name: "量子時光蕨", english_name: "Chrono Fern"},
  {id: 44,  chinese_name: "量子星雲蕨", english_name: "Nebula Fern"},
  {id: 45,  chinese_name: "量子星際藤", english_name: "Interstellar Vine"},
  {id: 46,  chinese_name: "電子藍莓樹", english_name: "Blueberry Tree"},
  {id: 47,  chinese_name: "電子水晶蕨", english_name: "Crystal Fern"},
  {id: 48,  chinese_name: "電子冰雹花", english_name: "Hail Flower"},
  {id: 49,  chinese_name: "電子極光花", english_name: "Aurora Flower"},
  {id: 50,  chinese_name: "電子天空蕨", english_name: "Sky Fern"},
  {id: 51,  chinese_name: "電子星空草", english_name: "Starry Sky Grass"},
  {id: 52,  chinese_name: "賽博向日葵", english_name: "Cyber Sunflower"},
  {id: 53,  chinese_name: "賽博紫羅蘭", english_name: "Cyber Violet"},
  {id: 54,  chinese_name: "賽博藍雪花", english_name: "Cyber SnowFlower"},
  {id: 55,  chinese_name: "賽博閃電藤", english_name: "Lightning Vine"},
  {id: 56,  chinese_name: "賽博星際藤", english_name: "Cyber Vine"},
  {id: 57,  chinese_name: "賽博風暴花", english_name: "Storm Flower"},
  {id: 58,  chinese_name: "賽博流星花", english_name: "Star Flower"},
  {id: 59,  chinese_name: "賽博護衛藤", english_name: "Guardian Vine"},
  {id: 60,  chinese_name: "賽博光穀樹", english_name: "Valley Tree"},
]
