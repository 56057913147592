import "core-js/modules/es.array.push.js";
import { Center } from "./chainField/chainCenter";
import EventQueueSample from "./views/eventQueueSample";
import { setToastDefaultOptions, setDialogDefaultOptions } from "vant";
import DevelopUi from "./views/mainPages/developUi";
export default {
  components: {
    DevelopUi,
    EventQueueSample
  },
  data() {
    return {
      winHeight: 0,
      winCurHeight: 0,
      offset_x: 0,
      offset_y: 500,
      openTestPage: false
    };
  },
  created() {
    setToastDefaultOptions({
      className: "custom-toast",
      wordBreak: 'break-word'
    });
    setDialogDefaultOptions({
      className: "custom-dialog-toast"
    });
    this.$store.dispatch('StartClock');
  },
  watch: {},
  methods: {
    toPage(path) {
      this.$router.push(path);
    },
    setWinCurSize() {
      const _height = this.getWinHeight();
      this.winCurHeight = _height;
      const _width = this.getWinWidth();
      this.$store.commit('setWinCurSize', {
        height: _height,
        width: _width
      });
    },
    getWinWidth() {
      const {
        width
      } = document.body.getBoundingClientRect() || 0;
      const innerWidth = window.innerWidth || 0;
      return Math.max(width, innerWidth);
    },
    getWinHeight() {
      const {
        height
      } = document.body.getBoundingClientRect() || 0;
      const innerHeight = window.innerHeight || 0;
      return Math.max(height, innerHeight);
    }
  },
  computed: {
    showBubble() {
      if (this.$route.path.indexOf('/login') > -1) {
        return false;
      } else if (this.$route.path !== '/') {
        let debugBubbleShow = this.$store.state.actionPersistenceStatus.debugBubble.show;
        return debugBubbleShow;
      } else {
        return false;
      }
    },
    pageHeight() {
      this.$store.state.winHeight;
      this.$store.state.winCurHeight;
      let targetHeight = 0;
      if (this.winCurHeight > this.winHeight) {
        targetHeight = this.winCurHeight;
      } else {
        targetHeight = this.winHeight;
      }
      return targetHeight > 0 ? targetHeight + 'px' : '100%';
    }
  },
  mounted() {
    this.$i18n.locale = this.app_lng == 'en' ? 'en' : 'zhTw';
    const height = this.getWinHeight();
    const width = this.getWinWidth();
    this.winHeight = height;
    this.$store.commit('setWinSize', {
      height: height,
      width: width
    });
    this.$store.commit('setWinCurSize', {
      height: height,
      width: width
    });
    window.addEventListener('resize', this.setWinCurSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.setWinCurSize);
  }
};