// 获取好友列表
import {GameRequest} from "@/util/axiosRequest/gameRequest";

export async function getFriendList({page=1,page_size=20}){
    return await GameRequest.post('api/auth/friend/friend_list',{page:page,page_size:page_size});
}

// 添加好友
export async function addFriend({friend_my_id}){
    return await GameRequest.post('api/auth/friend/add_friend', {friend_my_id});
}
// 删除好友
export async function deleteFriendById({friend_my_id}){
    return await GameRequest.post('api/auth/friend/del_friend', {friend_my_id});
}


// 这个刚加的又没用了.
export async function getMyInvitedFriends({page:page,page_size:page_size}){
    return await GameRequest.post('api/auth/friend/get_ref_list', {page:page,page_size:page_size});
}





