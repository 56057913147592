import SvgIcon from '@/components/SvgIcon.vue';
const componentsvgPlugin={
    install: function(vue,options){
        if(demandLoad(options)){
            const { imports } = options;
            imports.forEach((name) => {
                require(`@/assets/svg/${name}.svg`);
            });

        }else {
            const ctx = require.context("@/assets/svg", false, /\.svg$/);
            ctx.keys().forEach(path => {
                const temp = path.match(/\.\/([A-Za-z0-9\-_]+)\.svg$/);
                if (!temp) return;
                const name = temp[1];
                require(`@/assets/svg/${name}.svg`);
            });
        }
        vue.component('SvgIcon', SvgIcon);
    }
};

function demandLoad(options){
    if(options&&options.imports&&Array.isArray(options.imports)&&options.imports.length>0){
        return true;
    }else{
        return false;
    }
}



export default  componentsvgPlugin