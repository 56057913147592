import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["xlink:href", "fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    class: "svg-icon displayblock",
    style: _normalizeStyle({
      width: $props.size,
      height: $props.size,
      color: $props.color
    })
  }, [_createElementVNode("use", {
    "xlink:href": $options.fullIconName,
    fill: $props.color
  }, null, 8, _hoisted_1)], 4);
}