import {ethers} from "ethers";
import {EventManager,EventEnum} from "@/util/EventManager";
import {Center} from "@/chainField/chainCenter";
import {addressConfig} from "@/config";
import {ErrorTypes,AssembleErr,BaseContractSetting} from './NamingSummary';

import {
    ClearWaitForReceiptByHash,
    EventTag,
    PolyEventManager,
    syncWaitForReceiptByHash
} from "@/util/polyEventProgress";

const abis=[
    'function createUserPurchase(uint256 _goldType,uint256 _amount,uint256 _price) external', // 创建采购信息，参数为:金币类型, 数量 ,价格
    'function shellGold(address _buyer,uint256 _goldType,uint256 _tokenid) external', // 出售自己的金币给求购者 参数：求购者地址，金币类型，金币的tokenID
    'function OffShelf(uint256 _goldType) external',//取消求购订单.一个用户同一时间.一种类型的求购单只能发一条.
];
let _address=addressConfig.goldenConsignment;
const Main={
    contract:null,
    signerContract:null,
    _Iface:null,
};
Main.abis=abis;




// 用于金币nft的寄售
const init=function(){
        if(!Center.provider){
        return {status: false, message: 'provider need init!'}
    }
    Main.contract= new ethers.Contract(_address,abis,Center.provider);
    return {status: true, contract: Main.contract}
};

const contractAddSigner = function(){
    if(!Main.contract){
        const step0 = init();
        if(!step0.status){
            return step0;
        }
    }
    if(!Center.signer){
        return {status: false, message: 'center signer missed', showUserAuth:true}
    }
    Main.signerContract=Main.contract.connect(Center.signer);
    return {status: true, signerContract: Main.signerContract}
}

function ready(){
    if(!Main.contract){
        init();
    }
}


Main.AddGoldPurchase= async function({goldType=0,AmountInt,farmHra=5.2,retryTxOptions=null}){
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const farmSuaAmount = ethers.parseUnits(farmHra+'', addressConfig.farm.unit); //
        const popuInfo = await Main.signerContract.createUserPurchase.populateTransaction(goldType,AmountInt,farmSuaAmount);
        // const estimateGas = await Main.signerContract.createUserPurchase.estimateGas(goldType,AmountInt,farmSuaAmount);
        // gasOpt.gasLimit=Center.estimateGasMul110(estimateGas);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_创建金币求购,eventOpt:{goldType:goldType},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx = await Main.signerContract.createUserPurchase(goldType,AmountInt,farmSuaAmount,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_创建金币求购,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_创建金币求购,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        console.log('创建金币求购单tx',firstTx);
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log('创建金币求购单receipt',res);
            PolyEventManager.End({event:EventTag.e_创建金币求购,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.AddGoldNftOrder,{goldType:goldType});
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_创建金币求购,queueNo:queueNo,error:e,txResponse:firstTx});
        });;

        return {status:true}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_创建金币求购,queueNo:queueNo,error:e});
        if(e.toString().indexOf('transfer amount exceeds balance')>-1){
            return AssembleErr({status:false, message:'应该是授权量不够'},ErrorTypes.ethErr);
        }
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }


}


// 当前用户把自己的nft丢给Buyer
Main.shellGold= async function({buyerAddress,goldType,tokenId,source,retryTxOptions=null}){
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.shellGold.populateTransaction(buyerAddress,goldType,tokenId);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_金币nft卖给单子,source:source,eventOpt:{buyerAddress:buyerAddress,goldType:goldType,tokenId:tokenId},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx = await  Main.signerContract.shellGold(buyerAddress,goldType,tokenId,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_金币nft卖给单子,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_金币nft卖给单子,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("金币丢给求购",res);
            PolyEventManager.End({event:EventTag.e_金币nft卖给单子,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.goldNftPutToConsinment,{buyerAddress:buyerAddress,goldType:goldType,tokenId:tokenId,receiptInfo:{status:res.status}})
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_金币nft卖给单子,queueNo:queueNo,error:e,txResponse:firstTx});
            console.error('丢给求购 error',e);
        });
        return {status:true}
    }catch (e) {
        // if(e.toString().indexOf('transfer caller is not owner nor approved')>-1){
        //     console.log('对象发起人??');
        // }
        // if(e.toString().indexOf('transfer from incorrect owner')>-1){
        //     console.log('这个金币不是当前账号人拥有的');
        // }
        // if(e.toString().indexOf('goldType mismatching')>-1){
        //     console.log('金币类型不匹配哦');
        // }
        PolyEventManager.Error({event:EventTag.e_金币nft卖给单子,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }


}


Main.cancelByGoldType=async function({goldType,retryTxOptions=null}){
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.OffShelf.populateTransaction(goldType);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_取消金币求购,eventOpt:{goldType:goldType},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx = await Main.signerContract.OffShelf(goldType,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
            console.log('输出firstTGx',firstTx);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_取消金币求购,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        console.log('取消求购单TX',firstTx);
        PolyEventManager.Update({event:EventTag.e_取消金币求购,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log('取消求购单',res);
            PolyEventManager.End({event:EventTag.e_取消金币求购,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.CancelGoldNftOrder,{goldType:goldType,receiptInfo:{status:res.status}});
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_取消金币求购,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        return {status:true}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_取消金币求购,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }



}


Main.test= async function({}){
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }

    try{

        return {status:true, waitTx:{}, message:'内部等待wait'}
    }catch (e) {
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }


}


// 要做的是..钥匙的获取.钥匙的盲盒解锁. 金币的求购.取消.丢金币上去.

export {Main as ConsignmentSol}