export default {
  data() {
    return {
      visible: false,
      message: '',
      timeout: null
    };
  },
  methods: {
    show(message, duration = 3000) {
      this.message = message;
      this.visible = true;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.visible = false;
      }, duration);
    }
  },
  expose() {
    return {
      show: this.show
    };
  }
};