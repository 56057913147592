import _ from 'lodash';
import vueStore from "@/store";
const unMatch='unMatchDefaultErrMsgLikeThis';
const errMap={
    'default':[
        {keyword:unMatch,enMsg:"default fail",zhMsg:'默认失敗'}
    ],
    'Farmt.toApprove':[
        {keyword:unMatch,enMsg:"approve fail",zhMsg:'授權失敗'}
    ],
    'Farmt.transfer':[
        {keyword:unMatch,enMsg:"transfer fail",zhMsg:'轉賬失敗'}
    ],
    'Usdt.toApprove':[
        {keyword:unMatch,enMsg:"approve fail",zhMsg:'授權失敗'}
    ],
    'Usdt.transfer':[
        {keyword:unMatch,enMsg:"transfer fail",zhMsg:'轉賬失敗'}
    ],
    'MainPol.transferPol':[
        {keyword:unMatch,enMsg:"transfer fail",zhMsg:'轉賬失敗'}
    ],
    'BoxKeySol.KeySigMint':[
        {keyword:unMatch,enMsg:"cast fail",zhMsg:'鑄造失敗'}
    ],
    'UniSwap2.swapExpect':[
        {keyword:unMatch,enMsg:"swap fail",zhMsg:'兌換失敗'}
    ],
    'GoldenNft.runUserBatchSafeMint':[
        {keyword:unMatch,enMsg:"cast fail",zhMsg:'鑄造失敗'}
    ],
    'Nft.toApproveTokenId':[
        {keyword:unMatch,enMsg:"approve fail",zhMsg:'授權失敗'}
    ],
    'Nft.RunPayment':[
        {keyword:unMatch,enMsg:"pay fail",zhMsg:'支付失敗'},
        {keyword:'PaidInFull',enMsg:'has already unlock',zhMsg:'已激活解锁'},
        {keyword:'No TokenId',enMsg:'No TokenId',zhMsg:'缺失tokenId'}
    ],
    'Nft.checkTokenIdIsApproveToNftItemExchange':[
    ],
    'Nft.RunPaymentByKey':[
        {keyword:unMatch,enMsg:"unlock fail",zhMsg:'解鎖失敗'},
        {keyword:'PaidInFull',enMsg:'has already unlock',zhMsg:'已激活解锁'},
        {keyword:'No TokenId',enMsg:'No TokenId',zhMsg:'缺失tokenId'},
        {keyword:'ERC20: Insufficient funds',enMsg:'dont have key',zhMsg:'没有钥匙了'}
    ],
    'Nft.RunOpenBindBox':[
        {keyword:unMatch,enMsg:"open fail",zhMsg:'開啟失敗'},
        {keyword:'NftToRawData.length > 0',enMsg:'box has already opend',zhMsg:'盲盒已打开'},
        {keyword:'The original data does not match',enMsg:'open fail,data not match',zhMsg:'打开失败,数据不匹配'}
    ],
    'GoldenNft.runBurnNftByTokenId':[
        {keyword:unMatch,enMsg:"use fail",zhMsg:'使用失敗'},
        {keyword:'operator query for nonexistent token',enMsg:'golden token is not exist',zhMsg:'金币不存在,无法使用'},
    ],
    'GoldenNft.toApproveToConsignment':[
        {keyword:unMatch,enMsg:"approve fail",zhMsg:'授權失敗'},
        {keyword:'approve caller is not owner nor approved for all',enMsg:'you cant approve golnft not belongs you',zhMsg:'无法授权不属于你的金币'},
    ],
    'MarketSol.BuyEquipmentAndGolden':[
        {keyword:unMatch,enMsg:"buy fail",zhMsg:'購買失敗'},
        {keyword:'This NFT has not been listed',enMsg:'this item is not at market',zhMsg:'物品已不在拍卖行'},
    ],
    'MarketSol.EquipmentRunListingNFT':[
        {keyword:unMatch,enMsg:"listing fail",zhMsg:'上架失敗'},
    ],
    'MarketSol.cancelMyEquipment':[
        {keyword:unMatch,enMsg:"delisting fail",zhMsg:'下架失敗'},
    ],
    'NftItemExchange.runFarmToNFT':[
        {keyword:unMatch,enMsg:"buy Box fail",zhMsg:'購買失敗'},
        {keyword:'Panic due to OVERFLOW',enMsg:'Panic due to OVERFLOW',zhMsg:'盲盒庫存丟失'},
    ],
    'NftItemExchange.runNFtToFarm':[
        {keyword:unMatch,enMsg:"exchange fail",zhMsg:'兌換失敗'},
        {keyword:'unknown custom error',enMsg:'unkownCustomError',zhMsg:'未知的自定义错误'},
    ],
    'PaymentSol.runBuyByHash':[
        {keyword:unMatch,enMsg:"fail",zhMsg:'失敗'},
    ],
    'ConsignmentSol.cancelByGoldType':[
        {keyword:unMatch,enMsg:"fail",zhMsg:'失敗'},
    ],
    'ConsignmentSol.AddGoldPurchase':[
        {keyword:unMatch,enMsg:"create fail",zhMsg:'發起失敗'},
    ],
    'ConsignmentSol.shellGold':[
        {keyword:unMatch,enMsg:"operation fail",zhMsg:'操作失敗'},
        {keyword:'transfer caller is not owner nor approved',enMsg:'caller it not owner nor approved',zhMsg:'調用者不匹配'},
        {keyword:'transfer from incorrect owner',enMsg:'caller it not owner nor approved',zhMsg:'不拥有该金幣'},
        {keyword:'goldType mismatching',enMsg:'goldType mismatching',zhMsg:'金幣類型不匹配'},
    ]
};

const commonErr=[
    {keyword:'wait contract CD',enMsg:"Please wait for the network congestion",zhMsg:"網絡擁堵請等待"},
    {keyword:'nonce is not ready',enMsg:"nonce is not ready,please wait",zhMsg:"nonce還未準備好,請等待"},
    {keyword:'pol must be greater than',enMsg:"pol must be greater than0.5",zhMsg:"pol内部资金不足"},
    {keyword:'nonce_is_wrong',enMsg:"nonce not ready, please wait",zhMsg:"nonce未準備好,請等待"},
];
// 上面的通用错误中.主要是由AssembleErr，这个内部处理过的问题.


// 可能的错误.
export function AbiCallParseError(mapName,errInfo) {
    const lng = vueStore.state.appBaseOption.language=='en'?'en':'zh';
    const expectMap = errMap[mapName];
    let parseMsgInfo =null;
    if(expectMap){
        // 能用到这里的,至少map是肯定匹配的
        const index = _.findIndex(expectMap,o=>errInfo.toString().indexOf(o.keyword)>-1);
        if(index>-1){
            parseMsgInfo=(lng=='en'?'fail:':'失败:')+expectMap[index][lng+'Msg'];
        }
    }else{

    }

    if(parseMsgInfo==null){
        // 这个是公有错误.
        const index = _.findIndex(commonErr,o=>errInfo.toString().indexOf(o.keyword)>-1);
        if(index>-1){
            parseMsgInfo=commonErr[index][lng+'Msg'];
        }
    }



    if(parseMsgInfo==null&&expectMap){
        // 如果不是预期之内的错误.也不是公有错误.但是能匹配上map.则提示每种map里的默认错误即可
        if(expectMap.length>0){
            parseMsgInfo=expectMap[0][lng+'Msg'];
        }else{
            // 如果这都没有..那就不要有错误提示了.
        }
    }


    if(parseMsgInfo==null){
        // 1.不是预期之内的错误. 2.不是公有的那种错误  3.匹配的map内还没有默认的提示方式.
        // 到了这一步还没有值，就只显示失败了
        parseMsgInfo=(lng=='en'?'fail:':'失败:');
        // 如果map专门处理以及通用处理后依然找不到错误.才是未知错误.
        // parseMsgInfo=(lng=='en'?'fail:':'失败:')+'>>_'+errInfo;
    }
    // commonErr
    return parseMsgInfo||errInfo;
}

