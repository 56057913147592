const ErrCodeMap={
    '-998':{zh:'網絡未連接',en:'network disconnect'},
    '-999':{zh:'網絡錯誤',en:'network error'},
    '0':{zh:'.未知错误.',en:'ERROR'},
    '200':{zh:'正常',en:'normal'},
    '1000':{zh:'json參數解析失敗',en:'JsonError'},
    '1001':{zh:'SigMessage數據錯誤',en:'SigMessageError'},
    '1002':{zh:'有效時間戳必須大於當前時間且不大於5分鐘',en:'SigMessageTimeError'},
    '1003':{zh:'簽名地址不匹配',en:'SigMessageAddressError'},
    '1004':{zh:'解析參數操JSON錯誤',en:'ParameterJsonError'},
    '1005':{zh:'轉換數值錯誤',en:'DecimalError'},
    '1006':{zh:'沒有權限',en:'NotAuthorized'},
    '1007':{zh:'非法參數',en:'InvalidParameter'},
    '1008':{zh:'名字重複',en:'NameRepeat'},
    '1009':{zh:'轉換錯誤',en:'StrconvError'},
    '1010':{zh:'沒有管理權限',en:'NoOwnership'},
    '1011':{zh:'我的ID已經存在',en:'MyIdExist'},
    '1012':{zh:'Grpc服務通信錯誤',en:'GrpcConnError'},
    '1013':{zh:'讀取用戶公共等級列表出錯',en:'ReadUserLevelListError'},
    '1014':{zh:'讀取用戶數據錯誤',en:'ReadPlayerInfoError'},
    '1015':{zh:'我的ID為空',en:'MyIdIsNil'},
    '1016':{zh:'讀取用戶種子列表錯誤',en:'ReadPlayerInventoryError'},
    '1017':{zh:'讀取用戶數據錯誤',en:'ReadAutoUserInfoError'},
    '1018':{zh:'讀取websocketURL數據錯誤',en:'ReadWebsocketUrlError'},
    '1019':{zh:'推薦人ID不存在',en:'The recommender ID does not exist'},
    '1020':{zh:'重复的昵称',en:'DuplicateNames'},
    '1021':{zh:'一天只能修改一次',en:'RenameUser24H'},
    '1022':{zh:'日志數據不能超過3天',en:'Record date for no more than 3 days'},
    '1023':{zh:'获取gas价格错误',en:'GetGasPriceError'},
    '1024':{zh:'游戲版本錯誤，請更新至最新',en:'Game Version Error, Please update to the lastest version'},
    '1025':{zh:'讀取鏈合約數據錯誤',en:'CallContractDataError'},
    '1026':{zh:'無法刪除當前版本',en:'DeleteCurrentVersionError'},
    '1028':{zh:'服務器維護中，具體恢復時間請看官方通告',en:'The server is under maintenance, Please refer to the office notice for the recovery time.'},
    //
    '2001':{zh:'數據庫錯誤',en:'DBError'},
    '2002':{zh:'沒有查詢到該數據',en:'DBNotData'},
    '2003':{zh:'更新错误',en:'DBUpdateError'},
    '2004':{zh:'創建錯誤',en:'DBCreateError'},

    '3001':{zh:'你們已經是好友了',en:'You are already friends'},
    '3002':{zh:'你們還不是好友',en:'You are not friends'},
    '3003':{zh:'不能選擇自己',en:'cannot select yourself'},

    '4001':{zh:'redis錯誤',en:'RedisError'},
    '4002':{zh:'沒有查詢到該數據',en:'RedisNotData'},
    '4003':{zh:'更新redis數據錯誤',en:'RedisUpdateError'},

    '5001':{zh:'Token不匹配',en:'TokenError'},
    '5002':{zh:'ID不匹配',en:'IdError'},
    '5003':{zh:'沒有Header信息',en:'HeaderError'},
    '5004':{zh:'由於政策問題，我們不為中國大陸玩家提供游戲服務.',en:'Due to policy issues, We don\'t provide game services for players in mainland china'},
    '5005':{zh:'您的賬戶已經被封禁，進入Discord聯係管理員瞭解詳情.',en:'Your account are banned, Please contact our service in Discord Channel.'},
    '5006':{zh:'您的賬戶已經被封禁，進入Discord聯係管理員瞭解詳情.',en:'Your account are banned, Please contact our service in Discord Channel.'},

    '6001':{zh:'初始化用戶錯誤',en:'InitPlayerError'},
    '6002':{zh:'不存在的用户',en:'NotExistentUsers'},
    '6003':{zh:'讀取玩家信息錯誤',en:'ReadPlayerDataError'},
    '6004':{zh:'讀取玩家公共等級數據錯誤',en:'ReadPlayerLevelDataError'},
    '6005':{zh:'讀取玩家庫存錯誤',en:'ReadPlayerLevelInventoryError'},
    '6006':{zh:'讀取種子數據錯誤',en:'ReadSeedInfoError'},
    '6007':{zh:'不存在的種子ID',en:'NotExistentSeedInfo'},
    '6008':{zh:'您今天的偷取次數已經用完',en:'Steal used up'},
    '6009':{zh:'此作物不能被偷取',en:'MinimumLowerLimit'},
    '6010':{zh:'您今天已經偷取過此作物了',en:'You already steal the crop today'},
    '6011':{zh:'道具距离上次冷却时间未过1天，无法再次装备',en:'EquipmentCooling'},
    '6012':{zh:'無法使用比您等級高的裝備',en:'Cannot use equipment higher than your level'},
    '6013':{zh:'這個裝備不屬於您',en:'Equipment does not belong to you'},
    '6014':{zh:'此類型裝備正在使用中，請先脫下原來的裝備再使用新裝備',en:'Same kind of equipment cannot be used twice. Take off first and use another.'},
    '6015':{zh:'無效操作由於無法找到裝備',en:'Invalid operation, Beacuse can\'t find this equipment'},
    '6016':{zh:'種子數量不足',en:'InsufficientNumberOfSeeds'},
    '6017':{zh:'獲取偷取次數錯誤',en:'StealCountLog'},
    '6018':{zh:'倉庫中沒有種子',en:'No seeds in wearhouse '},
    '6019':{zh:'讀取裝備數據錯誤',en:'ReadEquipmentInfo'},
    '6020':{zh:'寫入裝備數據到reids錯誤',en:'WriteEquipmentInfo'},
    '6021':{zh:'此塊土地還未被播種',en:'Not Been Seeded Yet'},
    '6022':{zh:'裝備不存在',en:'Equipment not exist'},
    '6023':{zh:'裝備耐久度不足',en:'Insufficient equipment durability'},
    '6024':{zh:'運輸車耐久度不足導致無法繼續偷取，請先使用FARM積分恢復耐久',en:'Insufficient truck durability to steal. Please repair use FRAM credit.'},
    '6025':{zh:'收穫积分不足',en:'InsufficientHarvestIntegral'},
    '6026':{zh:'任務獎勵已領取',en:'Task Reward Received'},
    '6027':{zh:'還未完成該任務',en:'Task Not Completed'},
    '6028':{zh:'請您先升級成爲VIP后再使用此功能',en:'Upgrade VIP to use this feature'},

    '6029':{zh:'你必须先持有3件装备',en:'at leaset own 3 equipments'},
    '6030':{zh:'今日鑄造次數已用完',en:'Today\'s casting times are used up'},
    '6031':{zh:'您需要先開啓3次盲盒后，才有鑄造金幣NFT的權限',en:'You need to open the mystery box 3 times before you have permission to mint gold coin NFT'},

    //
    '7003':{zh:'讀取玩家土地信息錯誤',en:'ReadLandDataError'},
    '7004':{zh:'地塊不存在',en:'PlotNotExist'},
    '7005':{zh:'土地索引不符合',en:'LandIndexError'},
    '7006':{zh:'土地尚未解鎖',en:'Land has not been unlocked yet'},
    '7007':{zh:'土地解鎖錯誤，土地可能已被解鎖',en:'Land unlock error'},
    '7008':{zh:'土地已達到最高等級',en:'Land has reached the highest level '},
    '7009':{zh:'土地不存在',en:'LandNotExist'},
    '7010':{zh:'土地已種植作物，無法繼續播種',en:'Land planted already'},
    '7011':{zh:'作物還未成熟',en:'Immature Crops'},
    '7012':{zh:'種子數量不足或倉庫沒有該種子',en:'SeedAmountError'},
    '7013':{zh:'收穫金幣計算錯誤',en:'CalculationError'},
    '7014':{zh:'沒有可以播種的土地',en:'No land to sow'},
    '7015':{zh:'沒有可以收穫的作物',en:'No crops to harvest'},
    '7016':{zh:'獲取土地BUFF失敗',en:'LevelToPlotProduction'},
    '7017':{zh:'讀取土地等級錯誤',en:'ReadPublicPlotLevelsError'},
    '7018':{zh:'讀取土地解鎖數據錯誤',en:'ReadPlotExpandInfoError'},
    '7019':{zh:'讀取偷取記錄錯誤',en:'ReadStealCountLogError'},
    '7020':{zh:'讀取偷取方錯誤',en:'RedisStealAttackerLogError'},
    '7021':{zh:'盲盒還未完成支付',en:'Mystery Box Unpaid'},
    '7022':{zh:'人物未達到等級要求',en:'Character don\'t reach the level requirement'},
    //
    //
    //
    '8001':{zh:'金幣餘額不足',en:'Insufficient balance of gold coins'},
    '8002':{zh:'USDT餘額不足',en:'Insufficient balance of USDT'},
    '8003':{zh:'獲取USDT餘額失敗',en:'Get USDT Amount Error'},
    '8004':{zh:'您的等級太低',en:'Your level is too low'},
    '8005':{zh:'金幣餘額為負，不能繼續偷取作物',en:'The gold coin balance is negative, cannot steal'},
    //
    //
    '9001':{zh:'創建訂單錯誤',en:'Create order error'},
    '9002':{zh:'找不到訂單，可能是訂單已過期',en:'Order update error'},
    '9003':{zh:'創建金幣NFT訂單錯誤',en:'Create gold coins NFT order error'},
    '9004':{zh:'訂單重複.',en:'OrderExist'},
    '9005':{zh:'不存在的訂單HASH',en:'OrderHashNotExist'},
    '9006':{zh:'創建盲盒鑰匙簽名錯誤',en:'Create a mystery box key signature error'},
    //
    '10001':{zh:'已有公会，请先退出原公会',en:'RepeatedJoining'},
    '10002':{zh:'非公会成员',en:'NonUnionMembers'},
    '10003':{zh:'请勿重复申请',en:'PleaseDoCannotApplyAgain'},
    '10004':{zh:'管理员不能退出公会',en:'OwnerCannotQuit'},
};
const emptyError= {zh:'未知错误',en:'unkown error'};
import vueStore from '@/store/index'
export function ParseCodeError(codeNum,callPos=null) {
    // zhTw 和en
    const lngP = vueStore.state.appBaseOption.language=='en'?'en':'zh';
    const codeStr= codeNum+'';
    // if(ErrCodeMap[codeStr]){
    //     return (callPos?'['+callPos+']':'')+ErrCodeMap[codeStr][lngP];
    // }else{
    //     return (callPos?'['+callPos+']':'')+emptyError[lngP];
    // }
    if(ErrCodeMap[codeStr]){
        return ErrCodeMap[codeStr][lngP];
    }else{
        return emptyError[lngP]+'_code:'+codeNum;
    }

}