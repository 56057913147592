import "core-js/modules/es.array.push.js";
import { showDialog, showToast } from 'vant';
import { manualRefreshToken } from '@/gameApi';
import { Center } from '../../chainField/chainCenter';
import { logInPhone } from '../../util/phoneLog';
import TallPopFrame from "../frameStyle/tallPopFrame";
import { saveMap } from '@/util/localStore';
import { AbiCallParseError } from "../../chainField/contractApi";
import { SetTxWaitTimeout, BaseContractSetting } from '@/chainField/contractApi/NamingSummary';
import { updateStorePlayerInfo, compareSystemTime } from '@/hooks';
export default {
  components: {},
  data() {
    return {
      runTimeValue: 5
    };
  },
  created() {},
  mounted() {},
  beforeUnmount() {},
  computed: {
    nowRunTime() {
      return this.$store.state.newRunTime;
    },
    ifSetGasPriceInTx() {
      return this.$store.state.setGasPriceInTx;
    }
  },
  methods: {
    getTestTime() {
      compareSystemTime();
    },
    forceRemindMe({
      open = false
    }) {
      if (open) {
        localStorage.setItem(saveMap.user_key_load_type, 'create');
        localStorage.setItem(saveMap.remind_save_key, 'show');
      } else {
        localStorage.setItem(saveMap.user_key_load_type, 'import');
        localStorage.setItem(saveMap.remind_save_key, 'hidden');
      }
    },
    commonCoolDown() {
      showToast(this.UiText.devCDSetFree);
      this.$store.dispatch('submitCooldown');
    },
    viewToast() {
      let msg = '';
      for (let i = 0; i < 100; i++) {
        msg += '重复提示';
      }
      showToast(msg);
    },
    testDialog() {
      let testMsg = '这是很长的内容';
      for (let i = 0; i < 10; i++) {
        testMsg += testMsg;
      }
      this.showVanPopUp(testMsg, () => {
        showToast(this.runTimeValue);
        console.log('this.runTimeValue' + this.runTimeValue);
      });
    },
    DEBUG() {
      this.$store.commit('uidebug');
    },
    changeLanguage() {
      const curLng = this.app_lng == 'en' ? 'en' : 'zhTw';
      if (curLng == 'en') {
        this.$i18n.locale = 'zhTw';
        this.$store.commit('setLanguage', 'zhTw');
      } else {
        this.$i18n.locale = 'en';
        this.$store.commit('setLanguage', 'en');
      }
    },
    setReceiptRunTime(seconds) {
      const setRes = SetTxWaitTimeout(seconds);
      showToast(setRes ? '设置成功' + seconds + 's' : '设置失败');
    },
    changeGasSet() {
      this.$store.commit('gasPriceSetOnOff');
    },
    providerConnect() {
      Center.ConnectProvider();
    },
    setNewRunTime() {
      showToast(this.UiText.devSetNewRunTime + ':' + this.runTimeValue + 's');
      this.$store.commit('setNewRuntime', this.runTimeValue);
    },
    changeToNextNode() {
      Center.changeNextNode();
    },
    refreshNonce() {
      showToast(this.UiText.devManualRefreshNonce);
      Center.refreshNonceFromEth();
    },
    forceChangeNonce() {
      this.$store.commit('managerAdjustNonce', {
        action: 'reduce'
      });
    },
    testApi() {
      this.$router.push('/apiTest');
    },
    farmSelectorTest() {
      Farmt.testAbiSelectors();
    },
    sound1() {
      this.$audio.play('c2');
    },
    bgmStart() {},
    bgmPause() {},
    toRefreshToken() {
      manualRefreshToken().then(res => {
        console.log('主动刷新的结果', res);
      });
    },
    plusTest() {
      window.plus.push.getClientInfoAsync(info => {
        let cid = info['clientid'];
        showToast(cid + '>客户编号');
      });
    },
    testPhoneLog() {
      logInPhone({
        msg: 'androidStudio下能看到这个信息'
      });
    },
    providerMockScan() {
      // 测试扫块。这个用不上的.别去用.
      Center.testRunScan();
    },
    notifyManager() {
      if (!window.plus) {
        return showToast(this.UiText.notPlus);
      }
      const main = plus.android.runtimeMainActivity();
      console.log('main');
      console.log(main);
      const pkName = main.getPackageName();
      console.log('pkname');
      console.log(pkName);
      let compat;
      try {
        compat = plus.android.importClass('android.support.v4.app.NotificationManagerCompat');
      } catch (e) {
        console.log('android获取通知错误', e);
      }
      try {
        if (compat == null) {
          compat = plus.android.importClass('androidx.core.app.NotificationManagerCompat');
        }
      } catch (e) {
        console.log('androidX获取通知错误', e);
      }
      if (!compat) {
        return showToast("未获得compat");
      }
      const packageNames = compat.from(main);
      showToast(JSON.stringify(packageNames));
      console.log('调用方法判断是否开启了通知');
      if (packageNames.areNotificationsEnabled()) {
        showToast(this.UiText.notifyEnable);
      } else {
        const that = this;
        plus.nativeUI.confirm('开启通知权限?', function (e) {
          if (e.index == 1) {
            that.startNotificationIntent(main);
          }
        }, 'cyberFarm', ['不用', '开启']);
      }
    }
  }
};