import {ethers} from "ethers";

import {Center} from "@/chainField/chainCenter";
import {addressConfig} from "@/config";
import BigNumber from 'bignumber.js';
import {AssembleErr, ErrorTypes,BaseContractSetting} from "@/chainField/contractApi/NamingSummary";
import {
    ClearWaitForReceiptByHash,
    EventTag,
    PolyEventManager,
    syncWaitForReceiptByHash
} from "@/util/polyEventProgress";
import {EventEnum, EventManager} from "@/util/EventManager";

const abisRouter = [
    "function WETH() external pure returns (address)",
    "function swapExactTokensForTokens(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)",
    "function swapExactETHForTokens(uint amountOutMin, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)",
    "function swapExactTokensForETH(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)",
    "function swapETHForExactTokens(uint amountOut, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)",
    "function getAmountsOut(uint amountIn, address[] calldata path) external view returns (uint[] memory amounts)",
    "function getAmountsIn(uint amountOut, address[] calldata path) external view returns (uint[] memory amounts)"
];
const abisFactory=[
    'function getPair(address,address) view returns (address)'
];
const abisPair=[
    'function getReserves() view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)'
];

let _addressRouter=addressConfig.uniswapV2Router;
let _addressFactory=addressConfig.uniswapV2Factory;
const Main={
    contract:{factory:null,router:null},
    signerContract:{factory:null, router:null},
};

const init=function(){
        if(!Center.provider){
        return {status: false, message: 'provider need init!'}
    }
    Main.contract.router= new ethers.Contract(_addressRouter,abisRouter,Center.provider);
    Main.contract.factory= new ethers.Contract(_addressFactory,abisFactory,Center.provider);
    return {status: true, contract: Main.contract}
};

const contractAddSigner = function(type){
    if(!Main.contract[type]){
        const step0 = init();
        if(!step0.status){
            return step0;
        }
    }
    if(!Center.signer){
        return {status: false, message: 'center signer missed', showUserAuth:true}
    }
    Main.signerContract[type]=Main.contract[type].connect(Center.signer);
    return {status: true, signerContract: Main.signerContract}
}

function ready(type){
    if(!Main.contract[type]){
        init();
    }
}

function getCoinInfo(name){
    if(name=='usdt'){
        return {address:addressConfig.usdt.address, unit:addressConfig.usdt.unit}
    }
    else if(name=='farm'){
        return {address:addressConfig.farm.address, unit:addressConfig.farm.unit}
    }
}
Main.swapExpect=async function(hraAmount,coinInName,coinOutName){
    if(!(hraAmount>0)){
        return {status:true,suaOutAmount:0,hraOutAmount:0};
    }
    ready('router');
    const coinInInfo = getCoinInfo(coinInName);
    const coinOutInfo = getCoinInfo(coinOutName);
    const suaAmountIn = ethers.parseUnits(hraAmount,coinInInfo.unit);
    const path =[coinInInfo.address,coinOutInfo.address];
    try{
        const expectAmountSua = await Main.contract.router.getAmountsOut(suaAmountIn,path);
        const hraOutAmount =ethers.formatUnits(expectAmountSua[1],coinOutInfo.unit);
        console.log('expect from:'+coinOutName+'to:'+coinOutName);
        console.log("swapExpect before", expectAmountSua[1]);
        console.log("swapExpect after", hraOutAmount);
        return {status:true,suaOutAmount:expectAmountSua[1], hraOutAmount: hraOutAmount}
    }catch (e) {
        console.log("swapExpect error");
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }
};
Main.swap=async function({hraAmount,coinInName,coinOutName,retryTxOptions=null}){
    ready('router');
    const queueNo= PolyEventManager.Add({event:EventTag.e_Farm和Usdt交换,callArgs:arguments,eventOpt:{coinInName:coinInName,coinOutName:coinOutName},data:''});

    const expectRes= await Main.swapExpect(hraAmount,coinInName,coinOutName);
    if(!expectRes.status){
        return AssembleErr({status:false, message:expectRes.message});
    }
    // if(!status){
    //     return AssembleErr({status:false, message:e});
    // }
    console.log('预计结果是俩', expectRes.suaOutAmount, expectRes.hraOutAmount);
    const slippageOri = 0.01; //其实是百分之0.1
    if(!Main.signerContract.router){
        const step0=contractAddSigner('router');
        if(!step0.status){
            return step0;
        }
    }

    const countRes = countAmountMinusSlip(expectRes.suaOutAmount,slippageOri);
    console.log('计算浮点后给用户看的数值是', countRes);
    const coinInInfo = getCoinInfo(coinInName);
    const coinOutInfo = getCoinInfo(coinOutName);
    const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
    const to= Center.userAddress;

    const amountSuaIn = ethers.parseUnits(hraAmount+'',coinInInfo.unit);
    const amountOutMin =expectRes.suaOutAmount; //最小输出数量可以是0
    console.log('输入的'+coinInName,amountSuaIn);
    console.log('输出的'+coinOutName,amountOutMin);
    const path =[coinInInfo.address,coinOutInfo.address];
    const slippage= ethers.parseUnits(slippageOri+'',18); // 这个18似乎是固定写法.
    console.log("传入具体行为的滑点数据",slippage);
    // 从u换f, 2.99978和10000.01903
    //   2.99966

    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.router.swapExactTokensForTokens.populateTransaction(
            amountSuaIn,
            amountOutMin,
            path,
            to,
            deadline);
        PolyEventManager.AddFilltxOpt({txOptions:gasOpt,event:EventTag.e_Farm和Usdt交换,queueNo:queueNo,data:popuInfo.data});
        let firstTx={};
        try{
            firstTx = await Main.signerContract.router.swapExactTokensForTokens(
                amountSuaIn,
                amountOutMin,
                path,
                to,
                deadline,
                { gasLimit: 300000 ,slippage,nonce:gasOpt.nonce}
            );
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_Farm和Usdt交换,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_Farm和Usdt交换,queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        console.log("Transaction hash:", firstTx.hash);
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("receipt confirmed");
            console.log(res);

            PolyEventManager.End({event:EventTag.e_Farm和Usdt交换,queueNo,txReceipt:res});
            EventManager.emit(EventEnum.farmtAndUsdtv2Swap,{coinInName:coinInName,coinOutName:coinOutName})
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_Farm和Usdt交换,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        return {status:true};
    }catch (e) {
        console.log("交换出错");
        console.log(e);
        PolyEventManager.Error({event:EventTag.e_Farm和Usdt交换,queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }
   
}

// 一个token1等于多少个token2? 这个东西没什么用.不要用这个方法.
Main.factoryPairReserveViewPrice=async function (token1,token2) {
    ready('factory');
    const pairAddress = await Main.contract.factory.getPair(token1,token2);
    console.log('pair地址',pairAddress);
    // 创建交易对合约实例
    const pairContract = new ethers.Contract(pairAddress, abisPair, Center.provider);
    // 获取交易对中代币和 USDT 的储备量
    const reserves = await pairContract.getReserves();
    // 获取代币和 USDT 的储备量
    const token1Reserve = reserves.reserve0;
    const token2Reserve = reserves.reserve1;
    const divValue = accDiv(token2Reserve,token1Reserve); //传入了token1和token2. 用token2的reserve除以token1的.就可以得到一个token1相当于多少个token2
    const div2Value = accDiv(token1Reserve,token2Reserve);
    console.log("一个token1能换到多少个token2",divValue);
    console.log("反过来呢",div2Value);
    return divValue;
};



// 乘法
function accDiv(arg1,arg2){
    if(!arg1||!arg2){
        return 0;
    }
    const num = new BigNumber(arg1).div(new BigNumber(arg2));
    return num.toFixed();
}
// 减法
function accMinus(arg1,arg2){
    const num = new BigNumber(arg1).minus(new BigNumber(arg2));
    return num.toFixed();
}
// 乘法
export function accMul(arg1, arg2) {
    if (!arg1 || !arg2) {
        return 0;
    }
    const num = new BigNumber(arg1).times(new BigNumber(arg2));
    return num.toFixed();
}
// 计算考虑浮点在内后最少要多少
function countAmountMinusSlip(inputAmount,slippageP =0.1){
    const slippage = accDiv(slippageP, 100);
    console.log('当前滑点是', slippage);
    const value = accMinus(inputAmount, accMul(inputAmount, slippage));
    console.log("处理前后.",inputAmount, value);
    return value;
}
Main.checkSlipPoint=function(){
    const slippageP = 1; //默认0.1么
    const input = 100;
    const slippage = accDiv(slippageP, 100);
    console.log('除以后', slippage);
    const value = accMinus(input, accMul(input, slippage));
    console.log("应该对了.",value);
    // 三个档位的gasPrice

    //5000000000
    //6000000000
    //7000000000
}



export {Main as UniSwap2}