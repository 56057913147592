import {ethers} from "ethers";
import {Center} from "@/chainField/chainCenter";
import {addressConfig} from "@/config";

import {ErrorTypes,AssembleErr,BaseContractSetting} from './NamingSummary';
import {
    ClearWaitForReceiptByHash,
    EventTag,
    PolyEventManager,
    syncWaitForReceiptByHash
} from "@/util/polyEventProgress";
import {EventEnum, EventManager} from "@/util/EventManager";


const abis=[
    'function ListingNFT(address addr,uint256 tokenId,uint256 price) external',
    'function Place(address addr,uint256 tokenId) external',
    'function OffShelfNFT(address addr,uint256 tokenId) external'
];

// offShelfNft是下架
let _address=addressConfig.market;
const Main={
    contract:null,
    signerContract:null,
    _Iface:null,
};
Main.abis=abis;

const init=function(){
    if(!Center.provider){
        return {status: false, message: 'provider need init!'}
    }
    Main.contract=new ethers.Contract(_address,abis,Center.provider);
    return {status: true, contract: Main.contract}
};

Main.test=function () {

}
const contractAddSigner = function(level){
    if(!Main.contract){
        const step0 = init();
        if(!step0.status){
            return step0;
        }
    }
    if(!Center.signer){
        return {status: false, message: 'center signer missed', showUserAuth:true}
    }
    Main.signerContract=Main.contract.connect(Center.signer);
    return {status: true, signerContract: Main.signerContract}
}

function ready(){
    if(!Main.contract){
        init();
    }
}


function levelAddress(levelNum){
    if(levelNum==1){
        return addressConfig.level1;
    }else if(levelNum==2){
        return addressConfig.level2;
    }else if(levelNum==3){
        return addressConfig.level3;
    }
}
// 丢拍卖行
Main.EquipmentRunListingNFT=async function({nftLevel,tokenId,farmPrice=15,source,retryTxOptions=null}){
    console.log("丢到拍卖行去",tokenId);
    tokenId=parseInt(tokenId);
    ready();
    if(!Main.signerContract){
        const toSign = contractAddSigner();
        if(!toSign.status){
            return toSign;
        }
    }
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const nftAddress=levelAddress(nftLevel);
        const _inputPrice = ethers.parseUnits(farmPrice+'', 18); // 拍卖价格都是Farm的值的量
        console.log('哪种盒子的装备',nftAddress);
        const popuInfo = await Main.signerContract.ListingNFT.populateTransaction(nftAddress,tokenId,_inputPrice);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_NFT装备上架市场,source:source,eventOpt:{levelNum:nftLevel,tokenId:tokenId},data:popuInfo.data});

        let firstTx={};
        try {
            firstTx= await Main.signerContract.ListingNFT(nftAddress,tokenId,_inputPrice,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_NFT装备上架市场,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_NFT装备上架市场,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        console.log('装备丢拍卖的tx',firstTx);
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("装备丢拍卖的tx",res);
            PolyEventManager.End({event:EventTag.e_NFT装备上架市场,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.equipmentPushToMarket,{levelNum:nftLevel,tokenId:tokenId,receiptInfo:{status:res.status}});

        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_NFT装备上架市场,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        return {status: true, message:'wait finished'}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_NFT装备上架市场,queueNo:queueNo,error:e});
        return AssembleErr({status:false,message:e},ErrorTypes.ethErr);
    }
};

// 金币现在不能丢拍卖行咯.金币nft不能上架了
Main.none_GoldenRunListingNFT=async function({tokenId,farmPrice=15}){
    console.log("丢到拍卖行去",tokenId);
    tokenId=parseInt(tokenId);
    ready();
    if(!Main.signerContract){
        const toSign = contractAddSigner();
        if(!toSign.status){
            return toSign;
        }
    }
    try{
        const goldAddress=addressConfig.goldenNft;
        const _inputPrice = ethers.parseUnits(farmPrice+'', 18); // 拍卖价格都是Farm的值的量
        console.log('这是金币的',goldAddress);
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt();
        const farmCoinToNftTx= await Main.signerContract.ListingNFT(goldAddress,tokenId,_inputPrice,gasOpt);
        console.log('金币丢拍卖',farmCoinToNftTx);
        const res = await farmCoinToNftTx.wait();
        console.log('金币丢到拍卖了',res);
        return {status: true, message:'wait finished'}
    }catch (e) {
        return {status: false, message: 'fail',error:e}
    }

}
// 下架
Main.cancelMyEquipment=async function ({contract_addr,tokenId,source,retryTxOptions=null}){
    ready();
    if(!tokenId){
        return AssembleErr({status:false, message:'缺少tokenId'})
    }
    if(!Main.signerContract){
        const toSign = contractAddSigner();
        if(!toSign.status){
            return toSign;
        }
    }
    const tokenAddress=contract_addr;
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.OffShelfNFT.populateTransaction(tokenAddress,tokenId);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_NFT装备取消上架,source:source,eventOpt:{contractAddr:contract_addr,tokenId:tokenId},data:popuInfo.data});
        console.log('调用OffShelfNFT之前的地址',tokenAddress,"和tokenid",tokenId);
        let firstTx={};
        try {
            firstTx = await Main.signerContract.OffShelfNFT(tokenAddress,tokenId,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_NFT装备取消上架,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_NFT装备取消上架,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        console.log('下架物品tx',firstTx);
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("取消装备上架",res);
            PolyEventManager.End({event:EventTag.e_NFT装备取消上架,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.equipmentSellCancel,{contractAddr:contract_addr,tokenId:tokenId,receiptInfo:{status:res.status}});

        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_NFT装备取消上架,queueNo:queueNo,error:e,txResponse:firstTx});
        });

        return {status:true, message:'下架中'};
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_NFT装备取消上架,queueNo:queueNo,error:e});
        return AssembleErr({status:false,message:e},ErrorTypes.ethErr);
    }

}

// 这个是买装备.不能买金币了.
Main.BuyEquipmentAndGolden=async function ({contract_addr,tokenId,source,retryTxOptions=null}){
    if(!tokenId){
        return {status: false, message:'缺少tokenId'};
    }
    let tokenAddress=contract_addr;
    console.log("传入的tokenId是啥",tokenId);
    if(tokenAddress==''){
        return AssembleErr({status:false, message:'执行购买的Addres是空的'})
    }
    ready();
    if(!Main.signerContract){
        const toSign = contractAddSigner();
        if(!toSign.status){
            return toSign;
        }
    }
    let queueNo='';
    try{
        console.log('place方法的参数',tokenAddress,"和token",tokenId);
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.Place.populateTransaction(tokenAddress,tokenId);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_拍卖行购买装备,source:source,eventOpt:{contractAddr:contract_addr,tokenId:tokenId},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx = await Main.signerContract.Place(tokenAddress,tokenId,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_拍卖行购买装备,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_拍卖行购买装备,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("购买装备",res);
            PolyEventManager.End({event:EventTag.e_拍卖行购买装备,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.equipmentBuy,{contractAddr:contract_addr,tokenId:tokenId,receiptInfo:{status:res.status}});
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_拍卖行购买装备,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        //
        return {status: true}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_拍卖行购买装备,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr)
    }
};
export {Main as MarketSol}