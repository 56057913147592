import {GameRequest} from "@/util/axiosRequest/gameRequest";

export async function ExchangeGold({gold_type,Amount}){
    return await GameRequest.post('api/auth/nft/exchange_gold',{gold_type:gold_type,Amount:Amount});
}

export async function GetBlindBoxData({level,token_id}){
    return await GameRequest.post('api/auth/nft/get_blind_box_data',{level:level+'',token_id:token_id+''});
}


// 这个道具流通信息没用到.
export async function GetCoinNftInfo({level,token_id}){
    return await GameRequest.post('api/auth/nft/get_NFT_data',{level:parseInt(level),token_id:parseInt(token_id)});
}



export async function ViewSingleEquipmentDes({equipment_id}){
    return await GameRequest.post('api/auth/nft/get_NFT_attribute_value',{equipment_id:equipment_id+''});
}


// 修理装备
export async function repairEquipment({level, token_id,integral=10}){
    return await GameRequest.post('api/auth/nft/repair', {level:level+'', token_id:token_id+'',integral:integral});
}


// 获取单个nft数据的详细信息..这个是包括了盲盒.装备的么
export async function GetOneNftInfo({friend_id,level,token_id,gold=false}){
    return await GameRequest.post('api/auth/nft/get_nft_info',{friend_id:parseInt(friend_id),level:parseInt(level),token_id:parseInt(token_id),gold:gold});
}


// 获取排行榜
export async function getRankCharts(){
    return await GameRequest.post('api/auth/nft/get_charts');
}
