import {ethers} from "ethers";
import {EventManager,EventEnum} from "@/util/EventManager";
import {Center} from "@/chainField/chainCenter";
import {ErrorTypes,AssembleErr,BaseContractSetting} from './NamingSummary';
import {
    ClearWaitForReceiptByHash,
    EventTag,
    PolyEventManager,
    syncWaitForReceiptByHash
} from "@/util/polyEventProgress";
// 它没有所谓的合约地址
const Main={
};

const init=function(){
        if(!Center.provider){
        return {status: false, message: 'provider need init!'}
    }
    return {status: true}
};

function ready(){
    init();
}
Main.balance=async function(){
    ready();
    try{
        const suaBalance = await Center.provider.getBalance(Center.wallet.address);
        const hraBalance = ethers.formatEther(suaBalance); //
        return {status: true, suaBalance:suaBalance,hraBalance:hraBalance};
    }catch (e) {
        console.error("Error checking Pol balance:", e);
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr)
    }
};

function parseExpectError(e){
    let expectError=null;
    return expectError;
}
function parseReceiptExpectError(e){
    let expectError=null;
    if(e.toString().indexOf('transaction was replaced')>-1){
        expectError='@transaction was replaced';
    }
    return expectError;
}

// pol的转账
Main.transferPol= async function({toAddress,hraAmount,retryTxOptions=null}){
    ready();
    if(!Center.signer){
        Center.signer=Center.wallet.connect(Center.provider);
    }

    console.log("链接了provider之后的walletSigner", Center.signer);
    const suaAmountToTransfer = ethers.parseEther(hraAmount+''); //

    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_Pol币发送,eventOpt:{toAddress:toAddress},data:'sendTransaction'+suaAmountToTransfer});
        let firstTx={};
        try {
            firstTx =await Center.signer.sendTransaction({
                to:toAddress,
                value: suaAmountToTransfer,
                ...gasOpt
            });
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            const couldExpectErr=parseExpectError(e);
            return await PolyEventManager.txCatchError({event:EventTag.e_Pol币发送,queueNo:queueNo,error:couldExpectErr||e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);

        PolyEventManager.Update({event:EventTag.e_Pol币发送,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        console.log('主pol转账tx生成', firstTx);
        // 等待交易确认

        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            PolyEventManager.End({event:EventTag.e_Pol币发送,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.polFinishTransfer,{tokenKey:'token_pol',toAddress:toAddress});
            console.log(" transfer successful",res);
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_Pol币发送,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        return {status:true}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_Pol币发送,queueNo,error:e});
        console.error("Error transferring :", e);
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr)
    }
}


export {Main as MainPol}