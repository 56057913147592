import {ethers} from 'ethers';
import {EventTag, PolyEventManager} from "@/util/polyEventProgress";
import {addressConfig,AddressKeyToQueueName} from "@/config";
const Main={
};
let CenterInst=null;

async function checkAddressIsContractOrEOA(address){
    if(address==null){
        return 'null';
    }
    const codeRes = await CenterInst.provider.getCode(address);
    if (codeRes !== '0x') {
        console.log(`${address} is a contract address.`);
        return 'contract';
    } else {
        console.log(`${address} is an EOA (externally owned address).`);
        return 'EOA';
    }
}
Main.scanRoundBlock=async function(CenterPointer){
    if(CenterInst==null){
        console.log('装载实例');
        CenterInst=CenterPointer;
    }

    console.log('列出一套tag',EventTag);
    // 扫描附近的块.
    // const blockNumber=63070594;
    // const nonce = 1453;
    //const blockNumber=63073987; // end 63074015
    // const blockNumber=63074015; // end 63074015
    // const nonce = 1456;

    // const blockNumber=63075965; // end 63074015
    // const nonce = 1499;

    const blockNumber=63079093; // end 63074015
    const nonce = 1500;

    scanRoundBlockBase({blockNumber,nonce});
};

async function scanRoundBlockBase({blockNumber,nonce}){

    // const {data} = await CenterInst.getTransaction('0x4af475da02f5c49454450b7d8e3fd65f1218acdceec78fd7717261fb39106295');
    // analyseTransaction(data);
    // return;
    // 扫描附近的块.
    console.log('查询'+blockNumber+'附近的block,找寻'+nonce+'号');
    const address = CenterInst.wallet.address;
    console.log('钱包地址是',address);
    // 0xb06F7AA5a83bc4c95694D80535168B2dD0b4522A
    let pause=false;
    for (let i = blockNumber-1; i <= blockNumber+50&&pause==false; i++) {
        const block = await CenterInst.provider.getBlock(i,true);
        for(let index=0;index<block.prefetchedTransactions.length;index++){
            if(block.prefetchedTransactions[index]['from'].toLowerCase()==address.toLowerCase()){
                if(block.prefetchedTransactions[index].nonce==nonce){
                    console.log('截停时的i'+i);
                    pause=true;
                    console.log('匹配到这个nonce的东西了');
                    const theInfo = block.prefetchedTransactions[index];
                    analyseTransaction(theInfo);
                }
            }
        }
    }
}

// 不管用什么方式拿到的transaction.
async function analyseTransaction(info){
    console.log('取info',info);
    // console.log("nonce值",info.nonce);
    //"0xae0049a800000000000000000000000036a89b6dc6ec0547e44d394f8b636ad8560f6b830000000000000000000000000000000000000000000000000de0b6b3a7640000"
    const selector = info.data.substr(0,10);
    console.log('方法abi Selector:',selector);
    const checkRes = await checkAddressIsContractOrEOA(info.to);
    console.log('判断结果',checkRes);
    if(checkRes=='contract'){
        replaceErrorEvent({selector:selector,nonce:info.nonce,toAddress:info.to,hash:info.hash,data:info.data,blockNumber:info.blockNumber});
    }

}
import {Usdt,Nft,Farmt,UniSwap2,NftItemExchange,PaymentSol,MarketSol,BoxKeySol,ConsignmentSol,GoldenNft} from '@/chainField/contractApi';
function replaceErrorEvent({selector,nonce,toAddress,hash,data,blockNumber}){
    // 这东西应该是新增数据.不是替换原本的.

    console.log('扫块的toAddress地址',toAddress);

    let targetAddressKey=null;
    for(let addressKey in addressConfig){
        // 我知道这里要么是字符串要么是对象.没有其他情况
        if(typeof addressConfig[addressKey] ==='string'){
            // 这个是字符串.
            if(addressConfig[addressKey]==toAddress){
                targetAddressKey=addressKey;
            }
        }else if(typeof addressConfig[addressKey] === 'object' ){
            if(addressConfig[addressKey].address==toAddress){
                targetAddressKey=addressKey;
            }
        }
    }

    let contractQueueName=null;
    const setEventOpt={
        paramsInFunc:{},
    };
    if(targetAddressKey!=null){
        if(targetAddressKey=='level1'){
            setEventOpt.levelStr=targetAddressKey;
        }
        if(targetAddressKey=='level2'){
            setEventOpt.levelStr=targetAddressKey;
        }
        if(targetAddressKey=='level3'){
            setEventOpt.levelStr=targetAddressKey;
        }
        console.log('能定位到key',targetAddressKey);
        contractQueueName = AddressKeyToQueueName[targetAddressKey];
        console.log('定位到大概是怎样的queue',contractQueueName);
    }
    let targetSol = null;
    if(contractQueueName=='queueNft'){
        targetSol=Nft;
    }
    else if(contractQueueName=='queueFarmt'){
        targetSol=Farmt;
    }
    else if(contractQueueName=='queueGoldenNft'){
        targetSol=GoldenNft;
    }
    else if(contractQueueName=='queueMarket'){
        targetSol=MarketSol;
    }
    else if(contractQueueName=='queuePayment'){
        targetSol=PaymentSol;
    }
    else if(contractQueueName=='queueUsdt'){
        targetSol=Usdt;
    }
    else if(contractQueueName=='queueNftItemExchange'){
        targetSol=NftItemExchange;
    }
    else if(contractQueueName=='queueBoxKey'){
        targetSol=BoxKeySol;
    }
    else if(contractQueueName=='queueGoldConsignment'){
        targetSol=ConsignmentSol;
    }
    //     queuePol:[],
    //     queueUniswapv2:[],


    if(targetSol==null){
        return;
    }
    if(targetSol._Iface==null){
        targetSol._Iface=new ethers.Interface(targetSol.abis);
    }
    console.log('核对selector',selector);
    const targetFragment= targetSol._Iface.fragments.find(item=>item.selector==selector);
    const abiName = targetFragment.name;
    /*addressConfig*/
    const decoded = targetSol._Iface.decodeFunctionData(abiName,data);
    // const functionFragment = targetSol._Iface.getFunction(abiName);
    // const parameterNames = functionFragment.inputs.map(input => input.name);
    const parameterNames = targetFragment.inputs.map(input => input.name);
    const result = {};
    parameterNames.forEach((name, index) => {
        if(typeof decoded[index]==='bigint'){
            result[name] = Number(decoded[index]);
        }else{
            result[name] = decoded[index];
        }

    });
    setEventOpt.paramsInFunc=result;
    console.log('拿到SetEventOpt',setEventOpt);
    for(let key in EventTag){
        if(EventTag[key].contract==contractQueueName&&EventTag[key].abiName==abiName){
            console.log('匹配到这个事件了',EventTag[key]);
            PolyEventManager.AddTxInBlock({event:EventTag[key],data:data,blockNumber:blockNumber,hash:hash,nonce:nonce,setEventOpt:setEventOpt});
        }
    }
}


Main.getTxByNonce=async function (nonce) {
    const address = CenterInst.wallet.address;
    const latestBlock = 63069587;
    console.log('指定一个block',latestBlock);
    // 遍历区块，直到找到匹配的交易
    for (let i = latestBlock; i >= latestBlock-5; i--) {
        const block = await CenterInst.provider.getBlock(i,true);

        console.log('block信息是',block);


        for(let index=0;index<block.prefetchedTransactions.length;index++){
            if(block.prefetchedTransactions[index]['from'].toLowerCase()==address.toLowerCase()){
                console.log('来自我这钱包的');
                console.log("nonce值",block.prefetchedTransactions[index].nonce);
                console.log(block.prefetchedTransactions[index]);
            }
        }
    }

    console.log('Transaction not found');
    return null;
}
export {Main as ScanBlock}
