import {GameRequest} from "@/util/axiosRequest/gameRequest";

export async function getPlayerInfo({id}){
    // 新的微服务接口.前面依然有api.但是除了注册和拿到时间之外.第一个玩家信息接口就有问题.
    return await GameRequest.post('api/auth/player/get_player_info',{id});
}


// 获取用户库存 .这个是种子的列表
export async function getPlayerSeeds(){
    return await GameRequest.post('api/auth/player/get_player_inventory');
}

export async function MyOwnGoldNft({page,page_size,gold_type='-1'}){
    return await GameRequest.post('api/auth/player/get_player_gold_nft',{page,page_size,gold_type});
}

export async function MyBlindBoxList({page,page_size}){
    return await GameRequest.post('api/auth/player/get_player_blind_box_nft',{page,page_size});
}

// 玩家拥有的装备列表
export async function GetPlayerNftByPage({page,page_size,sort_type='price',asc=true}){
    // price, level
    return await GameRequest.post('api/auth/player/get_player_nft',{page:page,page_size:page_size,sort_type:'price',asc:true});
}

export async function equippedItem({level, token_id}){
    return await GameRequest.post('api/auth/player/equipped_item', {level:level+'', token_id:token_id+''});
}

export async function unequippedItem({level, token_id}){
    return await GameRequest.post('api/auth/player/unequipped_item', {level:level+'', token_id:token_id+''});
}


// 未铸造的钥匙列表
export async function getNeedFixKeySigList({page=0,page_size=20}){
    return await GameRequest.post('api/auth/player/get_player_blind_box_key_list',{page:page,page_size:page_size});
}


// 为铸造的金币nft列表
export async function getNeedFixGoldNftSigList({page=0,page_size=20}){
    return await GameRequest.post('api/auth/player/get_player_gold_sig_list\n',{page:page,page_size:page_size});
}


export async function RenamePlayer({name}){
    return await GameRequest.post('api/auth/player/rename_user',{name});
}



// 查看昨日收益
export async function ViewPlayerProfit({}){
    return await GameRequest.post('api/auth/player/get_profit_table',{});
}


//购买种子事件日志 0
//购买VIP    1
//购买钥匙   2
//升级土地   3
//拓展土地   4
//盲盒付款日志   5
//开盲盒日志 6
//收获日志   7
//提交任务日志   8
//注册邀请奖励日志   9
//邀请升级奖励日志   10

// 新加的日志

//市场购买日志    11
//市场上架日志    12
//市场下架日志    13
//兑换盲盒日志    14
//回收盲盒日志    15
//回收装备日志    16
//订单退款日志    17
export async function GetPlayerLogs({page,page_size,log_type=[],start_time,end_time}){
    return await GameRequest.post('api/auth/player/get_game_log_by_id',{page,page_size,log_type,start_time,end_time});
}

export async function GetPlayerLevelTaskList(data){
    return await GameRequest.post('api/auth/player/get_task_list_info', data);
}


export async function getTaskRewardApi(task_id){
    return await GameRequest.post('api/auth/player/submit_task', {task_id});
}



// 玩家的佣金相关.完整的信息
export async function GetUserRecommendInfo({}){
    return await GameRequest.post('api/auth/player/get_user_recommendations_info', {});
}


export async function GetReferrList({page:page,page_size:page_size}){
    return await GameRequest.post('api/auth/player/get_referrer_list', {page:page,page_size:page_size});
}


export async function GetRateBackLog({page:page,page_size:page_size}){
    return await GameRequest.post('api/auth/player/get_user_rate_back_log', {page:page,page_size:page_size});
}


