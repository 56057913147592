import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/main_bg.jpg';
const _hoisted_1 = {
  style: {
    "height": "100%"
  },
  class: "shield"
};
const _hoisted_2 = {
  class: "wall"
};
const _hoisted_3 = {
  style: {
    "color": "white",
    "z-index": "999"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_floating_bubble = _resolveComponent("van-floating-bubble");
  const _component_develop_ui = _resolveComponent("develop-ui");
  const _component_van_popup = _resolveComponent("van-popup");
  const _component_event_queue_sample = _resolveComponent("event-queue-sample");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString($options.pageHeight), 1), _createElementVNode("img", {
    onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
    src: _imports_0,
    style: _normalizeStyle({
      height: $options.pageHeight
    })
  }, null, 36)]), _createVNode(_component_router_view)]), $options.showBubble ? (_openBlock(), _createBlock(_component_van_floating_bubble, {
    key: 0,
    offset: {
      x: $data.offset_x,
      y: $data.offset_y
    },
    icon: "desktop-o",
    axis: "xy",
    magnetic: "x",
    onClick: _cache[1] || (_cache[1] = $event => $data.openTestPage = true)
  }, null, 8, ["offset"])) : _createCommentVNode("", true), _createVNode(_component_van_popup, {
    show: $data.openTestPage,
    style: {
      "width": "100%",
      "background-color": "transparent"
    },
    onClickOverlay: _cache[2] || (_cache[2] = $event => $data.openTestPage = false)
  }, {
    default: _withCtx(() => [_createVNode(_component_develop_ui)]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_event_queue_sample)], 64);
}