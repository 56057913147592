import {ethers} from 'ethers';
import {EventManager,EventEnum}  from '@/util/EventManager';
import {generateRandomString} from '@/util/common'
import {currentChainConnectConfig, AccountSaveLevels} from '@/config'
import {saveMap} from '@/util/localStore';
import {GetServeGasPrice} from '@/gameApi';
import vueStore from '@/store/index'


const chainAvailHttps=[...currentChainConnectConfig.httpsList];
const chainAvailwebs=[...currentChainConnectConfig.webList];
let curChainConnectIndex = 1;
let curChainConnectWSIndex = 0;

// wallet 初始化.但是provider和signer都没呢
EventManager.on(EventEnum.walletInit,()=>{
    Center.userAddress=Center.wallet.address;
    InitProvider('钱包init');
});
EventManager.on(EventEnum.ProviderInit,()=>{
// 有wallet, 有provider了.
    Center.signer = Center.wallet.connect(Center.provider);
    EventManager.emit(EventEnum.signerInit,{});
    console.log('provider>signer setup');
    startPingTimer();
});
EventManager.on(EventEnum.signerInit,()=>{
    console.log("wallet provider signer就绪");
});

const defaultPKey="";
console.log(defaultPKey);
const Center={
    provider:null,
    wallet: null,
    signer:null, // signer其实就是connect provider之后的wallet.
    userAddress:null,
    encryptedWalletJson:null,
    feeData:{
        fastGasPriceValue:0,
        isRefresh:false,
    }
};



const runContractUselocalNonce=true;

Center.estimateGasMul110=function(estimateGas){
    const multiplier = 110n;
    return (estimateGas * multiplier) / 100n;
};
Center.submitCool=async function(){
    console.warn('----------------cooldown--------------');
    vueStore.dispatch('submitCooldown');
};
Center.submitCoolByNonce=async function(nonce){
    if(runContractUselocalNonce){
        console.warn('----------------cooldown--------------');
        vueStore.dispatch('submitCooldownByNonce',{nonce});
    }else{
        vueStore.dispatch('submitCooldown');
    }
};


//  这个是.如果此方法被调用时。当前的这个nonce确实是传入时的值的话.再去改变
Center.NonceChangeIfLocalEqualWhenDid=async function(nonce){
    console.warn('dispatchStoreNonceFromTO'+new Date().getTime());
    return vueStore.dispatch('storeNonceFromTo',{oldNonce:nonce,newNonce:nonce+1});
};

Center.NonceRefreshAnyway=async function(nonce){
    if(runContractUselocalNonce){
        console.warn('------------StartRefreshNonceAnyWay----------->'+new Date().getTime());
        return await Center.refreshNonceFromEth();
    }else{
        console.log('noncerefresh不启用');
        return -1;
    }
};

// 在发现nonce too low问题之后.延迟一些时间.去更新nonce
Center.DelayNonceRefreshAfterToolowError=async function(){
    console.warn('有toolow情况所以去刷新nonce');
    setTimeout(()=>{
        Center.refreshNonceFromEth();
    },2000)
};

Center.submitSetFree=async function(){
    return await vueStore.dispatch('submitCooldown');
};


function ifSetGasPrice(){
    return vueStore.state.setGasPriceInTx;
}
Center.checkSubmitCDAndGetTxOpt=async function(retryTxOptions=null){
    if(vueStore.state.token_pol.balance.hraAmount>=0.5){
        // 金额正常
    }else{
        throw new Error('insufficient funds:pol_must_be_greater_than 0.5');
        return;
    }
    let couldStart = await vueStore.dispatch('submitCouldStart');
    console.log('初步判断方法能执行么',couldStart);
    if(!couldStart){
        console.log('看一下卡CD多久了',vueStore.state.txSubmit.intoBusyTime);
        const passSeconds = (new Date().getTime()-vueStore.state.txSubmit.intoBusyTime)/1000;
        console.log('卡了'+passSeconds+'秒');
        const runTimePass=vueStore.state.newRunTime;
        if(passSeconds>runTimePass){
            console.log('卡了超过'+runTimePass+'秒就给放行');
            couldStart=true;
            // showToast("time passed"+runTimePass+"seconds,continue");
            Center.submitCool();
        }
    }

    console.log('再次判断方法能执行么',couldStart);
    if(!couldStart){
        EventManager.emit(EventEnum.submitCommonCD,{message:'网络拥堵请等待'});
        throw new Error('contract_run_in_cd');
        return;
    }
    const baseOpt={};
    let tempNonce =0;
    if(runContractUselocalNonce){
        // 如果开启了这个功能才去处理nonce
        if(retryTxOptions){
            if(retryTxOptions.nonce){
                tempNonce = retryTxOptions.nonce; // 这个是..设置旧nonce.重试的时候才设置这个.
            }else{
                // 这个就没nonce了..那就也用这个.
                tempNonce = await vueStore.dispatch('getNonce');
            }

            console.warn('覆盖旧事件nonce:'+tempNonce);
        }else{
            tempNonce = await vueStore.dispatch('getNonce');
        }
        if(tempNonce>-1){
            baseOpt.nonce=tempNonce;
        }else{
            Center.submitSetFree();
            throw new Error('nonce_is_wrong');
        }
        await vueStore.dispatch('submitSetNonceIfBusy',{nonce:baseOpt.nonce});
    }else{
        console.warn('<<<<<不启用nonce>>>>');
    }


    if(ifSetGasPrice()){
        Center.feeData.isRefresh=false;//每次都重置一下.
        try {
            await Center.getServerFastFee();
        }catch (e) {
            console.log('getFastFeeError',e)
        }
        if(Center.feeData.isRefresh&&Center.feeData.fastGasPriceValue>0){
            console.log('加速执行:'+Center.feeData.fastGasPriceValue);
            baseOpt.gasPrice=Center.feeData.fastGasPriceValue;
            if(retryTxOptions){
                if(retryTxOptions.biggerGasPrice&&retryTxOptions.biggerGasPrice>=baseOpt.gasPrice){
                    baseOpt.gasPrice= retryTxOptions.biggerGasPrice;
                    console.log('旧方法的1.1倍值比较大.用此值.'+retryTxOptions.biggerGasPrice+'大于'+baseOpt.gasPrice);
                } else{
                    console.log('还是当前的gasPrice比较大.'+baseOpt.gasPrice+'大于'+retryTxOptions.biggerGasPrice);
                }
            }

        }
    }else{
        console.warn('跳过了gasPrice的设置');
    }
    console.log('读取交易设置',baseOpt);



    return baseOpt;
};

const skipConnect=true;

// 内部初始化provider
const InitProvider = function(source){
    console.warn('init provider at',source);
    const targetPathHttp =chainAvailHttps[curChainConnectIndex];
    // const targetPathWebsocket =chainAvailwebs[curChainConnectWSIndex];
    try{
        // 默认超时是 300000 也就是300秒..5分钟.
        const _fetchRequest =new ethers.FetchRequest(targetPathHttp);
        _fetchRequest.timeout=2*60*1000 ;// 设置2分钟超时感觉也可以.
        Center.provider=new ethers.JsonRpcProvider(_fetchRequest);
        // Center.provider=new ethers.JsonRpcProvider(targetPathHttp);
        // setTimeout(()=>{
        //     console.log('10秒后断了provider');
        //     Center.provider.destroy();//
        // },10000);

        pingNode();
        EventManager.emit(EventEnum.ProviderInit,{});
        Center.refreshNonceFromEth();
    }catch (e) {
        console.warn('initProviderErr',e)
    }
};

async function pingNode() {
    if(Center.provider){
        // 毕竟要存在才行
        try{

            const countStart= new Date().getTime();
            const blockNumber= await Center.provider.getBlockNumber();
            // console.log('ping_block:'+blockNumber);
            const countEnd = new Date().getTime();
            const nodeDelay=(countEnd-countStart);
            vueStore.commit('setPingInfo',{NodeHealth:true,blockNumber:blockNumber,nodeDelay:nodeDelay});
        }catch (e) {
            // 当使用http链接方式的时候我在这里拿到报错了！！！！
            showToast("ProviderNodeConnectError!!");
            vueStore.commit('setPingInfo',{NodeHealth:false});
            console.error('ping rpcnode error',e);
        }

    }
}

function startPingTimer() {
    setInterval(()=>{
        pingNode();
    },60000)
}

// eslint-disable-next-line no-unused-vars
function hexToAddress(key){
    if (typeof(key) === "string" && !key.startsWith("0x")) {
        key = "0x" + key;
    }
    return key;
}

// 引入web worker之后.反而是又用回这个登录了.
Center.SkipDecodeWalletLoad=function({wallet,notifyWalletInit=true}){
    try{
        Center.wallet = wallet;
        localStorage.setItem(saveMap.activeWalletAddress,Center.wallet.address);
        saveSessionWt();
        if(notifyWalletInit){
            // 这里才去通知wallet链接了.
            EventManager.emit(EventEnum.walletInit,{});
        }

        return {status: true, message: "wallet ready"}
    }catch (e) {
        if(e.toString().includes('incorrect password')){
            return {status: false, message: "password is wrong!!"}
        }else{
            console.log("getWalletError",e);
            return {status: false, message: "get Wallet error"}
        }
    }
};
// 获取交易详情
Center.getTransaction=async function(txHash) {
    try {
        const transaction = await Center.provider.getTransaction(txHash);
        return {status: true, data:transaction}
    } catch (error) {
        console.error("Error fetching transaction:", error);
        return {status: false, error:'error'};
    }
}

// Center.NoProviderWalletLoadedSync=function(password){
//     const encodeWallet = localStorage.getItem(saveMap.encryptedWallet);
//     if(!encodeWallet){
//         return {status:false, message:'encryptedWallet is null'};
//     }
//     try{
//         Center.wallet = ethers.Wallet.fromEncryptedJsonSync(encodeWallet, password+'');
//         localStorage.setItem(saveMap.activeWalletAddress,Center.wallet.address);
//         saveSessionWt();
//         EventManager.emit(EventEnum.walletInit,{});
//         return {status: true, message: "wallet ready"}
//     }catch (e) {
//         if(e.toString().includes('incorrect password')){
//             return {status: false, message: "password is wrong!!"}
//         }else{
//             console.log("getWalletError",e);
//             return {status: false, message: "get Wallet error"}
//         }
//     }
// }

// 会阻塞页面的.
// Center.NoProviderWalletLoaded= async function(password){
//     return new Promise((resolve)=>{
//         const encodeWallet = localStorage.getItem(saveMap.encryptedWallet);
//         if(!encodeWallet){
//             return {status:false, message:'encryptedWallet is null'};
//         }
//         ethers.Wallet.fromEncryptedJson(encodeWallet, password+'').then(wallet=>{
//             Center.wallet=wallet;
//             localStorage.setItem(saveMap.activeWalletAddress,Center.wallet.address);
//             saveSessionWt();
//             EventManager.emit(EventEnum.walletInit,{});
//             resolve({status: true, message: "wallet ready"})
//         }).catch(e=>{
//             if(e.toString().includes('incorrect password')){
//                 resolve({status: false, message: "password is wrong!!"})
//             }else{
//                 console.log("getWalletError",e);
//                 resolve({status: false, message:'get wallet error'});
//             }
//         })
//     })
// };

const keepLiveSaveType=AccountSaveLevels.currenSelect;
function keepSaveGet(){
    if(keepLiveSaveType==AccountSaveLevels.session){
        const j = sessionStorage.getItem(saveMap.randomencodewt);
        const p = sessionStorage.getItem(saveMap.randompd)+'';
        return {j:j,p:p}
    }
    else {
        const j = localStorage.getItem(saveMap.randomencodewt);
        const p = localStorage.getItem(saveMap.randompd)+'';
        return {j:j,p:p}
    }
}

function keepSaveSet(p,content){
    if(keepLiveSaveType==AccountSaveLevels.session){
        console.log('session级保存了啊randompd',p);
        sessionStorage.setItem(saveMap.randompd,p);
        sessionStorage.setItem(saveMap.randomencodewt,content);
    }else{
        console.log('local级别存randompd了啊',p);
        localStorage.setItem(saveMap.randompd,p);
        localStorage.setItem(saveMap.randomencodewt,content);
    }
}
const saveSessionWt=function(){
    if(Center.wallet){
        const tempPd= generateRandomString(26);
        Center.wallet.encrypt(tempPd).then(res=>{
            keepSaveSet(tempPd,res);
        })
    }
}

Center.keepAliveWtConnect=function(){
    if(!Center.wallet){
        const keepGetRes = keepSaveGet();
        if(keepGetRes.p=='null'){
            console.log('keep alive null');
            return {status:false, message:'保活数据异常'}
        }
        Center.wallet = ethers.Wallet.fromEncryptedJsonSync(keepGetRes.j,keepGetRes.p);
        localStorage.setItem(saveMap.activeWalletAddress,Center.wallet.address);
        console.log('keep alive init');
        EventManager.emit(EventEnum.walletInit,{});
        return {status: true};
    }else{
        return {status: false, message: 'wallet is active'}
    }


};



Center.getProvider = function(){
    if(!Center.provider){
        return {status: false, message:'provider need init'}
    }
    return {status: true, provider:Center.provider};
};


Center.GetWallet=function(){
    if(!Center.wallet){
        EventManager.emit(EventEnum.AskForPwdAuth,{message:'要求用户输入密码'});
        return {status: false, message:'wallet need init'};
    }
    return {status:true, wallet: Center.wallet};
}

// 这个原本是import页面载入key的.由于encrypt耗时.也不用这个方法了
Center.loadPageManualPKey=async function(key,password){
    const wallet = new ethers.Wallet(key);
    console.time('encrypt');
    const encryptStr = await wallet.encrypt(password);
    localStorage.setItem(saveMap.encryptedWallet,encryptStr);
};
Center.simulatePageLogin=async function(){
    const password="123";
    console.time('walletinit');
    return new Promise((resolve)=>{
        const wallet = new ethers.Wallet(defaultPKey);
        console.timeEnd('walletinit');
        console.time('encrypt');
        wallet.encrypt(password).then(encryptStr=>{
            console.timeEnd('encrypt');
            console.time('skipDecode');
            localStorage.setItem(saveMap.encryptedWallet,encryptStr);
            const walletLoadRes = Center.SkipDecodeWalletLoad({wallet:wallet});
            console.timeEnd('skipDecode');
            if(walletLoadRes.status){
                resolve({status:true});
                console.log("成功登录");
            }else{
                resolve({status:false});
                console.log("登录失败");
            }

        })
    })

}

// 默认给3分钟吧
Center.WalletSignInfo=function(minutes= 2){

    // 用本地时间减去本地比服务器快的时间就是服务器的时间.
    const dependOnServerSecondStamp  = Math.floor((new Date().getTime()-parseInt(vueStore.state.fasterThanServerMilliSec))/1000);
    // const now=new Date();
    // now.setMinutes(now.getMinutes()+minutes);
    // const localTimeSeconds =Math.floor(now.getTime()/1000);

    if(!Center.wallet){
        return {status: false, message: 'wallet missed,cannot get token'};
    }
    console.warn('本地比服务器快的值',vueStore.state.fasterThanServerMilliSec);
    console.log('依靠系统的时间戳',dependOnServerSecondStamp);
    console.log('本地的时间戳',new Date().getTime()/1000);
    console.log('额外加时后');
    const message={
        address: Center.wallet.address,
      time: dependOnServerSecondStamp+minutes*60
        //   time: localTimeSeconds
    };
    console.log('额外加时后',message.time);
    const signInfo = Center.wallet.signMessageSync(JSON.stringify(message));
    return {
        status: true,
        data:{
            signed_message: signInfo,
            message
        }};
}
Center.devWalletSignInfo=function(){
    const wallet = new ethers.Wallet(defaultPKey);
    localStorage.setItem(saveMap.activeWalletAddress,wallet.address);
    const now=new Date();
    now.setMinutes(now.getMinutes()+3)
    const time =Math.floor(now.getTime()/1000);

    const message={
        address: wallet.address,
        time: time
    };

    const signInfo = wallet.signMessageSync(JSON.stringify(message));
    const content ={
        signed_message: signInfo,
        message
    };
    return content;
};

import {showToast} from 'vant';
import pureRequest from "@/util/axiosRequest/pureRequest";
Center.changeNextNode=function (){
    console.log('换下个节点');
    curChainConnectIndex++;
    if(curChainConnectIndex==chainAvailHttps.length){
        curChainConnectIndex=0;
    }
    showToast('change next node:'+chainAvailHttps[curChainConnectIndex]);
    InitProvider('切换节点');
}
Center.ConnectProvider=function (){

    showToast("Connect_provider")
    InitProvider('链接provider');
}
Center.getFee=async function () {
    const feeDataRes = await Center.provider.getFeeData();
    console.log('feeDataREs',feeDataRes);
    const oriGasPrice= feeDataRes.gasPrice ;
    const maxPriorityFeePerGas= feeDataRes.maxPriorityFeePerGas ;
    const maxFeePerGas= feeDataRes.maxFeePerGas ;
    const moreGasPrice =oriGasPrice*13n/10n;
    return {oriGasPrice:oriGasPrice,maxPriorityFeePerGas,maxFeePerGas,moreGasPrice:moreGasPrice}
}

Center.getPolyApiGasPrice=async function(){
    let apiKey='';
    const res = await pureRequest.get('https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey='+apiKey);
    if(res.status=='1'){
        console.log("apigas费用返回",res);
        const info = res.result;
        const fastGasSua = ethers.parseUnits(info.FastGasPrice+'', 9);
        console.log('拿到值',fastGasSua);
        return {status:true, data:{fastGasPrice:fastGasSua}}
    }else{
        return {status:false,message:'没返回'}
    }
}

Center.getServerFastFee=async function(){
    Center.feeData.fastGasPriceValue=0; // 先清零
    const res = await GetServeGasPrice();
    if(res.code==200){
        console.log('服务器gas费用返回',res);
        const data  = res.data;
        let fastGasSua = data.FastGasPrice*(10**9);
        console.log('sua获取可能多位小数',fastGasSua);
        if((fastGasSua+'').indexOf('.')>-1){
            const firstNumStr = (fastGasSua+'').split('.')[0];
            fastGasSua=BigInt(firstNumStr);
            console.log('gasPrice处理后',fastGasSua);
        }
        Center.feeData.fastGasPriceValue=fastGasSua;
        Center.feeData.isRefresh=true;
        return {status:true, data:{fastGasPrice:fastGasSua}}
    }else{
        return {status:false,message:'没返回'}
    }
}


Center.refreshNonceFromEth=async function () {
    if(Center.wallet){
        try{
            console.time('getnoncefrometh');
            const nonce = await Center.provider.getTransactionCount(Center.wallet.address, 'pending');
            await vueStore.dispatch('action_setNonce',nonce);
            console.timeEnd('getnoncefrometh');
        }catch (e) {
            console.warn('CenterGetTxCountErr',e);
            return;
        }

        return;
    }else{
        console.log('丢失钱包');
        return;
    }
}
Center.getNonceAndIncre=async function () {
    const nonce = vueStore.dispatch('getNonceAndIncre');
    return nonce;
};
Center.nonceReduce=async function () {
    vueStore.dispatch('NonceReduce');
};



import {EventRetry} from './EventRetry';
// 重新跑一遍
Center.runAgain=function (itemInStoreQueue) {
    const res = EventRetry(itemInStoreQueue);
    return res;
};
// Center.Subcri= async function () {
//
//     const path = 'https://polygon-mainnet.g.alchemy.com/v2/7SJ-wof1FiZDfkzwvF0JKvAPDe6H6JRO';
//
//     pureRequest.post(path,{
//         "id": 1,
//         "jsonrpc": "2.0",
//         "method": "eth_newPendingTransactionFilter"
//         },{
//         "accept": "application/json",
//         "content-type": "application/json"
//         }
//     ).then(res=>{
//         console.log('调用接口',res);
//     });
//
//     return;
//     try{
//
//
//         const params=[
//             "alchemy_pendingTransactions",
//             {
//                 toAddress: [
//                     Center.wallet.address,
//                 ],
//                 fromAddress:[
//                     Center.wallet.address
//                 ],
//                 hashesOnly: false
//             }
//         ]
//         const subscriptionId = await Center.provider.send('eth_subscribe', params);
//         console.log('拿订阅id',subscriptionId);
//
//         // Center.provider.on('pending',(pendingTx) => {
//         //     console.info('【订阅到的消息】:', pendingTx);
//         // });
//         // Center.provider.websocket.on('error', (error) => {
//         //     console.error('WebSocket error:', error);
//         // });
//     }catch (e) {
//         console.log('调用ethGetBLockNumber pending错误',e);
//     }
//
// };
import {ScanBlock} from './scanBlock';
import BigNumber from "bignumber.js";
Center.testRunScan=function () {
    ScanBlock.scanRoundBlock(Center);
}
export {Center}
