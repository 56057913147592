import {ethers} from "ethers";
import {EventManager,EventEnum} from "@/util/EventManager";
import {Center} from "@/chainField/chainCenter";
import {addressConfig} from "@/config";
import {AssembleErr, ErrorTypes,BaseContractSetting} from "@/chainField/contractApi/NamingSummary";
import _ from 'lodash'
import {
    ClearWaitForReceiptByHash,
    EventTag,
    PolyEventManager,
    syncWaitForReceiptByHash
} from "@/util/polyEventProgress";

const abis=[
    "function Buy(string memory hash,uint256 amount) external",
];


let _address=addressConfig.payment;
const Main={
    contract:null,
    signerContract:null,
    _Iface:null,
};
Main.abis=abis;

const init=function(){
        if(!Center.provider){
        return {status: false, message: 'provider need init!'}
    }
    Main.contract= new ethers.Contract(_address,abis,Center.provider);
    return {status: true, contract: Main.contract}
};

const contractAddSigner = function(){
    if(!Main.contract){
        const step0 = init();
        if(!step0.status){
            return step0;
        }
    }
    if(!Center.signer){
        return {status: false, message: 'center signer missed', showUserAuth:true}
    }
    Main.signerContract=Main.contract.connect(Center.signer);
    return {status: true, signerContract: Main.signerContract}
}

function ready(){
    if(!Main.contract){
        init();
    }
}
function parseExpectError(e){
    let expectError=null;
    if(e.toString().indexOf('transfer amount exceeds allowance')>-1){
        expectError='transfer amount exceeds allowance';
    }
    return expectError;
}

// address account, uint256 goldType, uint256 amount, uint256 time, uint256 nonce, bytes signature
// usdt的转
Main.runBuyByHash= async function({hash,suaAmount,source,eventOptPlus=null,retryTxOptions=null}){
    // 这个amount就是考虑位数的
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.Buy.populateTransaction(hash,suaAmount);

        const eventOpt={hash:hash};
        if(eventOptPlus){_.assign(eventOpt,eventOptPlus);}
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_U购买服务,eventOpt:eventOpt,source,data:popuInfo.data});
        let firstTx={};
        try{
            firstTx = await Main.signerContract.Buy(hash,suaAmount,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            const couldExpectErr=parseExpectError(e);
            return await PolyEventManager.txCatchError({event:EventTag.e_U购买服务,queueNo:queueNo,error:couldExpectErr||e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_U购买服务,queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("购买服务",res);
            PolyEventManager.End({event:EventTag.e_U购买服务,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.paymentForService,{eventOpt:eventOpt,source:source,receiptInfo:{status:res.status}});
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_U购买服务,queueNo:queueNo,error:e,txResponse:firstTx});
        });

        return {status:true}
    }catch (e) {

        PolyEventManager.Error({event:EventTag.e_U购买服务,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
    }
}

export {Main as PaymentSol}