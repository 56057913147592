import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import VueClipboard from 'vue-clipboard3';
import svgIconPlug from '@/util/svgIconPlugin';
import {audioplayer} from '@/util/media_player/audio_player';
import _filtersList from '@/filter/index';


import ToastPlugin from '@/components/cyberToast/index';
import i18n from './language/i18n';
import {showToast} from 'vant';
// import FastClick from 'fastclick';
// FastClick.attach(document.body);
const app=createApp(App);
app.use(ToastPlugin);
app.use(i18n);

import {Locale} from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
Locale.use('en-US',enUS);
app.use(store);
app.use(router);
app.use(VueClipboard);
app.use(svgIconPlug, {imports:[]});
import lodashPlug from 'lodash';
app.config.globalProperties.$filters=_filtersList;
app.config.globalProperties.$audio=audioplayer;
app.config.globalProperties.$_=lodashPlug;
import { globalMixin } from './mixins/globalMixins';
app.mixin(globalMixin);
let routerCurPath='';
router.afterEach((to,from)=>{
    routerCurPath=to.path;
});
if(window.plus){
    plusReady();
}else{
    document.addEventListener('plusready',plusReady,false)
}
function checkBackBtnEvent(path){
    if(path=='/login'){
        plus.runtime.quit();
    }else if(path.indexOf('/main/')>-1){
        // 我默认为.路径里有main的.说明是没有返回键按钮的.
        console.log("在主页.退出需要询问");
        exitAskApp();
    }else{
        router.go(-1);
        console.log("进到了别的页面.点返回会router退页");
    }
}



function exitAskApp() {
    if(plus.os.name=='iOS'){
        plus.nativeUI.confirm('quit app[ios]?', function(e){
            if(e.index>0){
                plus.runtime.quit();
            }
        }, 'HelloH5', ['取消','确定'])
    }else{
        plus.nativeUI.confirm('quit Game now?', function(e){
            if(e.index==1){
                plus.runtime.quit();
            }
        },"cyberFarm",['no','yes']);

    }
}

function plusReady() {
    plus.key.addEventListener('backbutton', function () {
        console.log("输出按返回时当前的路径",routerCurPath);
        checkBackBtnEvent(routerCurPath);
        // 在这里我们做了拦截操作.并且要判断.当前的path.以及什么时候弹出..什么时候只是调用backBtn
        // if(plus.os.name=='iOS'){
        //     plus.nativeUI.confirm('quit app[ios]?', function(e){
        //         if(e.index>0){
        //             plus.runtime.quit();
        //         }
        //     }, 'HelloH5', ['取消','确定'])
        // }else{
        //     plus.nativeUI.confirm('quit Game now?', function(e){
        //         if(e.index==1){
        //             plus.runtime.quit();
        //         }
        //     },"cyberFarm",['no','yes']);
        //
        // }


    })
}

let longPressTimer=null;
let longPressTouchTimer=null;
document.addEventListener('contextmenu', (event) => {
//skipprevent
    if(event.target&&event.target.tagName.toLowerCase()=='input'&&event.target.classList.contains('avoid_prevent')){
        // 是input.且包含了特定樣式，不要禁止菜單.
        console.log('特殊input不禁止');
    }else{
        if(event.pointerType!=='mouse'){
            // 如果不是鼠标事件就屏蔽.这个主要是处理让手机端长按不会出现选择复制菜单一类的
            event.preventDefault()
        }else{
            // 這個是鼠標操作的.不需要禁止.
        }
    }
});
// document.addEventListener('touchstart', (event) => {
//     console.log('touch开始',event);
//     longPressTouchTimer = setTimeout(() => {
//         console.log('阻止事件');
//         event.preventDefault();
//     }, 500);
// });
// document.addEventListener('touchend', () => clearTimeout(longPressTouchTimer));

app.mount('#app');




