import {UiShowText} from '../language/eventQueueLng';
import {showDialog} from "vant";
import {formatTimestamp} from '@/hooks';
import BigNumber from "bignumber.js";
import {getUnit} from '@/chainField/contractApi/index'
import {seedDictList} from '@/views/mainPages/tabSettingChilds/data';
export const  globalMixin = {
  computed:{
    staticValue(){
      return {
        baseApprove:9999999
      }
    },
    app_lng(){
      if(this.$store.state.appBaseOption.language=='en'){
        return 'en';
      }else if(this.$store.state.appBaseOption.language=='zhTw'){
        return "zhTw";
      }else if(this.$store.state.appBaseOption.language=='zh'){
        return "zh";
      }else{
        return "en"; //默认英文
      }
    },
    en_zh(){
      // 有些值只有en和zh的区分.所以用这个来区分.
      return this.app_lng=='en'?'en':'zh';
    },
    playerInfo(){
      return this.$store.state.playerInfo;
    },
    checkVipStatus(){
      if(this.playerInfo.vip==0){
        return {vipOpened:false,isVip:false}
      }
      const vipStamp = new Date(this.playerInfo.vip*1000).getTime();
      const nowStamp= new Date().getTime();
      if(vipStamp>nowStamp){
        return {vipOpened:true,isVip:true, leftTime: vipStamp-nowStamp}
      }else {
        return {vipOpened:true,isVip:false, overdueTime: nowStamp-vipStamp}
      }
    },

    storeTokenFarmt(){
      return this.$store.state.token_farmt;
    },
    storeTokenUsdt(){
      return this.$store.state.token_usdt;
    },
    UiText(){
      return UiShowText[this.en_zh];
    }
  },
  methods: {
    seedNameInDic(seedId){
      const data = seedDictList.find(item => item.id === seedId);
      if(data){
        if(this.en_zh=='en'){
          return data.english_name;
        }else{
          return data.chinese_name;
        }
      }else{
        return '';
      }
    },
    showVanPopUp(text,callBack=null){
      showDialog({ message: text,theme: 'round-button',
        confirmButtonText:this.UiText.popConfirm}).then(()=>{
          if(callBack){
            callBack();
          }

      });
    },
    timestampFormat(timestamp) {
      // return ParseTimestampToFormat(timestamp, 1);
      return formatTimestamp(timestamp);
    },
    SuaToHraValue(value,type='farm') {
      type=type.toLowerCase();
      const unit = getUnit(type);
      if(unit==0){
        return value;
      }
      let tempVal = value;
      if (typeof tempVal == 'string') {
        let bigNum = BigNumber(tempVal);
        const divRes=bigNum.div(new BigNumber(10**unit)).toNumber();
        return divRes;
      }else{
        return value/(10**unit)
      }

    },
  }
};
