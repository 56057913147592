import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-04d576cc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "cyber-toast"
};
const _hoisted_2 = {
  class: "cyber-toast-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(() => [$data.visible ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])])) : _createCommentVNode("", true)]),
    _: 3
  });
}