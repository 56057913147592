import {GameRequest} from '@/util/axiosRequest/gameRequest';
export * from './errorCodeMap';
export * from './api_player';
export * from './api_shop';
export * from './api_land';
export * from './api_friend';
export * from './api_market';
export * from './api_nft';
import {saveMap} from '@/util/localStore';
export function clearToken() {
    GameRequest.clearToken();
}

export function LogOutClear() {
    console.log('清理对应local和session');
    localStorage.removeItem(saveMap.encryptedWallet);
    localStorage.removeItem(saveMap.activeWalletAddress);
    localStorage.removeItem(saveMap.randompd);
    sessionStorage.removeItem(saveMap.randompd);
    localStorage.removeItem(saveMap.randomencodewt);
    localStorage.removeItem(saveMap.player_my_id);  //用来链socket的
}











export async function manualRefreshToken() {
    return await GameRequest.refreshToken({});
}

export async function RegisterNewPlayer(registerData) {
    return await GameRequest.refreshToken({registerData});
}







// 不同接口现在按照功能划分区域了.注意一下

export async function CheckInviteCodeEsit({ref}){
    return await GameRequest.post('api/get_ref_exist',{ref});
}

export async function GetServeTime(){
    return await GameRequest.post('api/get_serve_time',{});
}
export async function GetServeGasPrice(){
    return await GameRequest.post('api/get_gas_price',{});
}
export async function ApiOneFarmToUsdt(){
    return await GameRequest.post('api/get_farm_price',{});
}



export async function ApiGetAppVer(){
    return await GameRequest.post('api/get_game_version');
}

export async function ApiGetGameNotice(){
    return await GameRequest.post('api/get_game_Notice');
}





const key1 = '0x37143cf4ff61ad40245f80b722d6baaf120adef7eb719e12b12454ce2660aeb2';
const account1 = 'f1e3f3a5eb55286f9418e241a37b810f9284e98a';
const key2 = '0x16ffc159975fb2fadb66063f86f8f9015d287b6cba1b56ad26d1429e257664ea';
const accout2 = '611c9f05aed1788eec51fc2d8f4be11d5f27598b';







// 创建工会 404
// export async function createGuild(params){
//     return await GameRequest.post('api/auth/game/create_guild', params);
// }
// // 查询工会 404
// export async function searchGuildById(params){
//     return await GameRequest.post('api/auth/game/get_guild_info', params);
// }
// // 查询工会成员信息 404
// export async function getGuildMembersInfoById(params){
//     return await GameRequest.post('api/auth/game/get_guild_user_info', params);
// }
// // 修改工会名称 404
// export async function updateGuildName(params){
//     return await GameRequest.post('api/auth/game/modify_guild_name', params);
// }
// // 修改工会留言板 404
// export async function updateGuildText(params){
//     return await GameRequest.post('api/auth/game/modify_guild_text', params);
// }
// // 获取工会列表 404
// export async function getGuildList(params){
//     return await GameRequest.post('api/auth/game/get_guild_list', params);
// }
// 申请加入工会 404
// export async function applyJoinGuild(params){
//     return await GameRequest.post('api/auth/game/apply_to_join_guild', params);
// }
// // 退出工会 404
// export async function quitGuild(params){
//     return await GameRequest.post('api/auth/game/quit_guild', params);
// }
// // 获取申请工会人员列表 404
// export async function getApplyJoinGuildList(params){
//     return await GameRequest.post('api/auth/game/get_apply_to_join_guild_list', params);
// }
// // 工会申请处理 404
// export async function handleGuildApply(params){
//     return await GameRequest.post('api/auth/game/handle_apply_to_join_guild', params);
// }



export async function GetWebsocketUrl(){
    return await GameRequest.post('api/auth/notify/get_ws_url',{});
}


