import axios from 'axios';
import {currentApiPath} from '@/config';
import {showToast} from 'vant';
import {GameApiHeader,ClearGameApiHeader,ApplyGameApiToken,requestIsGetToken} from './token';
const instanceKey =currentApiPath.baseGame; //只能是gameApi的请求用这个发送.
console.warn('request实例地址',instanceKey);
const Gameins=axios.create({baseURL: instanceKey});
Gameins.interceptors.response.use(async (res)=>{
    if(!requestIsGetToken(res.config)&&codesNeedRefreshToken.indexOf(res.data.code)>-1){
        const refreshStatus = await ApplyGameApiToken({});
        if(!refreshStatus.status){
            console.log("get token fail！！！！！！！");
            return {data:{code:refreshStatus.code,message:'get user token fail.code:'+refreshStatus.code}};
        }
        const {Token, Address} = GameApiHeader();
        res.config.headers.Token=Token;
        res.config.headers.Address=Address;
        const resAgain = await Gameins.request(res.config);
        return resAgain;
    }else{
        // 这边的请求失败就失败吧
    }
    return  res;
});
// let tokenRunOut=false;
const codesNeedRefreshToken = [5001,5002,5003]; // 分别是token不匹配.id不匹配.没有header信息?



async function request(options){
    const config={
        ...options
    }
     const {Token,Address}=GameApiHeader();
    if(Token&&Address){
        config.headers={Address:Address,Token:Token};
    }


    try{
        const response = await Gameins.request(config);
        return response.data;
    }catch (e) {
        console.log(">>>>error>>>>>>>");
        console.log(e);
        if(e.response&&e.response.data){
            if(e.response.data['code']){
                const codeErr = errCodeMatch(e.response.data['code']);
                e.response.data.codeError = codeErr;
                return e.response.data;
            }else{
                // 不存在code
                const resdata = e.response.data;
                let extraErr = '';
                if(resdata.indexOf('Could not proxy request')>-1){
                    extraErr='could not proxy request';
                }
                showToast("Network request blocking");
                return {code:-998, data:{},codeErr:'网络请求不通:'+extraErr};
            }

        }else if(e.response){
            return {code:0,errCode:'未知的错误信息:status'+e.response.status}
        }else{
            if(e.toString().indexOf('Network Error')>-1){
                return {code:-999,errCode:'Network Error'}
            }else{
                return {code:0,errCode:'未知的错误信息'}
            }

        }
        console.log("<<<<error<<<<");

    }

}

// 这个是对外一般api使用的.他们不会传入header.
request.get=async function(path,params) {
    return request({url:path,method:'GET',params})
};

request.post=async function(path,data) {
    return request({url:path,method:'POST',data})
};

request.refreshToken=async function ({registerData=null}) {
    return await ApplyGameApiToken({registerData});
}

request.clearToken=async function () {
    console.log('clear gameapi token');
    return await ClearGameApiHeader();
}
export  {Gameins,request as GameRequest};


function errCodeMatch(code){
    if(code==undefined){
        return 'code缺失';
    }
    switch (code) {
        case 1004:
                return 'ParameterJsonError 解析参数到JSON错误';
                break;
        default:
            return 'unkown error'

    }
}
