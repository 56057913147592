import {EnvEnum} from './envEnum';
const AddressConfigDiffEnv={};
AddressConfigDiffEnv[EnvEnum.test]={
    area:'本地国内',
    usdt:{address:'0xF75F8383Ef4407a9363d023A2d8683294452e835',unit:6},
    farm:{address:'0x8edECfF44581a144d4E1f46Fbd7F35f516E47fD1',unit:18},
    uniswapV2Router:'0xedf6066a2b290C185783862C7F4776A2C8077AD1',
    uniswapV2Factory:'0x9e5A52f57b3038F1B8EeE45F28b3C1967e22799C',
    level1: "0x6466244E9108AFe3E541c190F1967a8412541472", //20240911
    level2: "0xAbf2665F686267aC736d17c5DcC5208a3125E25B",
    level3: "0x0F2AAFfE77f7EfbBEFb091cA3a045ec5079167eB",
    market:'0xc6cb780787C42be8189a25D77d724D7D982870C6', //市场合约.主要是拍卖行.
    goldenNft:'0x62e512F42b2cFc366A6F4869a67f0cAeACe814bA',  //金币合约
    goldenConsignment:'0x4825A128C6788A9816aB327A19B3406a824B066d', //金币寄售合约
    payment:'0xA5c854DF2fdEa093D3b19d219defC237a1907379',  //付款服务buy合约,购买合约.
    nftItemExchange:'0x5dA185BCD490E77900428256d7D15e10a618b7F8', //游戏兑换合约
    KeyLow:'0x7Bf116e0a3ebFCb1ebD77406e4ea056A49865740',
    KeyMedium:'0x998eD316f59eb421015887FBECee9c71238f85D4',
    KeyHigh:'0x04C022F52B535615B52E65613696A34540683108',//新的钥匙
}
const AddressKeyToQueueName={
    farm:'queueFarmt',
    goldenNft:'queueGoldenNft',
    market:'queueMarket',
    pol:'queuePol',
    level1:'queueNft',
    level2:'queueNft',
    level3:'queueNft',
    payment:'queuePayment',
    uniswapV2Router:'queueUniswapv2',
    uniswapV2Factory:'queueUniswapv2',
    usdt:'queueUsdt',
    nftItemExchange:'queueNftItemExchange',
    KeyLow:'queueBoxKey',
    KeyMedium:'queueBoxKey',
    KeyHigh:'queueBoxKey',
    goldenConsignment:'queueGoldConsignment', // 这个是金币求购的
}




AddressConfigDiffEnv[EnvEnum.japanTest]={
    area:'japantest',
    usdt:{address:'0xF75F8383Ef4407a9363d023A2d8683294452e835',unit:6},
    farm:{address:'0x8edECfF44581a144d4E1f46Fbd7F35f516E47fD1',unit:18},
    uniswapV2Router:'0xedf6066a2b290C185783862C7F4776A2C8077AD1',
    uniswapV2Factory:'0x9e5A52f57b3038F1B8EeE45F28b3C1967e22799C',
    level1: "0xEed7FF2dd61C7eb04c83E4f7cd2Cc9f43a6e10f7", //20240911
    level2: "0x5cB7FcD4eE126472e2Ac944368eE282f044e9548",
    level3: "0xd280d22a4D4D51a3A540bc0dE280959688108bd0",
    market:'0xE2d18734e4FA3e8d429236624677AfB5e2B0d0c6', //市场合约.主要是拍卖行.
    goldenNft:'0x5E6D48Dbc82AB05E7fF841B9b9Cf6Fd88E9D5FC6',  //金币合约
    goldenConsignment:'0x951A0A9E94A22820228123f00f789E055c5CB540', //金币寄售合约
    payment:'0x5415567913dad2415E2F40fB180A480Ad03B749A',  //付款服务buy合约,购买合约.
    nftItemExchange:'0x0aEFa8e61cBDc576a3fcC718396103aE9C74461b', //游戏兑换合约
    KeyLow:'0x90191709D72eC4Cdbc69D025F22EB049e06221d5',
    KeyMedium:'0xe67C141fc0a0675d69f5E2B8864E1B4b2D16548b',
    KeyHigh:'0x4270Aab9CE0466EA0b825Cd3edC0458C7249eeD7',//新的钥匙
};

AddressConfigDiffEnv[EnvEnum.japan]={
    area:'japan',
    usdt:{address:'0xc2132D05D31c914a87C6611C10748AEb04B58e8F',unit:6},
    farm:{address:'0x735c3a4687464C2F367026A01F466D096f260823',unit:18},
    uniswapV2Router:'0xedf6066a2b290C185783862C7F4776A2C8077AD1',
    uniswapV2Factory:'0x9e5A52f57b3038F1B8EeE45F28b3C1967e22799C',
    level1: "0x0a8a993bbB7eb55e497030Bb6b7CF063eb93588F", //20240911
    level2: "0x9fe1dd9bf46bBE869919d9eb6a5b95b5F7F41304",
    level3: "0xc3866901dF9596fcB57AFD434e86582BD6D305be",
    market:'0x8c45e09773f2EFE0e24f04625EEa22aBD58cB62C', //市场合约.主要是拍卖行.
    goldenNft:'0xC64Cc817D0877d204dC2B597747E95743F0b8527',  //金币合约
    goldenConsignment:'0x887Eab9a10C9c93C7b40c4e4D37f4C253515204a', //金币寄售合约
    payment:'0xbF0acD197D60aa86b52759c5E7460D28b0c825C1',  //付款服务buy合约,购买合约.
    nftItemExchange:'0xd7B0d74624a5069129D675d547f0F512E8999831', //游戏兑换合约
    KeyLow:'0x556824e8D69157E41B5230D5bBceC3Cd12697fEe',
    KeyMedium:'0xE3a734C6870DAF806E544bA658E52e8404A383A7',
    KeyHigh:'0x6aC852Dbcca9F23aB000Fe510517C41cf3963E56',//新的钥匙
};
// 默认情况下.应该是根据当前测试正式切换地址的.但是uniswap无论如何都是用的正式地址.其他的才是测试.

export {AddressConfigDiffEnv,AddressKeyToQueueName};