
export const eventLngMap={
    zh:{ queueName:'队列编号',
        action:'行为',
        eventStatus:'交易状态',
        status_new:'正在提交',
        status_txresponse:'已提交.等待交易',
        status_receipt:'交易已确认',
        tx_final_status:'交易最终状态',
        view_in_browser:'在区块浏览器上查看',
        transation_finish_time:'交易确认时间',
        error_create_time:'出错时间',
        check_hash_times:'检测hash次数',
        its_replace_tx_on_other:'它替换了',
        event_newtime:'事件创建时间',
        event_txtime:'交易到链时间',
        noData:'无数据',
    },
    en:{
        queueName:'queueNo',
        action:'behavior',
        eventStatus:'status',
        status_new:'is submitting',
        status_txresponse:'submit finish,wait mined',
        status_receipt:'get receipt',
        tx_final_status:'transaction final status',
        view_in_browser:'view in block browser',
        transation_finish_time:'transation_finish_time',
        error_create_time:'error_time',
        check_hash_times:'check_hash_times',
        its_replace_tx_on_other:'it repleced:',
        event_newtime:'event create time',
        event_txtime:'event txreponse time',
        noData:'no data',
    }

};


export const UiShowText={
    zh:{
        appCheckVer:{
            appPlat:"平台",
            appLocalVer:"本地版本",
            appRemoteVer:"服務版本",
            clickUpdate:"點擊更新應用",
            close:"關閉"
        },
        cantLogin:{
            serverMaintenance:"服務器維護中",
            userBan:"用戶賬號已被封"
        },
        inputHolder:{
          inputPrice:"输入單價",
            inputQty:"輸入數量"
        },
        goldNftPurchase:{
            minprice:"最低單價"
        },
        checkAppVersionFail:"遊戲客戶端版本校驗失敗",
        welcomeToGame:"歡迎回來",
        mobileScanInit:"等待掃描初始化",
        noPermitBack:"無權限,返回",
        waitplease:'請等待',
        equipIsDressed:"裝備穿戴中",
        mustBiggerThanMinPrice:"必須大於最小價格",
        listingWaitPlease:"上架中，請等待",
        boxToFarmWaitPlease:"兌換中,請等待",
        burningGoldNftWaitPlease:"金幣使用中,請等待",
        buySeedSuccess:"種子購買成功",
        landLvUpSuccess:"土地升級成功",
        landExpandSuccess:"土地擴展成功",
        boxOpendAlready:"盲盒已打開",
        cantChangeTypeInLocking:"不能更改方式",
        boxUnlockWithKeySuccess:"成功以鑰匙解鎖",
        boxUnlockWithUsdtSuccess:"成功以USDT解鎖",
        TokenIdError:"Token參數錯誤",
        isActiving:"正在激活",
        isUnlockingInKey:"鑰匙解鎖中",
        isApproving:"授權中",
        waitOpening:"正在開啟請等待",
        goldBalanceNotEnough:"金幣不足",
        orderIsCreating:"訂單生成中",
        delCropSuccess:"剷除成功",
        youDontHaveSeedSoBuy:"沒有種子了,請購買",
        harvestSuccess:"收穫成功",
        noHarvest:"無收穫",
        approveFailExecutionReverted:"授權失敗:execution reverted",
        originDataNotMatch:"原始數據不匹配",
        autoCheckHash:"自動檢索",
        newGameKeyIsCreated:"鑰匙成功鑄造",
        vipServiceStatusUpdated:"您的vip狀態更新了",
        CD:"冷卻",
        CDBusy:"網絡擁堵請等待",
        lvUpServiceCD:"升級服務冷卻",
        expandServiceCD:"擴展服務冷卻",
        burnGoldNftSuccess:'成功使用金幣NFT',
        burnGoldNftFail:'金幣NFT使用失敗',
        seedSuccess:"播種成功",
        batchSeedSuccess:"批量播種成功",
        txResponseMissed:"txResponse信息缺失",
        noHash:"HASH缺失",
        pushingMarketWait:'上架中請等待',
        replaceFeeTooLow:"replace fee too low",
        repairSuccess:"成功修復",
        createKeySuccess:"鑄造鑰匙成功",
        createGoldNftSuccess:"兌換金幣NFT成功",
        runCreateGoldNft:"執行鑄造金幣",
        copySuccess:"複製成功",
        pKeyIsNotValid:"請輸入私鑰",
        pleaseInputPwd:"請輸入密碼",
        pleaseInputNewPwd:"請輸入新密碼",
        pwdIsNotSame:"兩次密碼輸入不一致",
        inviteCodeWrong:"邀請碼無效",
        notPlus:"非真機環境",
        scanNoSuitCamera:"無合適的攝像頭設備",
        InsecureContextError:"不安全的運行環境",
        pwdHasChanged:"密碼已修改",
        purchasePriceMustBiggerFarm:"單價必須大於",
        dataNotFound:"未找到數據",
        cantBuyItemSelf:"不能購買自己的",
        buying:"購買中",
        boxOpenButDataNotSync:"盲盒已打開但是數據未同步",
        tokenFarmTransferSuccess:"farm發送成功",
        tokenUsdtTransferSuccess:"Usdt發送成功",
        tokenPolTransferSuccess:"pol發送成功",
        devCDSetFree:"冷卻清空",
        devSetNewRunTime:'設置卡new的冷卻放行時間',
        devManualRefreshNonce:"手動刷新nonce",
        notifyEnable:"通知權限可用",
        canOnlyDelistingYours:"只能下架自己的",
        delistingWaitplease:"正在下架",
        delistingSuccess:"成功下架",
        noGoldNftIsSelect:"未選擇金幣NFT",
        thisGoldNftNeedApprove:"該金幣NFT還未授權",
        onlyCreatorCouldCancel:"唯有發起者才能取消",
        renameSuccess:"成功改名",
        pleaseInputName:"請輸入暱稱",
        isDevTest:"測試",
        remaindNeedCheckOption:"請勾選",
        remaindValidateSuccess:"校驗成功",
        remaindValidateFail:"校驗失敗",
        keyCreateDataQualityMissed:'重鑄鑰匙的數據品質缺失',
        addFriendSuccess:"好友添加成功",
        removeFriendSuccess:"已刪除好友",
        steal:"偷取",
        popConfirm:"好的",
        boxLevel1:"初級盲盒",
        boxLevel2:"中級盲盒",
        boxLevel3:"高級盲盒",
        pwdIsWrong:"密碼錯誤",
        walletError:"錢包錯誤",
        effectTimes:'次',
    },
    en:{
        appCheckVer:{
            appPlat:"platform",
            appLocalVer:"local version",
            appRemoteVer:"server version",
            clickUpdate:"click update",
            close:"close"
        },
        cantLogin:{
            serverMaintenance:"server maintenance",
            userBan:"account is ban"
        },
        inputHolder:{
            inputPrice:"input price",
            inputQty:"input quantity"
        },
        goldNftPurchase:{
          minprice:"min price"
        },
        checkAppVersionFail:"game version check fail",
        welcomeToGame:"Welcome back",
        mobileScanInit:"waiting init",
        noPermitBack:"no auth,return",
        waitplease:'please wait',
        equipIsDressed:"equipment is dressed",
        mustBiggerThanMinPrice:"must bigger than min price",
        listingWaitPlease:"nft is listing,please wait",
        boxToFarmWaitPlease:"box is swap,please wait",
        burningGoldNftWaitPlease:"goldnft is using,please wait",
        buySeedSuccess:"buy seed successful",
        landLvUpSuccess:"land upgrade success",
        landExpandSuccess:"land expand success",
        boxOpendAlready:"box opened already",
        cantChangeTypeInLocking:"cannot change unlock type",
        boxUnlockWithKeySuccess:"unlock with key successful",
        boxUnlockWithUsdtSuccess:"unlock with usdt successful",
        TokenIdError:"Token param error",
        isActiving:"is activing",
        isUnlockingInKey:"unlocking with key",
        isApproving:"is approving",
        waitOpening:"is opening,please wait",
        goldBalanceNotEnough:"gold balance is not enough",
        orderIsCreating:"order is creating",
        delCropSuccess:"del success",
        youDontHaveSeedSoBuy:"there is not seed,please buy",
        harvestSuccess:"harvest success",
        noHarvest:"no harvest",
        approveFailExecutionReverted:"approve fail:execution reverted",
        originDataNotMatch:"origin data not match",
        autoCheckHash:"auto check",
        newGameKeyIsCreated:"new gamekey is created",
        vipServiceStatusUpdated:"your vip service updated",
        CD:"CD",
        CDBusy:"Please wait for the network congestion",
        lvUpServiceCD:"land upgrade service cd",
        expandServiceCD:"expand service cd",
        burnGoldNftSuccess:'goldNft to coins',
        burnGoldNftFail:'goldNft to coins fail',
        seedSuccess:"seed success",
        batchSeedSuccess:"batch seed success",
        txResponseMissed:"txResponseMissed",
        noHash:"HASH missed",
        pushingMarketWait:'listing,please wait',
        replaceFeeTooLow:"replace fee too low",
        repairSuccess:"repair success",
        createKeySuccess:"gamekey create success",
        createGoldNftSuccess:"swap goldnft success",
        runCreateGoldNft:"swap goldnft now",
        copySuccess:"copy success",
        pKeyIsNotValid:"please input private key",
        pleaseInputPwd:"please input password",
        pleaseInputNewPwd:"please input new password",
        pwdIsNotSame:"two passwords are inconsistent",
        inviteCodeWrong:"invite code is wrong",
        notPlus:"not real machine",
        scanNoSuitCamera:"no suitable camera device installed",
        InsecureContextError:"InsecureContextError",
        pwdHasChanged:"password changed",
        purchasePriceMustBiggerFarm:"price must bigger than",
        dataNotFound:"data not found",
        cantBuyItemSelf:"it is yours,cannot buy",
        buying:"buying...",
        boxOpenButDataNotSync:"box is opend but data sync fail",
        tokenFarmTransferSuccess:"farm transfer success",
        tokenUsdtTransferSuccess:"Usdt transfer success",
        tokenPolTransferSuccess:"pol transfer success",
        devCDSetFree:"cd set free",
        devSetNewRunTime:'set new free time',
        devManualRefreshNonce:"refresh nonce manual",
        notifyEnable:"notify enable",
        canOnlyDelistingYours:"canOnlyDelistingYours",
        delistingWaitplease:"is delisting,please wait",
        delistingSuccess:"delisting success",
        noGoldNftIsSelect:"no goldNft be selected",
        thisGoldNftNeedApprove:"this goldnft need approve first",
        onlyCreatorCouldCancel:"you are not the creator of this purchase",
        renameSuccess:"rename success",
        pleaseInputName:"please input name",
        isDevTest:"is Dev",
        remaindNeedCheckOption:"please check the option",
        remaindValidateSuccess:"validate success",
        remaindValidateFail:"validate fail",
        keyCreateDataQualityMissed:'key create data missed',
        addFriendSuccess:"add friend success",
        removeFriendSuccess:"remove friend",
        steal:"steal",
        popConfirm:"ok",
        boxLevel1:"level1box",
        boxLevel2:"level2box",
        boxLevel3:"level3box",
        pwdIsWrong:"Password Is Wrong",
        walletError:"walletError",
        effectTimes:'times',
    }
}