// 市场
import {GameRequest} from "@/util/axiosRequest/gameRequest";

export async function GetEquipMarketList({page,page_size,sort_type='level',asc=true,is_me=false, category, quality}){
    return await GameRequest.post('api/auth/market/equip_market_list',{
        page,page_size,
        sort_type:sort_type,
        asc:asc,
        is_me:is_me,
        category: category,
        quality: quality
    });
}


// 这个没用了 .这个在装备金币不同时在拍卖合约里出现后就不能用了
// export async function GetMyMarketList({page,page_size,sort_type='level',asc=true}){
//     return await GameRequest.post('api/auth/market/user_market_list',{page,page_size,sort_type:sort_type, asc:asc});
// }



export async function GetGoldPurchaseInfoList({page,page_size,sort_type='price',asc=true,is_me=false}){
    // sortType的值是price或者amount
    return await GameRequest.post('api/auth/market/gold_purchase_info_list',{page,page_size,sort_type:sort_type, asc:asc,is_me:is_me});
}

export async function GetDetailOfEquipOrGold({is_gold=false,level='0',token_id='0',gold_type=0,buyer=''}){
    // sortType的值是price或者amount
    return await GameRequest.post('api/auth/market/equip_gold_info',{is_gold:is_gold,level:level,token_id:token_id,gold_type:gold_type,buyer:buyer});
}
