import {ethers} from "ethers";
import {EventManager,EventEnum} from "@/util/EventManager";
import {Center} from "@/chainField/chainCenter";
import {addressConfig,EnvEnum} from "@/config";
import {showToast} from 'vant';
import {ErrorTypes,AssembleErr,BaseContractSetting} from './NamingSummary';
import {
    ClearWaitForReceiptByHash,
    EventTag,
    PolyEventManager,
    syncWaitForReceiptByHash
} from "@/util/polyEventProgress";
import vueStore from '@/store/index'
const abis=[
    "function balanceOf(address account) external view returns (uint256)",
    "function approve(address spender, uint256 amount) external returns (bool)",
    "function allowance(address owner, address spender) external view returns (uint256)",
    "function transfer(address to, uint256 value) returns (bool)"
];

const unitNum=18;

let _address=addressConfig.farm.address;
const Main={
    contract:null,
    signerContract:null,
    _Iface:null,
};
Main.abis=abis;

const init=function(){
        if(!Center.provider){
        return {status: false, message: 'provider need init!'}
    }
    Main.contract= new ethers.Contract(_address,abis,Center.provider);
    return {status: true, contract: Main.contract}
};

const contractAddSigner = function(){
    if(!Main.contract){
        const step0 = init();
        if(!step0.status){
            return step0;
        }
    }
    if(!Center.signer){
        return {status: false, message: 'center signer missed', showUserAuth:true}
    }
    Main.signerContract=Main.contract.connect(Center.signer);
    return {status: true, signerContract: Main.signerContract}
}

function ready(){
    if(!Main.contract){
        init();
    }
}
function getSelectors(abis) {
    const iface = new ethers.Interface(abis);
    const selectors = {};

    // 遍历 ABI 并生成每个方法的选择器
    for (const fragment of Object.values(iface.fragments)) {
        if (fragment.type === 'function') {
            console.log('输出fargment',fragment);
            console.log('对应的selector',fragment.selector);
        }
    }

    return selectors;
}
Main.testAbiSelectors= async function(){
    getSelectors(abis);
    // console.log('取到的config是啥',addressConfig);
    return AssembleErr({status:false, message:'原本的'},ErrorTypes.ethErr);
}
Main.balance=async function(){
    ready();
    if(!Main.contract){
        return {status: false, message:'contract is null!!'}
    }
    const address = Center.userAddress;
    const suabalance = await Main.contract.balanceOf(address);
    const hraBalance = ethers.formatUnits(suabalance,unitNum);
    return {status: true, suaBalance:suabalance,hraBalance:hraBalance};
}


// 查看授权金额.我的farm给uniswap多少了授权
Main.viewAllowance= async function(spender){
    ready();
    if(!Main.contract){
        return AssembleErr({status: false, message:'contract is null!!'});

    }
    const ownerAddress=Center.userAddress;
    if(['level1','level2','level3','uniswapV2Router','nftItemExchange','market','goldenConsignment'].indexOf(spender)>-1){
        try{
            const spenderAddress=addressConfig[spender];
            const allowanceSuaAmount = await Main.contract.allowance(ownerAddress,spenderAddress);
            const hraAmount = ethers.formatUnits(allowanceSuaAmount, unitNum)
            return {status:true,hraAmount:hraAmount,suaAmount:allowanceSuaAmount};
        }catch (e) {
            return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
        }
    }else{
        return AssembleErr({status: false, message:'spender is not valid!'});
    }
}

// 去授权
Main.toApprove= async function({spender,amount,valueType='HRA',retryTxOptions=null}){
    console.log('farmt的授权');
    valueType=valueType.toUpperCase();
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }
    // 比如'1.05'
    const amountToApprove = valueType=='HRA'?ethers.parseUnits(amount+'', unitNum):amount; //
    let spenderAddress='';
    let queueNo='';
    try{
        if(['level1','level2','level3','uniswapV2Router','nftItemExchange','market','goldenConsignment'].indexOf(spender)>-1){
            const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
            spenderAddress=addressConfig[spender];
            const popuInfo = await Main.signerContract.approve.populateTransaction(spenderAddress,amountToApprove);
            queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_Farm币授权,eventOpt:{spender:spender},data:popuInfo.data});
            let firstTx={};
            try {
                firstTx =  await Main.signerContract.approve(spenderAddress,amountToApprove,gasOpt);
                await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
            }catch (e) {
                Center.submitCoolByNonce(gasOpt.nonce);
                return await PolyEventManager.txCatchError({event:EventTag.e_Farm币授权,queueNo:queueNo,error:e});
            }
            Center.submitCoolByNonce(gasOpt.nonce);
            PolyEventManager.Update({event:EventTag.e_Farm币授权,queueNo:queueNo,txResponse:firstTx})
            Center.NonceRefreshAnyway();
            console.log("approve_tx", firstTx);
            syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
                ClearWaitForReceiptByHash(firstTx.hash);
                console.log("approve_tx_wait_res",res);
                PolyEventManager.End({event:EventTag.e_Farm币授权,queueNo:queueNo,txReceipt:res})
                vueStore.dispatch('getAllowance',{tokenKey:'token_farmt',spender:spender});
                EventManager.emit(EventEnum.farmtFinishApprove,{tokenKey:'token_farmt',spender:spender});
            }).catch(e=>{
                PolyEventManager.receiptCatchError({event:EventTag.e_Farm币授权,queueNo:queueNo,error:e,txResponse:firstTx});
            });
            return {status:true, waitTx:firstTx, message:'内部等待wait'};
        }
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_Farm币授权,queueNo:queueNo,error:e});
        return AssembleErr({status:false,message:e},ErrorTypes.ethErr);
    }


}


// farm的转账
Main.transfer= async function({toAddress,hraAmount,retryTxOptions=null}){
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }

    if(!(toAddress&&toAddress.length>0&&toAddress.indexOf('0x')>-1)){
        return AssembleErr({status: false, message:'toAddress is not valid'});
    }
    let queueNo='';
    const amountToTransfer = ethers.parseUnits(hraAmount+'', unitNum); //
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.transfer.populateTransaction(toAddress,amountToTransfer);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_Farm币发送,eventOpt:{toAddress:toAddress},data:popuInfo.data});

        let firstTx={};
        try{
            firstTx = await Main.signerContract.transfer(toAddress,amountToTransfer,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_Farm币发送,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_Farm币发送,queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        console.log('transfer tx added',firstTx);
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            EventManager.emit(EventEnum.farmtFinishTransfer,{tokenKey:'token_farmt',toAddress:toAddress});
            PolyEventManager.End({event:EventTag.e_Farm币发送,queueNo,txReceipt:res});
            console.log('farm transfer receipt',res);
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_Farm币发送,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        console.log("Transfer successful");
        return {status:true}
    }catch (e) {
        console.error("Error transferring token:", e);
        PolyEventManager.Error({event:EventTag.e_Farm币发送,queueNo,error:e});
        return AssembleErr({status:false,message:e},ErrorTypes.ethErr);
    }
}


export {Main as Farmt}
