// 获取商店种子列表
import {GameRequest} from "@/util/axiosRequest/gameRequest";

export async function getSeedList(){
    return await GameRequest.post('api/auth/shop/get_seed_list');
}

// 获取种子详情.这个好像没用到
export async function getSeedInfo({prop_id}){
    return await GameRequest.post('api/auth/shop/get_seed_info', {prop_id});
}

// 购买种子
export async function buySeed(params){
    return await GameRequest.post('api/auth/shop/buy_seeds', params);
}


// 查看vip的价格列表
export async function VipSellPrice(){
    return await GameRequest.post('api/auth/shop/vip_list', {});
}

export async function ReadyForBuyVip({vip_type,amount=1}){

    if(['month', 'season', 'year'].indexOf(vip_type)>-1){
        return await GameRequest.post('api/auth/shop/buy_vip', {vip_type:'month',amount:amount});
    }else{
        return {status:false, message:'传入合法的vip类型'}
    }
    // 强制一个个买吧..

}


export async function CouldBuyBoxKeyList(){
    return await GameRequest.post('api/auth/shop/get_blind_box_key_list');
}


export async function ReadyForBuyBlindBoxKey({levelDes,amount,isGold}){
    // level的值可以有
    if(['Low','Medium','High'].indexOf(levelDes)>-1){
        return await GameRequest.post('api/auth/shop/buy_blind_box_key', {level:levelDes,amount,gold:isGold});
    }else{
        return {status:false, message:'传入的参数不合法'}
    }

}