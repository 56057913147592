export default {
  name: 'SvgIcon',
  props: {
    iconName: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '10px'
    },
    // inputH:{
    //     type:String,
    //     default:''
    // },
    color: {
      type: String,
      default: 'blue'
    },
    display: {
      type: String,
      default: 'block'
    }
  },
  computed: {
    fullIconName() {
      return `#icon-${this.iconName}`;
    }
    // height(){
    // 这个设置了没用.不能作用到原本的图上.
    //      if(this.inputH!==''){
    //          return this.inputH;
    //      }else{
    //          return this.size;
    //      }
    // }
  },
  data() {
    return {};
  }
};