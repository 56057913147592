export default {
  name: 'svgImgShadow',
  components: {},
  props: {
    size: {
      type: String,
      default: '10vw'
    },
    level: {
      type: Number,
      default: 0
    },
    path: {
      type: String
    }
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    strokePath() {
      if (this.level == 0) {
        return 'url(#icon-filter_stock_stroke_white)';
      } else if (this.level == 2) {
        return 'url(#icon-filter_stock_stroke_purple)';
      } else if (this.level == 1) {
        return 'url(#icon-filter_stock_stroke_blue)';
      } else if (this.level == 3) {
        return 'url(#icon-filter_stock_stroke_golden)';
      }
      // return 'url(#icon-defshadow_strokeblue)';
    }
  },
  methods: {}
};