import {platform,crossDomain,domainMobileMap} from "./apiConfig";
import {EnvEnum} from "./envEnum";
import {AddressConfigDiffEnv} from "./addressConfig";
import {chainConnectConfig} from "./chainConnectConfig";

const buildplatForm = platform.web; //  web,或者mobile,

if(buildplatForm==platform.mobile){
    crossDomain[buildplatForm].baseGame=domainMobileMap.japan;
    console.log('选择了日服地址');
}else{

}
const currentApiPath = crossDomain[buildplatForm]; // 配置api网络请求用的路径.
const addressConfig=AddressConfigDiffEnv[EnvEnum.japan]; // 这个是合约地址的配置
const currentChainConnectConfig = chainConnectConfig[EnvEnum.connectNode1]; // 这个是节点链接的地址

const AppVersion={
    androidVer:'0.1.7',
    iosVer:'0.0.0',
    webVer:'0.1.7',
};
export  {platform,buildplatForm,currentApiPath,addressConfig,AppVersion,currentChainConnectConfig};