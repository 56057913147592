import {GameRequest} from "@/util/axiosRequest/gameRequest";

export async function getLandHarvestingList(){
    return await GameRequest.post('api/auth/land/get_land_harvesting_list');
}
// 获取土地详情
export async function ApiGetLandInfo({landIndex}){
    return await GameRequest.post('api/auth/land/get_land_info',{land_index :landIndex});
}



// 这个获取地块的详情接口还没用过.
export async function ApiGetLandPlotInfo({landIndex,plotIndex}){
    return await GameRequest.post('api/auth/land/get_plot_info',{land_index :landIndex,plot_index:plotIndex});
}


// 获取升级土地所需参数
export async function getUpgradePlotConditions({land_index,plot_index}){
    return await GameRequest.post('api/auth/land/upgrade_plot_conditions', {land_index,plot_index});
}

// 升级土地
export async function upgradePlot({land_index,plot_index}){
    return await GameRequest.post('api/auth/land/upgrade_plot', {land_index,plot_index});
}

// 获取拓展地块所需参数
export async function getExpandPlotConditions({land_index, plot_index}){
    return await GameRequest.post('/api/auth/land/expand_plot_conditions', {land_index: land_index, plot_index: plot_index});
}

// 拓展地块
export async function expandPlot({land_index, plot_index}){
    return await GameRequest.post('api/auth/land/expand_plot', {land_index: land_index, plot_index: plot_index});
}

// 获取玩家可播种种子列表
export async function getSeedAbleInventory(params){
    return await GameRequest.post('api/auth/land/get_seed_able_inventory', params);
}


// 播种种子
export async function ApiSinglePlotSeeding({land_index,plot_index,seed_id}){
    return await GameRequest.post('api/auth/land/seeding', {land_index :land_index,plot_index: plot_index, seed_id: seed_id});
}

// 一键播种
export async function ApiBatchSeeding({land_index,seed_id}){
    return await GameRequest.post('api/auth/land/batch_seeding', {land_index,seed_id});
}

// 铲除作物
export async function ApiDelCropSeed({land_index,plot_index}){
    return await GameRequest.post('api/auth/land/del_crop', {land_index, plot_index});
}

// 收获
export async function ApiSingleHarvestCropSeed({land_index, plot_index}){
    return await GameRequest.post('api/auth/land/harvest',{land_index,plot_index});
}


// 查看他人当前可收获列表数量
export async function getOthersHarvestInfo({user_id}){
    return await GameRequest.post('api/auth/land/get_friend_land_harvesting_list', {user_id:user_id});
}

// 查看他人某个土地的详情信息
export async function getFriendLandInfo({land_index, user_id, is_guild}){
    return await GameRequest.post('api/auth/land/get_friend_land_info', {land_index, user_id, is_guild});
}

// 下面这个没用上.
export async function ApiGetFriendPlotInfo(LandIndex, PlotIndex, UserId, IsGuild){
    return await GameRequest.post('api/auth/land/get_friend_plot_info', {land_index:LandIndex, plot_index:PlotIndex, user_id: UserId, is_guild:IsGuild});
}

// 偷菜
export async function stealingCrops({land_index, plot_index, user_id, is_guild}){
    return await GameRequest.post('api/auth/land/stealing_crops', {land_index, plot_index,  user_id, is_guild});
}


// 防守记录
export async function stealingCropsLog(params){
    return await GameRequest.post('api/auth/land/stealing_crops_log', params);
}
// 一键收获
export async function ApiAllHarvest(landIndex){
    return await GameRequest.post('api/auth/land/all_harvest', {land_index:landIndex});
}


