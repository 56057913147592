import { eventLngMap } from '@/language/eventQueueLng';
import _ from 'lodash';
import { showConfirmDialog, showToast } from "vant";
import { PolyEventManager, SourceTag } from '@/util/polyEventProgress';
import { EventManager, EventEnum } from "@/util/EventManager";
import { copyContent } from '@/hooks/index';
import { Center } from '@/chainField/chainCenter';
import { ParseTimestampToFormat } from "../hooks";
export default {
  name: 'EthEventDetail',
  components: {},
  props: {
    eventInfo: {
      queueNo: {
        type: String
      },
      whichQueueContract: {
        type: String
      },
      abiName: {
        type: String
      }
    }
  },
  data() {
    return {
      bindEventData: {}
    };
  },
  watch: {
    'eventInfo.queueNo': function (newVal, oldVal) {
      console.log('变化了哦', newVal, "旧的", oldVal);
      this.loadMatchEvent();
    }
  },
  created() {
    this.loadMatchEvent();
  },
  // mounted() {
  //     console.log('详情mounted');
  // },
  // beforeUnmount() {
  //     console.log('详情beforeUnmount');
  // },
  computed: {
    eCurLngMap() {
      return eventLngMap[this.en_zh];
    },
    debugMode() {
      return this.$store.state.appRunStatus.debugMode;
    },
    currentTimeStamp() {
      return this.$store.state.currentTimeStamp;
    }
    // matchEvent(){
    //     if(this.eventInfo.whichQueueContract&&this.eventInfo.whichQueueContract.length>1){
    //         const matchQueueNo=this.eventInfo.queueNo;
    //         if(matchQueueNo&&matchQueueNo.length>0){
    //             return _.find(this.$store.state.queuesAll[this.eventInfo.whichQueueContract],o=>o.queueNo=matchQueueNo);
    //         }else{
    //             return {};
    //         }
    //     }else{
    //         return {};
    //     }
    // },
  },
  methods: {
    simulateRuntimError() {
      PolyEventManager.manualSetWaitTxTimeoutError(this.bindEventData.queueNo);
    },
    clearTxResponseError() {
      PolyEventManager.clearTxReponseError(this.bindEventData.queueNo);
    },
    timestampFormat(timestamp) {
      if (timestamp) {
        return ParseTimestampToFormat(timestamp, 1);
      } else {
        return 'empty_time';
      }
    },
    viewInBlockBrowser() {
      this.copyHash();
      let hash = '';
      if (this.bindEventData.txResponseInfo && this.bindEventData.txResponseInfo.hash) {
        hash = this.bindEventData.txResponseInfo.hash;
      }
      const url = 'https://polygonscan.com/tx/' + hash;
      if (window.plus) {
        plus.runtime.openURL(url);
      } else {
        window.open(url, '_blank');
      }
    },
    timePassed(time) {
      return (this.currentTimeStamp - new Date(time).getTime()) / 1000;
    },
    delayToast(text) {
      setTimeout(() => {
        showToast(text);
      }, 100);
    },
    copyHash() {
      if (this.bindEventData && this.bindEventData.txResponseInfo) {
        if (this.bindEventData.txResponseInfo.hash) {
          copyContent(this.bindEventData.txResponseInfo.hash);
          this.delayToast('已复制HASH:' + this.bindEventData.txResponseInfo.hash);
        } else {
          showToast(this.UiText.noHash);
        }
      } else {
        showToast(this.UiText.txResponseMissed);
      }
    },
    removeEventByQueueNo() {
      const info = {
        queueNo: this.bindEventData.queueNo
      };
      PolyEventManager.removeEvent(info).then(res => {
        if (res.status) {
          this.loadMatchEvent();
        } else {
          showToast(res.message);
        }
      });
    },
    loadMatchEvent() {
      console.log('加载数据');
      let data = {};
      this.bindEventData = {};
      if (this.eventInfo.whichQueueContract && this.eventInfo.whichQueueContract.length > 1) {
        const matchQueueNo = this.eventInfo.queueNo;
        if (matchQueueNo && matchQueueNo.length > 0) {
          data = _.find(this.$store.state.queuesAll[this.eventInfo.whichQueueContract], o => o.queueNo === matchQueueNo);
        }
      }
      // 会指向.所以回调改数据比如报错什么的这个也会有
      this.bindEventData = data;
    },
    runAgain() {
      if (this.bindEventData && this.bindEventData !== {}) {
        const res = Center.runAgain(this.bindEventData);
        if (res.status) {} else {
          showToast(res.message);
        }
      }
      //
    },
    resetCheckCD() {},
    checkThisEvent() {
      if (this.eventInfo) {
        const matchQueueNo = this.eventInfo.queueNo;
        PolyEventManager.urgeCheck(matchQueueNo);
        //
        // if(this.checkHashInfo.isChecking){
        //     showToast(this.UiText.waitplease);
        //     return;
        // }
        // this.checkHashInfo.isChecking=true;
        // PolyEventManager.urgeCheck(matchQueueNo).then(res=>{
        //     this.checkHashInfo.isChecking=false;
        //     this.checkHashInfo.checkResult=JSON.stringify(res);
        //     console.log('check的返回结果',res);
        //     let basMsg='';
        //     if(res.txExist){
        //         basMsg+='[order_exist]';
        //         // 交易存在
        //         if(res.receiptStatus=='txNotExist'){}
        //         if(res.receiptStatus=='waiting'){basMsg+='waiting'}
        //         if(res.receiptStatus=='success'){basMsg+='success'}
        //         if(res.receiptStatus=='fail'){basMsg+='fail'}
        //     }else{
        //         basMsg+='[order_not_found]:'+res.hash;
        //     }
        //     this.checkHashInfo.baseMsg=basMsg;
        //     showToast(basMsg);
        // }).catch(e=>{
        //     this.checkHashInfo.isChecking=false;
        //     this.checkHashInfo.catchErr=e;
        //     console.log('有抛出错误么',e);
        // });
      }
    }
  },
  destroyed() {
    console.log('详情destory');
  }
};