import { createVNode, render } from 'vue';
import Toast from './cyberToast.vue';

const ToastPlugin = {
  install(app) {
    const toastContainer = document.createElement('div');
    document.body.appendChild(toastContainer);

    // 使用 createVNode 创建节点
    const toastVNode = createVNode(Toast);
    // 使用 render 渲染节点
    render(toastVNode, toastContainer);

    // 显示 Toast 的函数
    const show = (message, duration = 3000) => {
      toastVNode.component.exposed.show(message, duration);
    };

    // 将 Toast 方法添加到全局属性中
    app.config.globalProperties.$toast = { show };
  }
};

export default ToastPlugin;
