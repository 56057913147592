import _ from 'lodash';
const EventManager={
    events:{},
}
// 注册事件监听器
EventManager.on=function(eventName, listener, ctx){
    if(!EventManager.events[eventName]){
        EventManager.events[eventName]=[];
    }
    EventManager.events[eventName].push({cb:listener, ctx});
}
// 移除掉那个监听器
EventManager.off=function (eventName,listenerToRemove) {
    const listenersGroup=EventManager.events[eventName];
    if(listenersGroup){
        // EventManager.events[eventName]=listenersGroup.filter(item=>{
        //     item.cb!==listenerToRemove; 这个写法不行.filter过滤全给我去掉了.
        // });
        EventManager.events[eventName]= _.filter(listenersGroup,item=>item.cb!==listenerToRemove)
    }
}

EventManager.emit=function(eventName,data){
    const listenerGroup=EventManager.events[eventName];
    if(listenerGroup){
        listenerGroup.forEach(item=>{
            if(item.ctx){
                item.cb.apply(item.ctx,data)
            }else{
                item.cb(data);
            }

        })
    }

};

// 作用.我可以在各地方都提前设置监听器.然后在某个地方触发的话.各地方的监听器都会被触发.
// 比方说.一旦wallet登录.在各个地方都可以显示address.而不是把显示的代码在一个地方统一编写.
export {EventManager}