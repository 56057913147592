import {EnvEnum} from './envEnum';
const chainConnectConfig={};
chainConnectConfig[EnvEnum.connectNode1]={
   httpsList:[
       'https://polygon-mainnet.infura.io/v3/366d17579efd49b098989bb25acdf10f',
       'https://polygon-mainnet.g.alchemy.com/v2/6Jfsy-kNvuijQ0ZLdQud96fEaO-KD6X-',
       'https://polygon-rpc.com',
   ],
    webList:[
        'wss://polygon-mainnet.infura.io/ws/v3/366d17579efd49b098989bb25acdf10f',
        'wss://polygon-mainnet.g.alchemy.com/v2/6Jfsy-kNvuijQ0ZLdQud96fEaO-KD6X-',
        // 'wss://polygon-mainnet.g.alchemy.com/v2/mtY-jrBY48EQdSVnhDOmIR8tKTMbLMTK',
    ]
}


 export {chainConnectConfig};