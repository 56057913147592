import { createRouter, createWebHashHistory } from 'vue-router'

import {Center as chainCenter} from "@/chainField/chainCenter";
import {saveMap} from '@/util/localStore';
const routes = [
  {
    path: '/login',
    name: 'login',
    component: ()=>import(/* webpackChunkName: "page1" */ '../views/login/LoginView.vue')
  },{
    path:'/',
    name:'index',
    redirect:{name:'main'},
  },{
    path:'/items',
    name:'items',
    component: ()=>import(/* webpackChunkName: "page1" */ '../views/more/items.vue')
  },
  {
    path:'/warehouse_item_detail/blindbox',
    name:'detail_blindbox',
    component: ()=>import(/* webpackChunkName: "page1" */ '../views/warehouseItemDetails/detailForBlindBox.vue')
  },
  {
    path:'/warehouse_item_detail/equipment',
    name:'detail_equipment',
    component: ()=>import(/* webpackChunkName: "page1" */ '../views/warehouseItemDetails/detailForEquipment.vue')
  },
  {
    path:'/warehouse_item_detail/goldnft',
    name:'detail_goldnft',
    component: ()=>import(/* webpackChunkName: "page1" */ '../views/warehouseItemDetails/detailForGoldNft.vue')
  },
  {
    path:'/warehouse_item_detail/seed',
    name:'detail_seed',
    component: ()=>import(/* webpackChunkName: "page1" */ '../views/warehouseItemDetails/detailForSeed.vue')
  },
  {
    path:'/farmland',
    name:'farmland',
    component: ()=>import(/* webpackChunkName: "page1" */ '../views/childFarmLand.vue')
  },
  {
    path:'/friendFarmland',
    name:'friendFarmland',
    component: ()=>import(/* webpackChunkName: "page1" */ '../views/childFriendFarmLand.vue')
  },
  {
    path: '/main',
    name: 'main',
    redirect:{name:'tb1'},
    component: ()=>import(/* webpackChunkName: "page1" */ '../views/MainView.vue'),
    children:[
      {
        path:'tb1', name:'tb1',
        meta:{
          title:'tb1'
        },
        component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/tb1.vue')
      },
      {
        /*这个是仓库*/
        path:'warehouse',
        name:'warehouse',
        component: ()=>import(/* webpackChunkName: "page1" */ '../views/warehouse.vue'),
        redirect:{name:'blindNftBox'},
        children:[
          {
            path:'myseeds',name:'myseeds',
            component:()=>import(/* webpackChunkName: "warehouse" */ '../views/warehouseDir/myseeds.vue'),
          },
          {
            path:'equipment',name:'equipment',
            component:()=>import(/* webpackChunkName: "warehouse" */ '../views/warehouseDir/equipment.vue'),
          },
          {
            path:'goldnft',name:'goldnft',
            component:()=>import(/* webpackChunkName: "warehouse" */ '../views/warehouseDir/goldNft.vue'),
          },
          {
            path:'blindNftBox',name:'blindNftBox',
            component:()=>import(/* webpackChunkName: "warehouse" */ '../views/warehouseDir/blindNftBox.vue'),
          },
        ]
      },
      {
        path:'shop', name:'shop',
        meta:{title:'shop'},
        redirect:{name:'equip'},
        component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/shop/index.vue'),
        children:[
          {
            path:'equip',name:'equip',
            component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/shop/buyEquip.vue'),
          },
          {
            path:'seed',name:'seed',
            component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/shop/buySeed.vue'),
          }
        ]
      },
      {
        path:'playermarket', name:'playermarket',
        meta:{title:'playermarket'},
        redirect:{name:'onlyequipment'},
        component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/market/index.vue'),
        children:[
          {
            path:'minemix',name:'minemix',
            component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/market/mine_equip_and_gold.vue'),
          },
          {
            path:'onlyequipment',name:'onlyequipment',
            component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/market/other_onlyequipment.vue'),
          },
          {
              path:'goldpurchase',name:'goldpurchase',
              component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/market/goldPurchase.vue'),
          }
        ]
      },
      {
        path:'tb4', name:'tb4',
        meta:{title:'tb4'},
        component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/union/index.vue')
      },
      {
        path:'tab_setting', name:'tab_setting',
        meta:{title:'tab_setting'},
        component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/tab_setting.vue')
      },
    ]
  },
    {
        path:'/equipInSell', name:'equipInSell',
        meta:{title:'equipInSell'},
        component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/market/item/equipInSell.vue')
    },
    {
        path:'/goldAskBuy', name:'goldAskBuy',
        meta:{title:'goldAskBuy'},
        component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/market/item/goldAskBuy.vue')
    },
  {
    path:'/playerEquip',
    name:'myPlayer',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/playerEquip.vue')
  },
  {
    path:'/otherPlayerFarm',
    name:'otherPlayerFarm',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/otherPlayerFarm.vue')
  },
  {
    path:'/withdraw',
    name:'withdraw',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/coinWithdraw.vue')
  },
  {
    path:'/settings',
    name:'settings',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/settings.vue')
  },
  {
    path:'/task',
    name:'task',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/task.vue')
  },
  {
    path:'/rank',
    name:'rank',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/rank.vue')
  },
  {
    path:'/friends',
    name:'friends',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/friends.vue')
  },
  {
    path:'/player_profit',
    name:'player_profit',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/player_profit.vue')
  },
  {
    path:'/copykey',
    name:'copykey',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/copykey.vue')
  },
  {
    path:'/swapv2',
    name:'swapv2',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/coinExchange.vue')
  },{
    path:'/gameGoldToNft',
    name:'gameGoldToNft',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/gameGoldToNft.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: ()=>import(/* webpackChunkName: "page1" */ '../views/TestView.vue')
  },
  {
    path: '/apiTest',
    name: 'apiTest',
    component: ()=>import('../views/apiTest.vue')
  },
  {
    path: '/union',
    name: 'union',
    component: () => import('../views/mainPages/union/index.vue')
  },
  {
    path: '/unionApprove',
    name: 'unionApprove',
    component: () => import('../views/mainPages/union/unionApprove.vue')
  },
  {
    path: '/mySeeds',
    name: 'mySeeds',
    component: () => import('../views/farm/seedSelector.vue')
  },
  {
    path: '/mobilescan',
    name: 'mobilescan',
    component: () => import('../views/mobilescan.vue')
  },
  {
    path:'/userrateback',
    name:'userrateback',
    component: ()=>import(/* webpackChunkName: "page5" */ '../views/more/userRateBack.vue')
  },
  {
    path:'/userlogs', name:'userlogs',
    meta:{title:'userlogs'},
    redirect:{name:'onlyview'},
    component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/tabSettingChilds/userlogs.vue'),
    children:[
      {
        path:'onlyview', name:'onlyview',
        meta:{
          title:'onlyview'
        },
        component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/tabSettingChilds/viewLogs.vue')
      },
      {
        path:'fix', name:'fix',
        meta:{
          title:'fix'
        },
        component:()=>import(/* webpackChunkName: "mainpages" */ '../views/mainPages/tabSettingChilds/fixUnFinishedSigList.vue')
      },

    ]
  },
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})
const noCheck = false;
router.beforeEach((to, from, next) => {
  if(noCheck) {
    next();
    return;
  }

  const ignoredRoute=['/login','/test'];
  if(ignoredRoute.indexOf(to.path)>-1){
    next();
    return;
  }
  if (chainCenter.wallet) {
    // 缓存有钱包
    if(to.path=='/login'){
      // 在已经有钱包的情况下.走login,会切成/路径代表其实可以进入了.
      next('/');
      return;
    }
    next();
  } else {
    // 缓存没钱包.会话级别会保活.不做这个的话页面刷新就会退出页面.连刷新都不行.
    if(sessionStorage.getItem(saveMap.randomencodewt)){
    // if(localStorage.getItem(saveMap.randomencodewt)){
      const keepRes = chainCenter.keepAliveWtConnect();
      if(keepRes.status){
        next();
      }else{
        next({path:'/login'});
      }
    }else{
      console.log('to login');
      next({path:'/login'});
    }
  }

});

export default router
