const filters={};
filters.accurateToPoint=function (value,number) {
    const point = number > 0 ? number : 0;
    if (point > 0) {
        const roundValue = Math.round(parseFloat(value) * Math.pow(10,point));
        const value2 = roundValue / Math.pow(10,point);
        return value2;
    } else {
        return Math.round(parseFloat(value));
    }
};
filters.ellipsis=function(text,length,suffix='...'){
    if(text){
        if(typeof text =='number'){
         text=text+'';
        }
        if(text.length<=length) return text;
        const begin = text.substr(0,length/2);
        const end = text.substr(-1*Math.ceil(length/2),length/2);
        return `${begin}${suffix}${end}`;
    }else{
        return '';
    }

}

export default filters;