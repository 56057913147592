export function logInPhone(data) {
    if(window.plus){
        const androidLog = window.plus.android.importClass("android.util.Log");
        if(androidLog){
            androidLog.d('farm', JSON.stringify(data));
        }else{
            console.log('farm console log');
            console.log(JSON.stringify(data));
        }
    }else{
        console.log(JSON.stringify(data));
    }
}