import {ethers} from "ethers";
import {EventManager,EventEnum} from "@/util/EventManager";
import {Center} from "@/chainField/chainCenter";
import  {abis} from './abi_contract_goldennft';
import {addressConfig} from "@/config";
import {ErrorTypes,AssembleErr,BaseContractSetting} from './NamingSummary';
import {
    ClearWaitForReceiptByHash,
    EventTag,
    PolyEventManager,
    syncWaitForReceiptByHash
} from "@/util/polyEventProgress";


let _address=addressConfig.goldenNft;
const Main={
    contract:null,
    signerContract:null,
    _Iface:null,
};
Main.abis=abis;

const init=function(){
        if(!Center.provider){
        return {status: false, message: 'provider need init!'}
    }
    Main.contract= new ethers.Contract(_address,abis,Center.provider);
    return {status: true, contract: Main.contract}
};

const contractAddSigner = function(){
    if(!Main.contract){
        const step0 = init();
        if(!step0.status){
            return step0;
        }
    }
    if(!Center.signer){
        return {status: false, message: 'center signer missed', showUserAuth:true}
    }
    Main.signerContract=Main.contract.connect(Center.signer);
    return {status: true, signerContract: Main.signerContract}
}

function ready(){
    if(!Main.contract){
        init();
    }
}

// address account, uint256 goldType, uint256 amount, uint256 time, uint256 nonce, bytes signature
Main.runUserBatchSafeMint= async function({address,goldType, amount,time,nonce,signature,source=null,retryTxOptions=null}){
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }
    console.log('列出参数');
    const body={
        gold_type:goldType,
        nft_amount:amount,
        public_order_nonce:nonce,
        time:time,
        user_addr:address,
        signature:signature
    };
    console.log("准备的Body",body);
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.UserBatchSafeMint.populateTransaction(address,goldType, amount,time,nonce,signature);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_金币铸造,source:source,eventOpt:{goldType:goldType,amount:amount,nonce:nonce},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx = await Main.signerContract.UserBatchSafeMint(address,goldType, amount,time,nonce,signature,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_金币铸造,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_金币铸造,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("金币铸造res",res);
            PolyEventManager.End({event:EventTag.e_金币铸造,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.newGoldNftCreated,{goldType:goldType,nonce:nonce,amount:amount,receiptInfo:{status:res.status}})
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_金币铸造,queueNo:queueNo,error:e,txResponse:firstTx});
        });

        return {status:true}
    }catch (e) {
        console.log("Error goldnft Create:", e);
        PolyEventManager.Error({event:EventTag.e_金币铸造,queueNo:queueNo,error:e});
        return AssembleErr({status:false,message:e},ErrorTypes.ethErr);
    }
};
Main.runBurnNftByTokenId= async function({tokenId,source,retryTxOptions=null}){
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }

    console.log('传入的tokenId是',tokenId);
    let queueNo='';
    try{
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.burn.populateTransaction(tokenId);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_金币使用,source:source,eventOpt:{tokenId:tokenId},data:popuInfo.data});
        tokenId=parseInt(tokenId);
        let firstTx={};
        try {
            firstTx =  await Main.signerContract.burn(tokenId,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_金币使用,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_金币使用,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("金币使用结果",res);
            PolyEventManager.End({event:EventTag.e_金币使用,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.goldNftToGameCoin,{tokenId:tokenId,receiptInfo:{status:res.status}});
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_金币使用,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        return {status:true}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_金币使用,queueNo:queueNo,error:e});
        return AssembleErr({status:false,message:e},ErrorTypes.ethErr);
    }
}

// 固定金币.这东西..是查看一下这个金币nft被授权给了谁.
const whereGoldenApproved= async function({tokenId}){
    ready();
    console.log('传进来的token是啥啊',tokenId);
    try{
        const whereAddress = await Main.contract.getApproved(tokenId);
        console.log('这个金币nft授权给谁了啊',whereAddress);
        return {status:true, address:whereAddress};
    }catch (e) {
        console.log('金币nftGetApproved错误',e);
        return AssembleErr({status:false,message:e},ErrorTypes.ethErr);
    }

};
Main.checkGoldenIsApproveToMarket= async function({tokenId}){
    const res =await whereGoldenApproved({tokenId});
    if(res.status){
        if(res.address==addressConfig['market']){
            return {status:true, isApprovedSuccess:true};
        }else{
            return {status:true, isApprovedSuccess:false};
        }

    }else{
        return AssembleErr({status:false,message:res.message});
    }
};

Main.checkGoldenIsApproveToConsigment= async function({tokenId}){
    const res =await whereGoldenApproved({tokenId});
    if(res.status){
        if(res.address==addressConfig.goldenConsignment){
            return {status:true, isApprovedSuccess:true};
        }else{
            return {status:true, isApprovedSuccess:false};
        }

    }else{
        return AssembleErr({status:false,message:res.message});
    }
};

// 金币不会授权给market了.它不会上架了，这个不用调整
Main.none_toApproveToMarket= async function({tokenId}){
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }
    // 比如'1.05'
    const goldNftTokenId =tokenId;
    let spenderAddress='';
    try{
        spenderAddress=addressConfig.market;
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt();
        const approveTx = await Main.signerContract.approve(spenderAddress,goldNftTokenId,gasOpt);
        console.log("金币卖场"+tokenId+'授权给market', approveTx);
        const res = await approveTx.wait();
        console.log("approve_tx_wait_res",res);
        return {status:true, waitTx:approveTx, message:'内部等待wait'}
    }catch (e) {
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
        // return {status: false, message:'approve token_id error', error:e}
    }
}


Main.toApproveToConsignment= async function({tokenId,source,eventOptPlus=null,retryTxOptions=null}){
    ready();
    if(!Main.signerContract){
        const step0=contractAddSigner();
        if(!step0.status){
            return step0;
        }
    }
    // 比如'1.05'
    const goldNftTokenId =tokenId;
    let spenderAddress='';
    let queueNo='';
    try{
        spenderAddress=addressConfig.goldenConsignment;
        const gasOpt = await Center.checkSubmitCDAndGetTxOpt(retryTxOptions);
        const popuInfo = await Main.signerContract.approve.populateTransaction(spenderAddress,goldNftTokenId);
        queueNo = PolyEventManager.Add({txOptions:gasOpt,callArgs:arguments,event:EventTag.e_金币授权给求购单,source:source,eventOpt:{tokenId:tokenId},data:popuInfo.data});
        let firstTx={};
        try{
            firstTx = await Main.signerContract.approve(spenderAddress,goldNftTokenId,gasOpt);
            await Center.NonceChangeIfLocalEqualWhenDid(gasOpt.nonce);
        }catch (e) {
            Center.submitCoolByNonce(gasOpt.nonce);
            return await PolyEventManager.txCatchError({event:EventTag.e_金币授权给求购单,queueNo:queueNo,error:e});
        }
        Center.submitCoolByNonce(gasOpt.nonce);
        PolyEventManager.Update({event:EventTag.e_金币授权给求购单,queueNo:queueNo,txResponse:firstTx});
        Center.NonceRefreshAnyway();
        syncWaitForReceiptByHash({hash:firstTx.hash,promise:firstTx.wait(1,BaseContractSetting.txWaitTimeout),queueNo:queueNo}).then(res=>{
            ClearWaitForReceiptByHash(firstTx.hash);
            console.log("授权给求购receipt",res);
            PolyEventManager.End({event:EventTag.e_金币授权给求购单,queueNo:queueNo,txReceipt:res});
            EventManager.emit(EventEnum.goldNftApproved,{source:source,spender:'goldenConsignment',tokenId:tokenId,receiptInfo:{status:res.status}})
        }).catch(e=>{
            PolyEventManager.receiptCatchError({event:EventTag.e_金币授权给求购单,queueNo:queueNo,error:e,txResponse:firstTx});
        });
        return {status:true, message:'内部等待wait'}
    }catch (e) {
        PolyEventManager.Error({event:EventTag.e_金币授权给求购单,queueNo:queueNo,error:e});
        return AssembleErr({status:false, message:e},ErrorTypes.ethErr);
        // return {status: false, message:'approve token_id error', error:e}
    }
}
// const qm1 = ethers.id(JSON.stringify(signature));
// const signature_bytes = ethers.toUtf8Bytes(JSON.stringify(signature));
// const idvalue = ethers.id(JSON.stringify(signature));
// ethers.hexlify(ethers.toUtf8Bytes())
// console.log('idvalue',idvalue);

export {Main as GoldenNft}