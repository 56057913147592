import axios from "axios";
async function pureRequest(options,headers){
    const axiosConfig ={
        ...options
    };
    if(headers){
        axiosConfig.headers=headers
    }
    try{
        const response=  await axios(axiosConfig);
        if(response&&response.data.hasOwnProperty('status')){
            return response.data;
        }else{
            return {status: true, ...response.data}
        }
    }catch (e) {
        console.log(e);
        if(e.response){
            return {status:false,error_code:e.response.status,...e.response.data};
        }
        throw new Error("axios request error");
    }
}


pureRequest.get =async function(url,params){
    return pureRequest({url, method: 'GET',params});
};
pureRequest.post = async function (url, data,headers){
    return pureRequest({url, method:'POST',data},headers)
};
pureRequest.put = async function (url, data){
    return pureRequest({url, method:'PUT',data})
};
pureRequest.delete = async function (url, data){
    return pureRequest({url, method:'DELETE',data})
};
export default pureRequest;
