


function setKey(key,value){
    return localStorage.setItem(key,value);
};
function getKey(key){
    return localStorage.getItem(key);
};
function clearKey(key) {
    localStorage.removeItem(key)
}

export {setKey,clearKey,getKey};